import Button from './button';
import Div from './div';
import Paragraph from './paragraph';
import Section from './section';
import heading from './heading';
import Header from './header';
import List from './list';
import ListItem from './list-item';
import Footer from './footer';
import Image from './image';
import Anchor from './anchor';
import Iframe from './iframe';

const componentsKeys = {
  // Sections
  header01: Header,
  header02: Header,
  header03: Header,
  hero01: Section,
  hero02: Section,
  content01: Section,
  content02: Section,
  content03: Section,
  footer01: Footer,
  footer02: Footer,
  footer03: Footer,
  clients01: Section,
  clients02: Section,
  call_to_action01: Section,
  call_to_action02: Section,
  contact_us01: Section,
  contact_us02: Section,
  feature01: Section,
  feature02: Section,
  feature03: Section,
  testimonials01: Section,
  testimonials02: Section,
  testimonials03: Section,
  team01: Section,
  team02: Section,
  portfolio01: Section,
  portfolio02: Section,
  divider01: Section,
  divider02: Section,
  biography01: Section,
  biography02: Section,
  video01: Section,
  video02: Section,
  statistics01: Section,
  statistics02: Section,
  // Elements
  div: Div,
  paragraph: Paragraph,
  linkButton: Button,
  image: Image,
  heading,
  list: List,
  listElement: ListItem,
  link: Anchor,
  iframe: Iframe,
};

export default componentsKeys;
