import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getUser } from '../../../redux/actions/user.actions';
import Api from '../../../services';

function Unsubscribe({ isModalOpen, toggleModal }) {
  const dispatch = useDispatch();

  const onDelete = () => {
    Api.payments.unsubscribe().then(() => {
      dispatch(getUser());
      toggleModal();
    });
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="border-0 text-gray" toggle={() => toggleModal()}>
        إلغاء الاشتراك
      </ModalHeader>
      <ModalBody className="pb-0">
        <p className="text-gray">لن يمكنك إعادة الاشتراك في حال إلغاء اشتراكك، هل تود المتابعة؟</p>
      </ModalBody>
      <ModalFooter className="border-0">
        <button type="submit" className="btn btn-red px-4" onClick={onDelete} id="unsubscribe">
          تأكيد
        </button>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_unsubscribe">
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default Unsubscribe;
