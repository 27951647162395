import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import {
  Input, FormGroup, Label, Modal, ModalBody, ModalFooter, Row,
} from 'reactstrap';
import ColorInputField from '../../../../../components/color-input';
import { removeElementById, updateNestedItems } from '../../../../../helpers';
import { getPages } from '../../../../../redux/actions/pages.actions';
import { updateSection } from '../../../../../redux/actions/sections.actions';

function Button({
  config, projectId, pageId, parent, getPageDetails, previewComponent, updateProgressBar,
}) {
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = useState(false);
  const [externalLink, setExternaLink] = useState(config.metadata?.externalLink);
  const [text, setText] = useState(config.metadata?.text);
  const [target, setTarget] = useState(config.metadata?.target === '_blank');
  const [linkPoint, setLinkpoint] = useState(config.metadata?.linkPoint);
  const [backgroundColor, setBackgroundColor] = useState(config?.interface_data?.style && config?.interface_data?.style?.['background-color']);
  const [textColor, setTextColor] = useState(config?.interface_data?.style?.color);
  const [borderColor, setBorderColor] = useState(config?.interface_data?.style && config?.interface_data?.style?.['border-color']);
  const [borderTopRightRadius, setBorderTopRightRadius] = useState(config?.interface_data?.style && config?.interface_data?.style?.['border-top-right-radius']?.replace(/\D/g, ''));
  const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(config?.interface_data?.style && config?.interface_data?.style?.['border-top-left-radius']?.replace(/\D/g, ''));
  const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(config?.interface_data?.style && config?.interface_data?.style?.['border-bottom-right-radius']?.replace(/\D/g, ''));
  const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(config?.interface_data?.style && config?.interface_data?.style?.['border-bottom-left-radius']?.replace(/\D/g, ''));
  const [selectedPageId, setPageId] = useState(config.metadata?.pageId);
  const [buttonClass, setButtonClass] = useState(config?.interface_data?.class);
  const [pages, setPages] = useState([]);

  const updateConfigValue = () => {
    updateProgressBar(50);
    updateNestedItems(parent?.components, config.id, {
      metadata: {
        text, linkPoint, target: target ? '_blank' : '_self', pageId: linkPoint === 'page' ? selectedPageId : undefined, externalLink,
      },
      interface_data: {
        ...config?.interface_data,
        style: {
          ...config?.interface_data?.style,
          'background-color': backgroundColor,
          color: textColor,
          'border-color': borderColor,
          'border-top-right-radius': `${borderTopRightRadius}px`,
          'border-top-left-radius': `${borderTopLeftRadius}px`,
          'border-bottom-right-radius': `${borderBottomRightRadius}px`,
          'border-bottom-left-radius': `${borderBottomLeftRadius}px`,
        },
        class: buttonClass,
      },
    });
    dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
      toggleModal(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  const deleteButton = () => {
    const data = removeElementById(parent?.components, config?.id);
    const updatedParent = { ...parent, components: data };
    dispatch(updateSection(projectId, pageId, updatedParent?.id, updatedParent)).then(() => getPageDetails()).then(() => {
      toggleModal(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  const handleExternalLinkChange = (e) => {
    const { value } = e.target;

    if (!value.startsWith('https://')) {
      setExternaLink(`https://${value}`);
    } else {
      setExternaLink(value);
    }
  };

  const handleBackgroundColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setBackgroundColor(color);
  };

  const handleTextColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setTextColor(color);
  };

  const handleBorderColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setBorderColor(color);
  };

  useEffect(() => {
    if (!isModalOpen) return;

    dispatch(getPages(projectId)).then((response) => {
      setPages(response.data);
    });
  }, [isModalOpen]);

  if (previewComponent) {
    const link = config?.metadata?.linkPoint === 'external' ? config?.metadata?.externalLink : `/preview/${projectId}/pages/${config?.metadata?.pageId}`;
    return (
      <button
        type="button"
        style={{
          backgroundColor: config?.interface_data?.style && config?.interface_data?.style['background-color'],
          color: config?.interface_data?.style?.color,
          borderColor: config?.interface_data?.style && config?.interface_data?.style['border-color'],
          borderTopRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-right-radius'],
          borderTopLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-left-radius'],
          borderBottomRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-right-radius'],
          borderBottomLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-left-radius'],
        }}
        className={config?.interface_data?.class}
        onClick={() => window.open(link, '_top')}
      >
        {config.metadata?.text}
      </button>
    );
  }
  return (
    <div className="config-btn">
      <button
        style={{
          backgroundColor: config?.interface_data?.style && config?.interface_data?.style['background-color'],
          color: config?.interface_data?.style?.color,
          borderColor: config?.interface_data?.style && config?.interface_data?.style['border-color'],
          borderTopRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-right-radius'],
          borderTopLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-left-radius'],
          borderBottomRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-right-radius'],
          borderBottomLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-left-radius'],
        }}
        type="button"
        className={config?.interface_data?.class}
        onClick={() => toggleModal(!isModalOpen)}
      >
        {config.metadata?.text}
      </button>
      <Modal size="lg" className="button-modal" toggle={() => toggleModal(!isModalOpen)} isOpen={isModalOpen}>
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إعدادات الزر</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody>
          <Row>
            <FormGroup className="form-group col-md-6">
              <Label className="text-gray mb-1">
                تعديل النص
              </Label>
              <Input value={text} onChange={(e) => setText(e.target.value)} />
            </FormGroup>
            <FormGroup className="form-group col-md-6">
              <Label className="text-gray mb-1">
                نوع الرابط
              </Label>
              <Input
                value={linkPoint}
                name="linkPoint"
                onChange={(e) => setLinkpoint(e.target.value)}
                type="select"
                defaultValue=""
              >
                <option value="" disabled>يرجى الاختيار</option>
                <option value="external">رابط خارجي</option>
                <option value="page">صفحة من الموقع</option>
              </Input>
            </FormGroup>
            {linkPoint === 'external'
              ? (
                <FormGroup className="form-group col-md-6">
                  <Label className="text-gray mb-1">
                    تعديل الرابط
                  </Label>
                  <Input value={externalLink} onChange={(e) => handleExternalLinkChange(e)} dir="ltr" />
                </FormGroup>
              )
              : (
                <FormGroup className="form-group col-md-6">
                  <Label className="text-gray mb-1">
                    رابط الصفحة
                  </Label>
                  <Input
                    value={selectedPageId || ''}
                    name="pageId"
                    onChange={(e) => setPageId(e.target.value)}
                    type="select"
                    defaultValue=""
                  >
                    <option value="" disabled>يرجى الاختيار</option>
                    {pages?.map((page) => {
                      return (
                        <option value={page.id}>{page.title}</option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}
            <FormGroup className="form-group form-switch form-check col-md-6 pt-4">
              <Input
                name="target"
                type="checkbox"
                checked={target}
                onChange={(e) => setTarget(e.target.checked)}
                className="mt-2"
              />
              <Label className="text-gray mt-1 mb-0" switch>
                فتح في نافذة جديدة
              </Label>
            </FormGroup>
            <FormGroup className="form-group col-md-6">
              <Label className="text-gray mb-1">
                حجم الزر
              </Label>
              <div className="btns-container w-100">
                <button
                  className={classNames('btn btn-blue-outline', buttonClass.includes('btn-sm') ? 'active' : '')}
                  type="button"
                  onClick={() => setButtonClass('btn btn-secondary btn-sm')}
                >
                  صغير
                </button>
                <button
                  className={classNames('btn btn-blue-outline', buttonClass === 'btn btn-secondary' ? 'active' : '')}
                  type="button"
                  onClick={() => setButtonClass('btn btn-secondary')}
                >
                  وسط
                </button>
                <button
                  className={classNames('btn btn-blue-outline', buttonClass.includes('btn-lg') ? 'active' : '')}
                  type="button"
                  onClick={() => setButtonClass('btn btn-secondary btn-lg')}
                >
                  كبير
                </button>
              </div>
            </FormGroup>
            <FormGroup className="form-group col-md-3">
              <Label className="text-gray mb-1">
                تدوير الإطار (أعلى اليمين)
              </Label>
              <Input
                value={borderTopRightRadius}
                onChange={(e) => setBorderTopRightRadius(e.target.value)}
                type="number"
              />
            </FormGroup>
            <FormGroup className="form-group col-md-3">
              <Label className="text-gray mb-1">
                تدوير الإطار (أعلى اليسار)
              </Label>
              <Input
                value={borderTopLeftRadius}
                onChange={(e) => setBorderTopLeftRadius(e.target.value)}
                type="number"
              />
            </FormGroup>
            <FormGroup className="form-group col-md-3">
              <Label className="text-gray mb-1">
                تدوير الإطار (أسفل اليمين)
              </Label>
              <Input
                value={borderBottomRightRadius}
                onChange={(e) => setBorderBottomRightRadius(e.target.value)}
                type="number"
              />
            </FormGroup>
            <FormGroup className="form-group col-md-3">
              <Label className="text-gray mb-1">
                تدوير الإطار (أسفل اليسار)
              </Label>
              <Input
                value={borderBottomLeftRadius}
                onChange={(e) => setBorderBottomLeftRadius(e.target.value)}
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-6 mb-3">
              <Label className="text-gray">لون الخلفية</Label>
              <ColorInputField
                value={backgroundColor || ''}
                onChange={handleBackgroundColorChange}
                style={{ backgroundColor }}
              />
            </FormGroup>
            <FormGroup className="col-md-6 mb-3">
              <Label className="text-gray">لون النص</Label>
              <ColorInputField
                value={textColor || ''}
                onChange={handleTextColorChange}
                style={{ backgroundColor: textColor }}
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className="text-gray">لون الإطار</Label>
              <ColorInputField
                value={borderColor || ''}
                onChange={handleBorderColorChange}
                style={{ backgroundColor: borderColor }}
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <button type="button" className="btn btn-red me-2" onClick={deleteButton}>حذف الزر</button>
          <div className="d-flex align-items-center">
            <button type="button" className="btn btn-navy me-2" onClick={updateConfigValue}>حفظ</button>
            <button type="button" className="btn btn-blue-outline" onClick={() => toggleModal(!isModalOpen)}>إلغاء</button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Button;
