/* eslint-disable max-len */
/* eslint-disable max-lines */
import teamOneThumbnail from '../../assets/images/thumbnails/team-one.svg';
import teamTwoThumbnail from '../../assets/images/thumbnails/team-two.svg';

const team = [
  {
    id: 'a66fc50d-6b9e-4291-9313-0b0e038a489a',
    title: 'Team',
    elements: [
      {
        id: 'd8de08b9-4c48-4243-ae6e-563913b19f5b',
        thumbnail: teamOneThumbnail,
        data: {
          type: 'team01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'team-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-4',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-3',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'team-card',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex flex-column align-items-center justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '100px',
                                          'border-top-left-radius': '100px',
                                          'border-bottom-right-radius': '100px',
                                          'border-bottom-left-radius': '100px',
                                          height: '100px',
                                          width: '100px',
                                        },
                                      },
                                    },
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'الاسم الكامل',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mt-3 mb-1',
                                        style: {
                                          'text-align': 'center',
                                        },
                                      },
                                    },
                                    {
                                      type: 'list',
                                      metadata: {
                                        listType: 'unorderlist',
                                      },
                                      interface_data: {
                                        class: 'mb-0 p-0',
                                      },
                                      items: [
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-3',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'team-card',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex flex-column align-items-center justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '100px',
                                          'border-top-left-radius': '100px',
                                          'border-bottom-right-radius': '100px',
                                          'border-bottom-left-radius': '100px',
                                          height: '100px',
                                          width: '100px',
                                        },
                                      },
                                    },
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'الاسم الكامل',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mt-3 mb-1',
                                        style: {
                                          'text-align': 'center',
                                        },
                                      },
                                    },
                                    {
                                      type: 'list',
                                      metadata: {
                                        listType: 'unorderlist',
                                      },
                                      interface_data: {
                                        class: 'mb-0 p-0',
                                      },
                                      items: [
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-3',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'team-card',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex flex-column align-items-center justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '100px',
                                          'border-top-left-radius': '100px',
                                          'border-bottom-right-radius': '100px',
                                          'border-bottom-left-radius': '100px',
                                          height: '100px',
                                          width: '100px',
                                        },
                                      },
                                    },
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'الاسم الكامل',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mt-3 mb-1',
                                        style: {
                                          'text-align': 'center',
                                        },
                                      },
                                    },
                                    {
                                      type: 'list',
                                      metadata: {
                                        listType: 'unorderlist',
                                      },
                                      interface_data: {
                                        class: 'mb-0 p-0',
                                      },
                                      items: [
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-3',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'team-card',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex flex-column align-items-center justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '100px',
                                          'border-top-left-radius': '100px',
                                          'border-bottom-right-radius': '100px',
                                          'border-bottom-left-radius': '100px',
                                          height: '100px',
                                          width: '100px',
                                        },
                                      },
                                    },
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'الاسم الكامل',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mt-3 mb-1',
                                        style: {
                                          'text-align': 'center',
                                        },
                                      },
                                    },
                                    {
                                      type: 'list',
                                      metadata: {
                                        listType: 'unorderlist',
                                      },
                                      interface_data: {
                                        class: 'mb-0 p-0',
                                      },
                                      items: [
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          type: 'listElement',
                                          interface_data: {
                                            class: 'list-inline-item',
                                          },
                                          items: [
                                            {
                                              type: 'link',
                                              metadata: {
                                                linkPoint: 'external',
                                                externalLink: '',
                                                target: '_self',
                                              },
                                              items: [
                                                {
                                                  type: 'image',
                                                  metadata: {
                                                    initialized: true,
                                                  },
                                                  interface_data: {
                                                    class: 'icon',
                                                    alt: '',
                                                    style: {
                                                      'object-fit': 'cover',
                                                      'border-top-right-radius': '35px',
                                                      'border-top-left-radius': '35px',
                                                      'border-bottom-right-radius': '35px',
                                                      'border-bottom-left-radius': '35px',
                                                      height: '35px',
                                                      width: '35px',
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'a5673ca9-accd-4ca1-8400-985cd927a5ab',
        thumbnail: teamTwoThumbnail,
        data: {
          type: 'team02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'team-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-4',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'team-card',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'row',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'col-md-6',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: '',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '0px',
                                              'border-top-left-radius': '0px',
                                              'border-bottom-right-radius': '0px',
                                              'border-bottom-left-radius': '0px',
                                              height: '265px',
                                              width: '100%',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'col-md-6',
                                      },
                                      items: [
                                        {
                                          type: 'heading',
                                          metadata: {
                                            text: 'الاسم الكامل',
                                            level: 4,
                                          },
                                          interface_data: {
                                            class: 'mb-1',
                                            style: {
                                              'text-align': 'right',
                                            },
                                          },
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                          },
                                          interface_data: {
                                            class: 'small mb-0',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'team-card',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'row',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'col-md-6',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: '',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '0px',
                                              'border-top-left-radius': '0px',
                                              'border-bottom-right-radius': '0px',
                                              'border-bottom-left-radius': '0px',
                                              height: '265px',
                                              width: '100%',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'col-md-6',
                                      },
                                      items: [
                                        {
                                          type: 'heading',
                                          metadata: {
                                            text: 'الاسم الكامل',
                                            level: 4,
                                          },
                                          interface_data: {
                                            class: 'mb-1',
                                            style: {
                                              'text-align': 'right',
                                            },
                                          },
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                          },
                                          interface_data: {
                                            class: 'small mb-0',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default team;
