import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import HeaderLogo from '../../../components/header-logo';
import illustration from '../../../assets/images/email-verified.svg';

function ConfirmEmail() {
  const [isPlaceholderVisible, showPlaceholder] = useState(false);
  const [count, setCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.search;
    const queryParams = new URLSearchParams(url);
    const requestUrl = queryParams.get('url');

    if (requestUrl) {
      axios.get(requestUrl)
        .then((response) => {
          localStorage.setItem('api_token', response?.data?.api_token);
          showPlaceholder(true);
        });
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);

    if (count === 0) {
      navigate('/projects');
    }

    return () => clearInterval(interval);
  }, [count, navigate]);

  if (!isPlaceholderVisible) return null;

  return (
    <section className="auth-container">
      <HeaderLogo />
      <main>
        <Container>
          <Row className="justify-content-center">
            <Col md={4}>
              <div className="white-card d-flex flex-column justify-content-center align-items-center">
                <img src={illustration} alt="email" height={150} />
                <h5 className="text-gray text-center fw-bold mb-0 mt-4">
                  تم التحقق من البريد الإلكتروني بنجاح
                </h5>
                <h5 className="mb-0 text-center text-blue mt-3">
                  سيتم إعادة توجيهك إلى الصفحة الرئيسية بعد
                  {' '}
                  {count}
                  {' '}
                  ثواني
                  {' '}
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </section>
  );
}

export default ConfirmEmail;
