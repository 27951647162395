import React from 'react';
import { Link, useParams } from 'react-router-dom';

function Anchor({ config, previewComponent, children }) {
  const params = useParams();
  if (!previewComponent) {
    return config?.metadata?.text || children;
  }

  if (config?.metadata?.linkPoint === 'page') {
    return (
      <Link
        to={`/preview/${params.projectId}/pages/${config.metadata.pageId}`}
        target={config.metadata.target}
        rel="noreferrer"
        id="preview_project"
      >
        {config?.metadata?.text || children}
      </Link>
    );
  }
  return (
    <a
      href={config.metadata.externalLink}
      target={config.metadata.target}
      rel="noreferrer"
    >
      {config?.metadata?.text || children}
    </a>
  );
}

export default Anchor;
