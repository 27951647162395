import React, {
  useCallback, useState, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import { debounce } from '../../../../../helpers';
import { editPage } from '../../../../../redux/actions/pages.actions';
import DeletePage from '../delete';
import ColorInputField from '../../../../../components/color-input';

function PageSettings({
  page, pageId, projectId, getPageDetails, updatePages,
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState(page);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);

  const onSubmit = useCallback(debounce((values) => {
    dispatch(editPage(projectId, pageId, values)).then((response) => {
      setData(response?.data);
      getPageDetails();
    });
  }, 3000), [getPageDetails, setData]);

  useEffect(() => {
    setData(page);
  }, [page?.id]);

  const handleIsHomePageChange = (e) => {
    const { checked } = e.target;
    setData({
      ...data,
      is_homepage: checked,
      style: data?.style ? { ...data?.style } : {},
      seo: data?.seo ? { ...data?.seo } : {},
    });
    const newValues = {
      ...page,
      is_homepage: checked,
      style: data?.style ? { ...data?.style } : {},
      seo: data?.seo ? { ...data?.seo } : {},
    };
    onSubmit(newValues);
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setData({
      ...data,
      title: value,
      slug: data?.slug,
      style: data?.style ? { ...data?.style } : {},
      seo: data?.seo ? { ...data?.seo } : {},
    });
    const newValues = {
      ...page,
      title: value,
      slug: data?.slug,
      style: data?.style ? { ...data?.style } : {},
      seo: data?.seo ? { ...data?.seo } : {},
    };
    onSubmit(newValues);
  };

  const handleSlugChange = (e) => {
    const { value } = e.target;
    setData({
      ...data,
      title: data?.title,
      slug: value,
      style: data?.style ? { ...data?.style } : {},
      seo: data?.seo ? { ...data?.seo } : {},
    });
    const newValues = {
      ...page,
      title: data?.title,
      slug: value,
      style: data?.style ? { ...data?.style } : {},
      seo: data?.seo ? { ...data?.seo } : {},
    };
    onSubmit(newValues);
  };

  const handleSeoTitleChange = (e) => {
    const { value } = e.target;
    setData({
      ...data,
      seo: {
        ...data?.seo,
        title: value,
      },
    });
    const newValues = {
      ...page,
      seo: {
        ...data?.seo,
        title: value,
      },
    };
    onSubmit(newValues);
  };

  const handleSeoDescriptionChange = (e) => {
    const { value } = e.target;
    setData({
      ...data,
      seo: {
        ...data?.seo,
        description: value,
      },
    });
    const newValues = {
      ...page,
      seo: {
        ...data?.seo,
        description: value,
      },
    };
    onSubmit(newValues);
  };

  const handleBackgroundColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setData({
      ...data,
      seo: data?.seo ? { ...data?.seo } : {},
      style: {
        ...data?.style,
        'background-color': color,
      },
    });
    const newValues = {
      ...page,
      seo: data?.seo ? { ...data?.seo } : {},
      style: {
        ...data?.style,
        'background-color': color,
      },
    };
    onSubmit(newValues);
  };

  const handleTextColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setData({
      ...data,
      seo: data?.seo ? { ...data?.seo } : {},
      style: {
        ...data?.style,
        color,
      },
    });
    const newValues = {
      ...page,
      seo: data?.seo ? { ...data?.seo } : {},
      style: {
        ...data?.style,
        color,
      },
    };
    onSubmit(newValues);
  };
  return (
    <>
      <div className="mb-2 form-check">
        <input
          className="form-check-input"
          name="is_homepage"
          type="checkbox"
          id="is_homepage"
          checked={data?.is_homepage}
          onChange={handleIsHomePageChange}
        />
        <Label className="text-gray">
          صفحة رئيسية
        </Label>
      </div>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray">عنوان الصفحة</Label>
        <Input
          name="title"
          type="text"
          value={data?.title || ''}
          onChange={handleTitleChange}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray">رابط الصفحة</Label>
        <Input
          name="slug"
          type="text"
          value={data?.slug || ''}
          onChange={handleSlugChange}
          dir="ltr"
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray">عنوان محركات البحث</Label>
        <Input
          name="seo_title"
          type="text"
          value={data?.seo?.title || ''}
          onChange={handleSeoTitleChange}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray">الوصف لمحركات البحث</Label>
        <Input
          name="seo_description"
          type="textarea"
          rows="3"
          value={data?.seo?.description || ''}
          onChange={handleSeoDescriptionChange}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray">لون النص</Label>
        <ColorInputField
          value={data?.style?.color || ''}
          onChange={handleTextColorChange}
          style={{ backgroundColor: data?.style?.color }}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray">لون الخلفية</Label>
        <ColorInputField
          value={(data?.style && data?.style['background-color']) || ''}
          onChange={handleBackgroundColorChange}
          style={{ backgroundColor: (data?.style && data?.style['background-color']) }}
        />
      </FormGroup>
      {!page?.is_homepage
        && (
          <button className="btn btn-red w-100" type="button" onClick={() => toggleDeleteModal(!isDeleteModalOpen)} id="delete_page">
            حذف الصفحة
          </button>
        )}
      <DeletePage
        projectId={projectId}
        pageId={pageId}
        isModalOpen={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        updatePages={updatePages}
      />
    </>
  );
}
export default PageSettings;
