const cardsReducer = (state = {}, action) => {
  switch (action.type) {
  case 'GET_CARDS':
    if (action.error) return state;
    return {
      ...state,
      list: action.data,
    };

  default:
    return state;
  }
};

export default cardsReducer;
