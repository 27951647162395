import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { passwordlessLogin } from '../../../redux/actions/passwordless.actions';

function MagicLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.search;
    const queryParams = new URLSearchParams(url);
    const token = queryParams.get('token');

    if (token) {
      dispatch(passwordlessLogin({ token })).then(() => {
        navigate('/projects');
      }).catch((e) => {
        navigate('/auth/login-link', { state: { error: e?.response?.data?.message } });
      });
    }
  }, []);
  return null;
}

export default MagicLink;
