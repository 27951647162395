const userReducer = (state = {}, action) => {
  switch (action.type) {
  case 'GET_USER':
  case 'UPDATE_USER':
    if (action.error) return state;
    return {
      ...state,
      data: action.data.data,
    };

  default:
    return state;
  }
};

export default userReducer;
