import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import SubscriptionDetails from './details';
import Transactions from './transactions';
import PaymentMethods from './cards';
import Subscribe from '../../projects/details/subscribe';
import AddCard from './add-card';
import { stripePromise } from '../../../App';

function Subscriptions() {
  const [clientSecret, setClientSecret] = useState(undefined);
  const [isSubscribeModalOpen, toggleSubscribeModal] = useState(false);
  const [isCreateModalOpen, toggleCreateModal] = useState(false);

  const updateClientSecret = useCallback((value) => {
    setClientSecret(value);
  }, [toggleSubscribeModal, setClientSecret]);

  const openSubscribeModal = useCallback(() => {
    toggleSubscribeModal(true);
  }, [toggleSubscribeModal]);

  const openCreateModal = useCallback(() => {
    toggleCreateModal(true);
  }, [toggleCreateModal]);
  return (
    <>
      <Row className="mt-4">
        <Col md={7}>
          <SubscriptionDetails openSubscribeModal={openSubscribeModal} />
          <Transactions />
        </Col>
        <Col md={5}>
          <PaymentMethods openCreateModal={openCreateModal} />
        </Col>
      </Row>
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <Subscribe
          isModalOpen={isSubscribeModalOpen}
          toggleModal={toggleSubscribeModal}
          updateClientSecret={updateClientSecret}
        />
        <AddCard
          isModalOpen={isCreateModalOpen}
          toggleModal={toggleCreateModal}
          updateClientSecret={updateClientSecret}
        />
      </Elements>
    </>

  );
}

export default Subscriptions;
