import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import Iframe from 'react-iframe';
import { useDispatch } from 'react-redux';
import PreviewNavbar from './navbar';
import { getPage } from '../../../redux/actions/pages.actions';

function ProjectPreview() {
  const dispatch = useDispatch();
  const [previewMode, setPreviewMode] = useState('desktop');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const params = useParams();

  const updateDimensions = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    const { projectId, pageId } = params;
    dispatch(getPage(projectId, pageId));
  }, [params?.pageId]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updatePreviewMode = useCallback((mode) => {
    setPreviewMode(mode);
  }, [setPreviewMode]);

  return (
    <>
      <PreviewNavbar mode={previewMode} updatePreviewMode={updatePreviewMode} />
      <div className="preview-wrapper" style={{ height: windowHeight - 70 }}>
        <Iframe
          url={`${window.location.origin}/iframe/${params.projectId}/pages/${params.pageId}`}
          width={previewMode === 'desktop' ? '100%' : '450px'}
          height={windowHeight - 70}
          className={previewMode === 'desktop' ? 'desktop' : 'mobile'}
          display="block"
        />
      </div>
    </>
  );
}

export default ProjectPreview;
