import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { deletePage } from '../../../../../redux/actions/pages.actions';

function DeletePage({
  isModalOpen, toggleModal, projectId, pageId, updatePages,
}) {
  const dispatch = useDispatch();

  const deletePageHandler = () => {
    dispatch(deletePage(projectId, pageId)).then(() => {
      updatePages();
      toggleModal();
    });
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="border-0 text-gray" toggle={() => toggleModal()}>
        حذف صفحة
      </ModalHeader>
      <ModalBody className="pb-0">
        <p className="text-gray">حذف الصفحة سيؤدي إلى خسارة جميع التعديلات التي تم إجراؤها، هل تود المتابعة؟</p>
      </ModalBody>
      <ModalFooter className="border-0">
        <button type="submit" className="btn btn-red px-4" onClick={deletePageHandler} id="confirm_delete_page">
          تأكيد
        </button>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_delete_page">
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default DeletePage;
