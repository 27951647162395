import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { updateNestedItems } from '../../../../../helpers';
import { updateSection } from '../../../../../redux/actions/sections.actions';
import EditForm from '../../../../../components/edit-form';

function Paragraph({
  config, projectId, pageId, parent, getPageDetails, previewComponent, updateProgressBar,
}) {
  const dispatch = useDispatch();
  const [editingMode, setEditingMode] = useState(false);
  const [value, setValue] = useState(config.metadata?.text);

  const updateConfigValue = () => {
    updateProgressBar(50);
    updateNestedItems(parent?.components, config.id, { metadata: { text: value } });
    dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
      setEditingMode(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  const toggleEditingMode = () => {
    setValue(config.metadata?.text);
    setEditingMode(true);
  };

  if (previewComponent) {
    return (
      <p className={config?.interface_data?.class}>
        {config.metadata?.text}
      </p>
    );
  }
  return (
    <>
      {editingMode
        ? (
          <EditForm
            className={classnames('p', config?.interface_data?.class)}
            value={value}
            onChange={setValue}
            onBlur={updateConfigValue}
            onCancel={() => setEditingMode(false)}
          />
        )
        : (
          <p className={classnames('component', config?.interface_data?.class)} onClick={toggleEditingMode}>
            {config.metadata?.text}
          </p>
        )}
    </>
  );
}

export default Paragraph;
