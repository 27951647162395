import React, {
  createElement, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getPage } from '../../../redux/actions/pages.actions';
import componentsKeys from '../details/components';

function ProjectIframe() {
  const [page, setPage] = useState({});

  const dispatch = useDispatch();
  const params = useParams();

  const getPageDetails = () => {
    const { projectId, pageId } = params;
    dispatch(getPage(projectId, pageId)).then((response) => {
      setPage(response.data);
    });
  };

  useEffect(() => {
    getPageDetails();
  }, [params?.pageId]);

  const renderChildren = (config, parent) => {
    const { projectId, pageId } = params;

    if (typeof componentsKeys[config.type] !== 'undefined') {
      return createElement(
        componentsKeys[config.type],
        {
          config,
          pageId,
          projectId,
          parent,
          getPageDetails,
          previewComponent: true,
        },
        config.items
          && (typeof config.items === 'string'
            ? config.items
            : config.items?.map((component) => renderChildren(component, parent))),
      );
    }
    return null;
  };

  const renderComponent = (config) => {
    const { projectId, pageId } = params;

    if (typeof componentsKeys[config.type] !== 'undefined') {
      return createElement(
        componentsKeys[config.type],
        {
          config,
          pageId,
          projectId,
          getPageDetails,
          previewComponent: true,
        },
        config.components
          && (typeof config.components === 'string'
            ? config.components
            : config.components?.map((component) => renderChildren(component, config))),
      );
    }
    return null;
  };
  return (
    <>
      <Helmet>
        <title>{page?.seo?.title}</title>
        <meta name="description" content={page?.seo?.description} />
        <style type="text/css">
          {`
          body {
            color: ${page?.style?.color};
            background-color: ${page?.style && page?.style['background-color']}
          }
        `}
        </style>
      </Helmet>
      {page.sections?.sort((a, b) => a.order - b.order)?.map((section) => {
        return (
          <div key={section.id}>
            {renderComponent(section)}
          </div>
        );
      })}
    </>
  );
}

export default ProjectIframe;
