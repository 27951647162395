import React, { useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, Row,
} from 'reactstrap';
import { updateNestedItems } from '../../../../../helpers';
import { updateSection } from '../../../../../redux/actions/sections.actions';

function Iframe({
  config, projectId, pageId, parent, getPageDetails, previewComponent, updateProgressBar,
}) {
  const [isModalOpen, toggleModal] = useState(false);
  const [src, setSrc] = useState(config.metadata?.src);
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const [heightUnit, setHeightUnit] = useState(undefined);
  const [widthUnit, setWidthUnit] = useState(undefined);
  const [isHeightDropdownOpen, toggleHeightDropdown] = useState(false);
  const [widthError, setWidthError] = useState('');
  const dispatch = useDispatch();

  const onOpened = () => {
    setHeight(config?.metadata?.height?.replace(/\D/g, '') || '');
    setWidth(config?.metadata?.width?.replace(/\D/g, '') || '');
    setHeightUnit(config?.metadata?.height?.replace(/[0-9]/g, '') || '');
    setWidthUnit(config?.metadata?.width?.replace(/[0-9]/g, '') || '');
    setWidthError('');
  };

  const updateConfigValue = () => {
    updateProgressBar(50);
    const updatedConfig = {
      ...config,
      metadata: {
        ...config?.metadata,
        src,
        width: `${width}${widthUnit}`,
        height: `${height}${heightUnit}`,
      },
    };
    updateNestedItems(parent?.components, config.id, updatedConfig);
    dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
      toggleModal(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  if (previewComponent) {
    return (
      <iframe title="iframe" src={config?.metadata?.src} height={config?.metadata?.height} width={config?.metadata?.width} />
    );
  }

  return (
    <>
      <div className="iframe-wrapper" onClick={() => toggleModal(!isModalOpen)}>
        <iframe title="iframe" src={config?.metadata?.src} height={config?.metadata?.height} width={config?.metadata?.width} />
      </div>
      <Modal onOpened={onOpened} toggle={() => toggleModal(!isModalOpen)} isOpen={isModalOpen} size="lg">
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إعدادات الفيديو</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody>
          <Form className="iframe-form">
            <Row>
              <FormGroup className="col-12 form-group">
                <Label className="text-gray mb-1">
                  تعديل الرابط
                </Label>
                <Input dir="ltr" name="text" value={src} onChange={(e) => setSrc(e.target.value)} />
              </FormGroup>
              <FormGroup className="form-group col-md-6">
                <Label className="text-gray mb-1">
                  الطول
                </Label>
                <InputGroup>
                  <Input value={height} onChange={(e) => setHeight(e.target.value)} type="number" />
                  <ButtonDropdown isOpen={isHeightDropdownOpen} toggle={() => toggleHeightDropdown(!isHeightDropdownOpen)}>
                    <DropdownToggle caret>
                      {heightUnit}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setHeightUnit('px')}>
                        pixels
                      </DropdownItem>
                      <DropdownItem onClick={() => setHeightUnit('%')}>
                        percentage
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </InputGroup>
              </FormGroup>
              <FormGroup className="form-group col-md-6">
                <Label className="text-gray mb-1">
                  العرض
                </Label>
                <InputGroup>
                  <Input
                    value={width}
                    onChange={(e) => {
                      setWidth(e.target.value);
                      if (e.target.value > 100) {
                        setWidthError('عرض الفيديو لا يمكن أن يتجاوز 100%');
                      } else {
                        setWidthError('');
                      }
                    }}
                    type="number"
                  />
                  <InputGroupText>
                    {widthUnit}
                  </InputGroupText>
                </InputGroup>
                {widthError && <h6 className="text-red mt-2 small mb-0">{widthError}</h6>}
              </FormGroup>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-end">
          <button type="button" className="btn btn-navy me-2" onClick={updateConfigValue}>حفظ</button>
          <button type="button" className="btn btn-blue-outline" onClick={() => toggleModal(!isModalOpen)}>إلغاء</button>
        </ModalFooter>
      </Modal>
    </>

  );
}

export default Iframe;
