import React, { useEffect, useState } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Api from '../../../../services';

function NavigationBar() {
  const navigate = useNavigate();
  const params = useParams();
  const [homepage, setHomepage] = useState(undefined);

  useEffect(() => {
    Api.projects.getProject(params.projectId).then((response) => {
      setHomepage(response.data.homepage);
    });
  }, []);
  return (
    <div className="header-wrapper bg-light">
      <Row>
        <Col md={6} className="d-flex align-items-center">
          <button
            type="button"
            onClick={() => navigate(`/builder/${params.projectId}/pages/${homepage?.id}`)}
            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
            id="navigate_builder_settings"
          >
            <ArrowRight size={20} className="me-2" />
            العودة إلى التصميم
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default NavigationBar;
