import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const token = localStorage.getItem('api_token');
  if (token) {
    return true;
  }
  return false;
};

function PublicRoutes() {
  const auth = useAuth();

  return auth ? <Navigate to="/projects" /> : <Outlet />;
}

export default PublicRoutes;
