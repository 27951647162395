/* eslint-disable max-len */
import contactUsOneThumbnail from '../../assets/images/thumbnails/contact-us-one.svg';
import contactUsTwoThumbnail from '../../assets/images/thumbnails/contact-us-two.svg';

const contactUs = [
  {
    id: 'daa27b56-a670-4c00-bd13-26c2f84deeed',
    title: 'Contact us',
    elements: [
      {
        id: '7a9c42bb-e91e-48af-bd39-a6f084042c8e',
        thumbnail: contactUsOneThumbnail,
        data: {
          type: 'contact_us01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'cta-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12 d-flex flex-column align-items-center',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'تواصل معنا',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-1',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'text-center w-50 mb-4',
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'contact-us-card d-flex flex-column align-items-center justify-content-center',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      width: '65px',
                                      height: '65px',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'تفاصيل العنوان',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0 mt-1 ltr',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'contact-us-card d-flex flex-column align-items-center justify-content-center',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      width: '65px',
                                      height: '65px',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'mail@example.com',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0 mt-1 ltr',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'contact-us-card d-flex flex-column align-items-center justify-content-center',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      width: '65px',
                                      height: '65px',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: '+966507125152',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0 mt-1 ltr',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'd9bc836b-b133-4060-b65d-a27b4e6427cc',
        thumbnail: contactUsTwoThumbnail,
        data: {
          type: 'contact_us02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'cta-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6 d-flex flex-column justify-content-center',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'تواصل معنا',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-1',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'mb-0',
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'contact-us-card d-flex flex-column align-items-center justify-content-center',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: '',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '0px',
                                              'border-top-left-radius': '0px',
                                              'border-bottom-right-radius': '0px',
                                              'border-bottom-left-radius': '0px',
                                              width: '65px',
                                              height: '65px',
                                            },
                                          },
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'mail@example.com',
                                          },
                                          interface_data: {
                                            class: 'text-center mb-0 mt-1 ltr',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'contact-us-card d-flex flex-column align-items-center justify-content-center',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: '',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '0px',
                                              'border-top-left-radius': '0px',
                                              'border-bottom-right-radius': '0px',
                                              'border-bottom-left-radius': '0px',
                                              width: '65px',
                                              height: '65px',
                                            },
                                          },
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: '+966507125152',
                                          },
                                          interface_data: {
                                            class: 'text-center mb-0 mt-1 ltr',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default contactUs;
