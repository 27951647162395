/* eslint-disable max-lines */
/* eslint-disable max-len */
import featuresOneThumbnail from '../../assets/images/thumbnails/features-one.svg';
import featuresTwoThumbnail from '../../assets/images/thumbnails/features-two.svg';
import featuresThreeThumbnail from '../../assets/images/thumbnails/features-three.svg';

const features = [
  {
    id: '389f0c85-76e9-4a8d-8222-0491aad16cdd',
    title: 'Features',
    elements: [
      {
        id: 'fe805bcb-ff99-4ac0-861b-ccc90d599883',
        thumbnail: featuresOneThumbnail,
        data: {
          type: 'feature01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'features-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  width: '100%',
                                  height: '500px',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6 d-flex flex-column justify-content-center',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-12',
                                  },
                                  items: [
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'العنوان الرئيسي',
                                        level: 2,
                                      },
                                      interface_data: {
                                        class: 'mb-4',
                                        style: {
                                          'text-align': 'right',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: 'div',
                              interface_data: {
                                class: 'row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'mb-md-0 mb-3',
                                      },
                                      items: [
                                        {
                                          type: 'div',
                                          interface_data: {
                                            class: 'd-flex align-items-center mb-2',
                                          },
                                          items: [
                                            {
                                              type: 'image',
                                              metadata: {
                                                initialized: true,
                                              },
                                              interface_data: {
                                                class: '',
                                                alt: '',
                                                style: {
                                                  'object-fit': 'cover',
                                                  'border-top-right-radius': '80px',
                                                  'border-top-left-radius': '80px',
                                                  'border-bottom-right-radius': '80px',
                                                  'border-bottom-left-radius': '80px',
                                                  height: '80px',
                                                  width: '80px',
                                                },
                                              },
                                            },
                                            {
                                              type: 'heading',
                                              metadata: {
                                                text: 'عنوان ثانوي',
                                                level: 4,
                                              },
                                              interface_data: {
                                                class: 'mb-0 ms-2',
                                                style: {
                                                  'text-align': 'right',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                          },
                                          interface_data: {
                                            class: 'small',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'mb-md-0 mb-3',
                                      },
                                      items: [
                                        {
                                          type: 'div',
                                          interface_data: {
                                            class: 'd-flex align-items-center mb-2',
                                          },
                                          items: [
                                            {
                                              type: 'image',
                                              metadata: {
                                                initialized: true,
                                              },
                                              interface_data: {
                                                class: '',
                                                alt: '',
                                                style: {
                                                  'object-fit': 'cover',
                                                  'border-top-right-radius': '80px',
                                                  'border-top-left-radius': '80px',
                                                  'border-bottom-right-radius': '80px',
                                                  'border-bottom-left-radius': '80px',
                                                  height: '80px',
                                                  width: '80px',
                                                },
                                              },
                                            },
                                            {
                                              type: 'heading',
                                              metadata: {
                                                text: 'عنوان ثانوي',
                                                level: 4,
                                              },
                                              interface_data: {
                                                class: 'mb-0 ms-2',
                                                style: {
                                                  'text-align': 'right',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                          },
                                          interface_data: {
                                            class: 'small',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'mb-md-0 mb-3',
                                      },
                                      items: [
                                        {
                                          type: 'div',
                                          interface_data: {
                                            class: 'd-flex align-items-center mb-2',
                                          },
                                          items: [
                                            {
                                              type: 'image',
                                              metadata: {
                                                initialized: true,
                                              },
                                              interface_data: {
                                                class: '',
                                                alt: '',
                                                style: {
                                                  'object-fit': 'cover',
                                                  'border-top-right-radius': '80px',
                                                  'border-top-left-radius': '80px',
                                                  'border-bottom-right-radius': '80px',
                                                  'border-bottom-left-radius': '80px',
                                                  height: '80px',
                                                  width: '80px',
                                                },
                                              },
                                            },
                                            {
                                              type: 'heading',
                                              metadata: {
                                                text: 'عنوان ثانوي',
                                                level: 4,
                                              },
                                              interface_data: {
                                                class: 'mb-0 ms-2',
                                                style: {
                                                  'text-align': 'right',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                          },
                                          interface_data: {
                                            class: 'small',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'div',
                                      interface_data: {
                                        class: 'mb-md-0 mb-3',
                                      },
                                      items: [
                                        {
                                          type: 'div',
                                          interface_data: {
                                            class: 'd-flex align-items-center mb-2',
                                          },
                                          items: [
                                            {
                                              type: 'image',
                                              metadata: {
                                                initialized: true,
                                              },
                                              interface_data: {
                                                class: '',
                                                alt: '',
                                                style: {
                                                  'object-fit': 'cover',
                                                  'border-top-right-radius': '80px',
                                                  'border-top-left-radius': '80px',
                                                  'border-bottom-right-radius': '80px',
                                                  'border-bottom-left-radius': '80px',
                                                  height: '80px',
                                                  width: '80px',
                                                },
                                              },
                                            },
                                            {
                                              type: 'heading',
                                              metadata: {
                                                text: 'عنوان ثانوي',
                                                level: 4,
                                              },
                                              interface_data: {
                                                class: 'mb-0 ms-2',
                                                style: {
                                                  'text-align': 'right',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          type: 'paragraph',
                                          metadata: {
                                            text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                          },
                                          interface_data: {
                                            class: 'small',
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'a7525bd4-f3e6-41ae-8ea7-105abb779aed',
        thumbnail: featuresTwoThumbnail,
        data: {
          type: 'feature02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'features-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-4',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mb-md-0 mb-3',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '200px',
                                      width: '100%',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-4',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: 'text-center small',
                                  },
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'linkButton',
                                      metadata: {
                                        text: 'زر رئيسي',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      interface_data: {
                                        class: 'btn btn-secondary',
                                        style: {
                                          'border-top-right-radius': '5px',
                                          'border-top-left-radius': '5px',
                                          'border-bottom-right-radius': '5px',
                                          'border-bottom-left-radius': '5px',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mb-md-0 mb-3',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '200px',
                                      width: '100%',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-4',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: 'text-center small',
                                  },
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'linkButton',
                                      metadata: {
                                        text: 'زر رئيسي',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      interface_data: {
                                        class: 'btn btn-secondary',
                                        style: {
                                          'border-top-right-radius': '5px',
                                          'border-top-left-radius': '5px',
                                          'border-bottom-right-radius': '5px',
                                          'border-bottom-left-radius': '5px',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mb-md-0 mb-3',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '200px',
                                      width: '100%',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-4',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: 'text-center small',
                                  },
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'd-flex justify-content-center',
                                  },
                                  items: [
                                    {
                                      type: 'linkButton',
                                      metadata: {
                                        text: 'زر رئيسي',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      interface_data: {
                                        class: 'btn btn-secondary',
                                        style: {
                                          'border-top-right-radius': '5px',
                                          'border-top-left-radius': '5px',
                                          'border-bottom-right-radius': '5px',
                                          'border-bottom-left-radius': '5px',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'f447ed99-59d6-4bdd-a487-7e4b620b9969',
        thumbnail: featuresThreeThumbnail,
        data: {
          type: 'feature03',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'features-three',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-0',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mt-4',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: 'd-flex mx-auto',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-3 mb-1',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'استخدم هذا النص لوصف ميزة المنتج الخاص بك',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mt-4',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: 'd-flex mx-auto',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-3 mb-1',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'استخدم هذا النص لوصف ميزة المنتج الخاص بك',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mt-4',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: 'd-flex mx-auto',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-3 mb-1',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'استخدم هذا النص لوصف ميزة المنتج الخاص بك',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mt-4',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: 'd-flex mx-auto',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-3 mb-1',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'استخدم هذا النص لوصف ميزة المنتج الخاص بك',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mt-4',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: 'd-flex mx-auto',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-3 mb-1',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'استخدم هذا النص لوصف ميزة المنتج الخاص بك',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'mt-4',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: 'd-flex mx-auto',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'عنوان ثانوي',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mt-3 mb-1',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'استخدم هذا النص لوصف ميزة المنتج الخاص بك',
                                  },
                                  interface_data: {
                                    class: 'text-center mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default features;
