import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProject } from '../../../../../../redux/actions/projects.actions';

function DeleteProject({
  isModalOpen, toggleModal, projectId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteProjectHandler = () => {
    dispatch(deleteProject(projectId)).then(() => {
      navigate('/projects');
      toggleModal();
    });
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="border-0 text-gray" toggle={() => toggleModal()}>
        حذف مشروع
      </ModalHeader>
      <ModalBody className="pb-0">
        <p className="text-gray"> حذف المشروع سيؤدي إلى خسارة جميع التعديلات التي تم إجراؤها، هل تود المتابعة؟</p>
      </ModalBody>
      <ModalFooter className="border-0">
        <button type="submit" className="btn btn-red px-4" onClick={deleteProjectHandler} id="delete_project">
          تأكيد
        </button>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_delete_project">
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteProject;
