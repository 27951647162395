import React, { useEffect, useState } from 'react';
import { CardChecklist, Check2, XLg } from 'react-bootstrap-icons';
import {
  Col,
  FormGroup,
  Input,
  Label,
  List,
  Modal, ModalBody, ModalFooter, Row, Spinner,
} from 'reactstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Api from '../../../../services';
import masterCard from '../../../../assets/images/mastercard.png';
import visa from '../../../../assets/images/visa.png';
import { getUser } from '../../../../redux/actions/user.actions';
import { getCards } from '../../../../redux/actions/cards.actions';

function Subscribe({
  isModalOpen, toggleModal, updateClientSecret,
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState(undefined);
  const [name, setName] = useState('');
  const [selectedCardId, setSelectedCardId] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [selectedPeriodId, setSelectedPeriodId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [periods, setPeriods] = useState([]);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const cards = useSelector((state) => state.cards.list);

  useEffect(() => {
    if (!selectedPlan) return;

    setLoading(true);
    Api.plans.getPlans().then((response) => {
      const periods = response.data.map((plan) => plan.periods.filter((period) => period?.duration === selectedPlan)).flat();
      setPeriods(periods);
      setSelectedPeriodId(periods[0]?.id);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [selectedPlan]);

  const getPlans = () => {
    setLoading(true);
    Api.plans.getPlans().then((response) => {
      const periods = response.data.map((plan) => plan.periods.filter((period) => period?.duration === selectedPlan)).flat();
      setPeriods(periods);
      setSelectedPeriodId(periods[0]?.id);
      setLoading(false);
    }).catch(() => setLoading(false));
  };

  const onModalOpen = () => {
    setName('');
    setSelectedPlan('monthly');
    setSelectedCardId(undefined);
    setSelectedPeriodId(undefined);

    Api.payments.getSessionKey().then((response) => {
      setClientSecret(response?.data['session-key']);
      updateClientSecret();
    });

    dispatch(getCards());
    getPlans();
  };

  const cardOptions = {
    style: {
      base: {
        color: '#606060',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        fontFamily: 'Tajawal, sans-serif',
        '::placeholder': {
          color: '#606060',
        },
      },
      invalid: {
        color: '#FF4D4F',
        iconColor: '#FF4D4F',
      },
    },
    hidePostalCode: true,
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setSelectedCardId(undefined);
    setError(event.error ? event.error.message : '');
  };

  const addCard = async () => {
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
          },
        },
      }).then((result) => {
        if (result?.error) {
          setProcessing(false);
          setSucceeded(false);
          setError(result?.error?.message);
        } else {
          setError(null);
          Api.payments.addCard({
            card_data: {
              payment_method_id: result?.setupIntent?.payment_method,
            },
            is_default: false,
          }).then((response) => {
            Api.payments.subscribe({ period: selectedPeriodId, card_id: response?.data?.id }).then(() => {
              setProcessing(false);
              setSucceeded(true);
              dispatch(getUser());
              dispatch(getCards());
              toggleModal();
            });
          }).catch(() => {
            setProcessing(false);
            setSucceeded(false);
          });
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCardId) {
      Api.payments.subscribe({ period: selectedPeriodId, card_id: selectedCardId }).then(() => {
        setProcessing(false);
        setSucceeded(true);
        dispatch(getUser());
        dispatch(getCards());
        toggleModal();
      });
    } else {
      addCard();
    }
  };

  const renderBrandImage = (brand) => {
    switch (brand) {
    case 'mastercard':
      return <img src={masterCard} alt="mastercard" height={25} />;
    case 'visa':
      return <img src={visa} alt="visa" height={10} />;

    default:
      return <CardChecklist />;
    }
  };

  return (
    <Modal onOpened={onModalOpen} centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <form onSubmit={handleSubmit}>
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">ترقية الباقة</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody className="p-0">
          <div className="px-3 pt-4">
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <div className="btns-container">
                  <button
                    className={classNames('btn btn-blue-outline px-4', selectedPlan === 'monthly' ? 'active' : '')}
                    type="button"
                    onClick={() => {
                      setSelectedPlan('monthly');
                      setSelectedPeriodId(undefined);
                    }}
                    id="monthly-plan"
                  >
                    شهري
                  </button>
                  <button
                    className={classNames('btn btn-blue-outline px-4', selectedPlan === 'yearly' ? 'active' : '')}
                    type="button"
                    onClick={() => {
                      setSelectedPlan('yearly');
                      setSelectedPeriodId(undefined);
                    }}
                    id="yearly-plan"
                  >
                    سنوي
                  </button>
                </div>
              </Col>
              <Col md={12}>
                {loading
                  ? (
                    <>
                      <Skeleton height={48} width="100%" className="mt-3" />
                      <Skeleton height={48} width="100%" className="mt-3" />
                    </>
                  )
                  : (
                    <>
                      {periods?.map((period) => {
                        return (
                          <div className="plan-card" key={period?.id}>
                            <div className="d-flex align-items-center justify-content-between">
                              <FormGroup check className="mb-0">
                                <Input
                                  name="period"
                                  type="radio"
                                  onChange={() => setSelectedPeriodId(period.id)}
                                  checked={period?.id === selectedPeriodId}
                                />
                                <Label check>
                                  {period?.name}
                                </Label>
                              </FormGroup>
                              <Label className="mb-0 text-navy h6">
                                {period?.price}
                                {' '}
                                /
                                {' '}
                                {period?.duration === 'monthly' ? 'شهرياً' : 'سنوياً'}
                              </Label>
                            </div>
                            {period?.id === selectedPeriodId
                          && (
                            <>
                              <h6 className="text-gray mt-3">تعرف على ميزات الباقة</h6>
                              <List className="mb-0" type="unstyled">
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  تصميم موقعك بدون خبرة برمجية
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  قوالب متجاوبة مع كافة أنواع الأجهزة والمتصفحات
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  التواصل مع الدعم الفني عبر البريد الإلكتروني
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  استضافة مجانية
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  تحسين الظهور على محركات البحث
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  شهادة الـ SSL
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  ربط الدومين الخاص بك بموقعك
                                </li>
                                <li className="text-gray">
                                  <span className="text-navy me-2">&#10004;</span>
                                  ساعة من الدعم الخاص لمساعدتك في انشاء موقعك
                                </li>
                              </List>
                            </>
                          )}
                          </div>
                        );
                      })}
                    </>
                  )}
              </Col>
            </Row>
          </div>
          <hr className="mb-3 border" />
          {cards?.data?.length > 0
          && (
            <div className="payment-form px-3 pt-1 pb-4">
              <h6 className="text-gray mb-2">اختر طريقة الدفع</h6>
              {cards?.data?.map((card) => {
                return (
                  <div
                    className={classNames('rounded p-2 mb-2 d-flex align-items-center justify-content-between cursor-pointer', selectedCardId === card?.id ? 'border-blue' : 'border')}
                    onClick={() => {
                      setDisabled(false);
                      setSelectedCardId(card?.id);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {renderBrandImage(card.brand)}
                      <h6 className="text-gray mb-0 ms-2">
                        ********
                        {card.masked_number}
                        {' '}
                        (
                        {card.expiry_month}
                        /
                        {card?.expiry_year}
                        )
                      </h6>
                    </div>
                    {selectedCardId === card?.id
                  && <Check2 size={24} className="text-blue" />}
                  </div>
                );
              })}
            </div>
          )}
          <div className="payment-form px-3 pt-1 pb-4">
            <h6 className="text-gray mb-2">إضافة طريقة دفع</h6>
            <input
              className="mb-3"
              placeholder="Card holder name"
              value={name}
              onChange={(e) => {
                setSelectedCardId(undefined);
                setName(e.target.value);
              }}
            />
            <CardElement className="card-element" options={cardOptions} onChange={handleChange} />
            {error && (
              <p className="mt-2 mb-0 text-red" dir="ltr">
                {error}
              </p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            disabled={processing || disabled || succeeded}
            id="submit"
            type="submit"
            className="btn btn-blue"
          >
            {processing ? (
              <Spinner className="text-white mt-1" size="sm" />
            ) : (
              'ادفع الآن'
            )}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default Subscribe;
