import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';
import parsePhoneNumber from 'libphonenumber-js';
import Loader from 'react-loaders';
import InputField from '../../../components/input';
import PhoneField from '../../../components/phone-input';
import schema from './schema';
import { setServerErrors } from '../../../helpers';
import { getUser, updateUser } from '../../../redux/actions/user.actions';
import SuccessAlert from '../../../components/success-alert';

function Profile() {
  const [isModalOpen, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register, formState: { errors }, reset, control, setError, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    if (!user) return;

    reset(user);
  }, [user]);

  const onSubmit = (data) => {
    setLoading(true);
    const parsedPhoneNumber = parsePhoneNumber(`${data.phone_number}`);
    const newData = { ...data, phone_number: parsedPhoneNumber?.number, phone_country: parsedPhoneNumber?.country };
    dispatch(updateUser(newData)).then(() => {
      dispatch(getUser());
      toggleModal(true);
      setLoading(false);
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
      setLoading(false);
    });
  };
  return (
    <>
      <Row className="mt-4">
        <Col md={8}>
          <div className="form-card">
            <div className="header bg-light p-3">
              <h5 className="text-gray mb-0">البيانات الشخصية</h5>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-3">
                <Row>
                  <Col md={6}>
                    <InputField
                      label="الاسم الكامل"
                      name="name"
                      type="text"
                      errors={errors}
                      {...register('name')}
                    />
                  </Col>
                  <Col md={6}>
                    <InputField
                      label="البريد الإلكتروني"
                      name="email"
                      type="email"
                      errors={errors}
                      {...register('email')}
                    />
                  </Col>
                  <Col md={6}>
                    <PhoneField
                      label="رقم الهاتف"
                      type="phone_number"
                      errors={errors}
                      control={control}
                      {...register('phone_number')}
                    />
                  </Col>
                </Row>
              </div>
              <div className="footer p-3 bg-light d-flex justify-content-end">
                <button type="submit" className="btn btn-sm btn-blue" id="update_profile" disabled={loading}>
                  {
                    loading ? <Loader type="ball-pulse" style={{ transform: 'scale(0.2)' }} /> : 'تحديث البيانات'
                  }
                </button>
              </div>
            </Form>
          </div>
        </Col>
        {/* <Col md={4}>
      <div className="form-card">
        <div className="bg-light p-3">
          <h5 className="text-gray mb-0">تغيير كلمة المرور</h5>
        </div>
        <div className="p-3">
          <Form className="row">
            <Col md={12}>
              <InputField
                label="كلمة المرور الحالية"
                name="old_password"
                type="password"
                errors={errors}
                {...register('old_password')}
              />
            </Col>
            <Col md={12}>
              <InputField
                label="كلمة المرور الجديدة"
                name="new_password"
                type="password"
                errors={errors}
                {...register('new_password')}
              />
            </Col>
          </Form>
        </div>
        <div className="footer p-3 bg-light d-flex justify-content-end">
          <button type="submit" className="btn btn-sm btn-blue" id="change_password">تغيير</button>
        </div>
      </div>
    </Col> */}
      </Row>
      <SuccessAlert
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        title="تعديل البيانات الشحصية"
      />
    </>
  );
}

export default Profile;
