import React from 'react';
import {
  Label, Input, FormFeedback, InputGroup, InputGroupText, FormGroup,
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { getErrorsByPath } from '../../helpers';

const InputGroupField = React.forwardRef((props, ref) => {
  const errors = props.errors || [];

  return (
    <FormGroup className="form-group">
      <Label className="text-gray" for={props.name}>
        {props.label}
      </Label>
      <InputGroup>
        {props.rightInputText
        && (
          <InputGroupText dir="ltr">
            {props.rightInputText}
          </InputGroupText>
        )}
        <Input
          {...props}
          innerRef={ref}
          invalid={getErrorsByPath(errors, props.name) && true}
          autoComplete="off"
          dir="ltr"
        />
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={props.name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </FormGroup>
  );
});

export default InputGroupField;
