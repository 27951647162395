import clientOneThumbnail from '../../assets/images/thumbnails/client-one.svg';
import clientTwoThumbnail from '../../assets/images/thumbnails/client-two.svg';

const clients = [
  {
    id: '1bcc79a0-6c8a-4670-b08b-f6619ae51271',
    title: 'Clients',
    elements: [
      {
        id: '21df1e62-c04b-4871-a6a7-0d3df80cf9e4',
        thumbnail: clientOneThumbnail,
        data: {
          type: 'clients01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'clients',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center justify-content-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '99366548-b94a-499f-99b0-2aa8e1217de0',
        thumbnail: clientTwoThumbnail,
        data: {
          type: 'clients02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'clients',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center justify-content-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '50px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default clients;
