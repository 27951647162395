import Api from '../../services';

export const getProjects = (params) => {
  return (dispatch) => {
    return Api.projects.getProjects(params).then((response) => {
      dispatch({ type: 'GET_PROJECTS', data: response });
      return response;
    });
  };
};

export const createProject = (data) => {
  return (dispatch) => {
    return Api.projects.createProject(data).then((response) => {
      dispatch({ type: 'CREATE_PROJECT' });
      return response;
    });
  };
};

export const editProject = (projectId, data) => {
  return (dispatch) => {
    return Api.projects.editProject(projectId, data).then((response) => {
      dispatch({ type: 'EDIT_PROJECT' });
      return response;
    });
  };
};

export const deleteProject = (projectId) => {
  return (dispatch) => {
    return Api.projects.deleteProject(projectId).then((response) => {
      dispatch({ type: 'DELETE_PROJECT' });
      return response;
    });
  };
};

export const uploadMedia = (data) => {
  return (dispatch) => {
    return Api.projects.uploadMedia(data).then((response) => {
      dispatch({ type: 'UPLOAD_MEDIA' });
      return response;
    });
  };
};

export const publishProject = (projectId) => {
  return (dispatch) => {
    return Api.projects.publishProject(projectId).then((response) => {
      dispatch({ type: 'PUBLISH_PROJECT' });
      return response;
    });
  };
};

export const getProjectsTemplates = (params) => {
  return (dispatch) => {
    return Api.projects.getProjectsTemplates(params).then((response) => {
      dispatch({ type: 'GET_PROJECTS_TEMPLATES' });
      return response;
    });
  };
};
