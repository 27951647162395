import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { CardImage, Upload, XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import {
  ButtonDropdown,
  Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, Row, Spinner,
} from 'reactstrap';
import placeholder from '../../../../../assets/images/placeholder.svg';
import { removeElementById, updateNestedItems } from '../../../../../helpers';
import { uploadMedia } from '../../../../../redux/actions/projects.actions';
import { updateSection } from '../../../../../redux/actions/sections.actions';

function Image({
  config, projectId, pageId, parent, getPageDetails, previewComponent, updateProgressBar,
}) {
  const [isModalOpen, toggleModal] = useState(false);
  const [objectFit, setObjectFit] = useState(undefined);
  const [altText, setAltText] = useState(undefined);
  const [imgUrl, setImgUrl] = useState(undefined);
  const [imgId, setImgId] = useState(undefined);
  const [borderTopRightRadius, setBorderTopRightRadius] = useState(undefined);
  const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(undefined);
  const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(undefined);
  const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(undefined);
  const [height, setHeight] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const [heightUnit, setHeightUnit] = useState(undefined);
  const [widthUnit, setWidthUnit] = useState(undefined);
  const [isHeightDropdownOpen, toggleHeightDropdown] = useState(false);
  const [error, setError] = useState('');
  const [widthError, setWidthError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const onOpened = () => {
    setObjectFit((config.interface_data?.style && config.interface_data?.style?.['object-fit']) || '');
    setAltText(config?.interface_data?.alt || '');
    setImgUrl(config?.metadata?.url || '');
    setBorderTopRightRadius((config?.interface_data?.style && config?.interface_data?.style?.['border-top-right-radius']?.replace(/\D/g, '')) || '');
    setBorderTopLeftRadius((config?.interface_data?.style && config?.interface_data?.style?.['border-top-left-radius']?.replace(/\D/g, '')) || '');
    setBorderBottomRightRadius((config?.interface_data?.style && config?.interface_data?.style?.['border-bottom-right-radius']?.replace(/\D/g, '')) || '');
    setBorderBottomLeftRadius((config?.interface_data?.style && config?.interface_data?.style?.['border-bottom-left-radius']?.replace(/\D/g, '')) || '');
    setHeight(config?.interface_data?.style?.height?.replace(/\D/g, '') || '');
    setWidth(config?.interface_data?.style?.width?.replace(/\D/g, '') || '');
    setHeightUnit(config?.interface_data?.style?.height?.replace(/[0-9]/g, '') || '');
    setWidthUnit(config?.interface_data?.style?.width?.replace(/[0-9]/g, '') || '');
    setError('');
    setWidthError('');
  };

  const deleteImage = () => {
    const data = removeElementById(parent?.components, config?.id);
    const updatedParent = { ...parent, components: data };
    dispatch(updateSection(projectId, pageId, updatedParent?.id, updatedParent)).then(() => getPageDetails()).then(() => {
      toggleModal(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  const onChange = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('media', e.target.files[0]);
    formData.append('entity', 'project-media');
    formData.append('reference_id', projectId);
    formData.append('reference_type', 'project');
    dispatch(uploadMedia(formData)).then((response) => {
      setError('');
      setImgUrl(response.url);
      setImgId(response.uuid);
      setAltText(e.target.files[0]?.name?.split('.')[0]);
      setLoading(false);
    }).catch((e) => {
      const error = e?.response?.data?.message;
      setError(error);
      setLoading(false);
    });
  };

  const updateConfigValue = () => {
    updateProgressBar(50);
    if (imgId) {
      const updatedConfig = {
        ...config,
        metadata: {
          id: imgId,
        },
        interface_data: {
          ...config?.interface_data,
          alt: altText,
          style: {
            ...config?.interface_data?.style,
            'object-fit': objectFit,
            'border-top-right-radius': `${borderTopRightRadius}px`,
            'border-top-left-radius': `${borderTopLeftRadius}px`,
            'border-bottom-right-radius': `${borderBottomRightRadius}px`,
            'border-bottom-left-radius': `${borderBottomLeftRadius}px`,
            width: `${width}${widthUnit}`,
            height: `${height}${heightUnit}`,
          },
        },
      };
      updateNestedItems(parent?.components, config.id, updatedConfig);
      dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
        toggleModal(false);
        updateProgressBar(100);
      }).catch(() => updateProgressBar(100));
    } else {
      const updatedConfig = {
        ...config,
        interface_data: {
          ...config?.interface_data,
          alt: altText,
          style: {
            ...config?.interface_data?.style,
            'object-fit': objectFit,
            'border-top-right-radius': `${borderTopRightRadius}px`,
            'border-top-left-radius': `${borderTopLeftRadius}px`,
            'border-bottom-right-radius': `${borderBottomRightRadius}px`,
            'border-bottom-left-radius': `${borderBottomLeftRadius}px`,
            width: `${width}${widthUnit}`,
            height: `${height}${heightUnit}`,
          },
        },
      };
      updateNestedItems(parent?.components, config.id, updatedConfig);
      dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
        toggleModal(false);
        updateProgressBar(100);
      }).catch(() => updateProgressBar(100));
    }
  };

  const openModal = () => {
    if (config?.interface_data?.class === 'icon') return;
    toggleModal(!isModalOpen);
  };
  if (previewComponent) {
    return (
      <img
        style={{
          objectFit: config.interface_data?.style && config.interface_data?.style['object-fit'],
          borderTopRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-right-radius'],
          borderTopLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-left-radius'],
          borderBottomRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-right-radius'],
          borderBottomLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-left-radius'],
          height: config?.interface_data?.style?.height,
          width: config?.interface_data?.style?.width,
        }}
        src={config.metadata?.url || placeholder}
        className={config?.interface_data?.class}
        alt={config?.interface_data?.alt}
      />
    );
  }

  return (
    <>
      <div className="img-wrapper cursor-pointer">
        <img
          style={{
            objectFit: config.interface_data?.style && config.interface_data?.style['object-fit'],
            borderTopRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-right-radius'],
            borderTopLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-left-radius'],
            borderBottomRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-right-radius'],
            borderBottomLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-left-radius'],
            height: config?.interface_data?.style?.height,
            width: config?.interface_data?.style?.width,
          }}
          src={config.metadata?.url || placeholder}
          className={classNames('cursor-pointer', config?.interface_data?.class)}
          alt={config?.interface_data?.alt}
          onClick={openModal}
        />
      </div>
      <Modal onOpened={onOpened} className="img-modal" isOpen={isModalOpen} toggle={() => toggleModal(!isModalOpen)} size="xl">
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إعدادات الصورة</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody>
          <Row>
            <Col md={6}>
              <div className={classNames('img-wrapper cursor-pointer', error ? 'red-border' : '', loading ? 'loading' : '')} onClick={() => inputFile.current.click()}>
                <div className="overlay" />
                <img
                  style={{
                    objectFit,
                    borderTopRightRadius: `${borderTopRightRadius}px`,
                    borderTopLeftRadius: `${borderTopLeftRadius}px`,
                    borderBottomRightRadius: `${borderBottomRightRadius}px`,
                    borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
                    height: `${height}${heightUnit}`,
                    width: width > 100 ? `100${widthUnit}` : `${width}${widthUnit}`,
                  }}
                  src={imgUrl || placeholder}
                  className={config?.interface_data?.class}
                  alt={config?.interface_data?.alt}
                />
                {loading ? <Spinner size="lg" color="white" /> : <Upload />}
                <input type="file" ref={inputFile} hidden onChange={onChange} />
              </div>
              {error && <h6 className="text-red mt-3 mb-0">{error}</h6>}
            </Col>
            <Col md={6}>
              <Form className="img-form row">
                <FormGroup className="form-group col-md-12">
                  <Label className="text-gray mb-1">النص البديل</Label>
                  <Input
                    value={altText}
                    onChange={(e) => setAltText(e.target.value)}
                    type="text"
                  />
                </FormGroup>
                <FormGroup className="form-group col-md-12">
                  <Label className="text-gray mb-1">تعبئة الصورة</Label>
                  <div className="btns-container w-100">
                    <button
                      className={classNames('btn btn-blue-outline', objectFit === 'contain' ? 'active' : '')}
                      type="button"
                      onClick={() => setObjectFit('contain')}
                    >
                      <CardImage size={20} className="me-1" />
                      {' '}
                      الحجم الأصلي
                    </button>
                    <button
                      className={classNames('btn btn-blue-outline', objectFit === 'cover' ? 'active' : '')}
                      type="button"
                      onClick={() => setObjectFit('cover')}
                    >
                      <CardImage size={20} className="me-1" />
                      {' '}
                      تعبئة المساحة
                    </button>
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 col-md-6">
                  <Label className="text-gray mb-1">
                    الطول
                  </Label>
                  <InputGroup>
                    <Input value={height} onChange={(e) => setHeight(e.target.value)} type="number" />
                    <ButtonDropdown isOpen={isHeightDropdownOpen} toggle={() => toggleHeightDropdown(!isHeightDropdownOpen)}>
                      <DropdownToggle caret>
                        {heightUnit}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => setHeightUnit('px')}>
                          pixels
                        </DropdownItem>
                        <DropdownItem onClick={() => setHeightUnit('%')}>
                          percentage
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="form-group mb-3 col-md-6">
                  <Label className="text-gray mb-1">
                    العرض
                  </Label>
                  <InputGroup>
                    <Input
                      value={width}
                      onChange={(e) => {
                        setWidth(e.target.value);
                        if (e.target.value > 100) {
                          setWidthError('عرض الفيديو لا يمكن أن يتجاوز 100%');
                        } else {
                          setWidthError('');
                        }
                      }}
                      type="number"
                    />
                    <InputGroupText>{widthUnit}</InputGroupText>
                  </InputGroup>
                  {widthError && <h6 className="text-red mt-2 small mb-0">{widthError}</h6>}
                </FormGroup>
                <FormGroup className="form-group mb-3 col-md-6">
                  <Label className="text-gray mb-1">
                    تدوير الإطار (أعلى اليمين)
                  </Label>
                  <Input
                    value={borderTopRightRadius}
                    onChange={(e) => setBorderTopRightRadius(e.target.value)}
                    type="number"
                  />
                </FormGroup>
                <FormGroup className="form-group mb-3 col-md-6">
                  <Label className="text-gray mb-1">
                    تدوير الإطار (أعلى اليسار)
                  </Label>
                  <Input
                    value={borderTopLeftRadius}
                    onChange={(e) => setBorderTopLeftRadius(e.target.value)}
                    type="number"
                  />
                </FormGroup>
                <FormGroup className="form-group mb-3  col-md-6">
                  <Label className="text-gray mb-1">
                    تدوير الإطار (أسفل اليمين)
                  </Label>
                  <Input
                    value={borderBottomRightRadius}
                    onChange={(e) => setBorderBottomRightRadius(e.target.value)}
                    type="number"
                  />
                </FormGroup>
                <FormGroup className="form-group col-md-6">
                  <Label className="text-gray mb-1">
                    تدوير الإطار (أسفل اليسار)
                  </Label>
                  <Input
                    value={borderBottomLeftRadius}
                    onChange={(e) => setBorderBottomLeftRadius(e.target.value)}
                    type="number"
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <button type="button" className="btn btn-red me-2" onClick={deleteImage}>حذف الصورة</button>
          <div className="d-flex align-items-center">
            <button type="button" className="btn btn-navy me-2" onClick={updateConfigValue}>حفظ</button>
            <button type="button" className="btn btn-blue-outline" onClick={() => toggleModal(!isModalOpen)}>إلغاء</button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Image;
