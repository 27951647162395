import React, { useEffect, useState } from 'react';
import {
  Col, Form, Input, Label, Row, Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Check2Circle, Upload, X } from 'react-bootstrap-icons';
import schema from './schema';
import InputField from '../../../../../components/input';
import { editProject, uploadMedia } from '../../../../../redux/actions/projects.actions';
import InputGroupField from '../../../../../components/input-group';
import DeleteProject from './delete';
import Api from '../../../../../services';
import { setServerErrors } from '../../../../../helpers';
import iconPlaceholder from '../../../../../assets/images/icon-placeholder.png';
import chromeTab from '../../../../../assets/images/chrome-tab.svg';

function GeneralSettings() {
  const params = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = useState(false);
  const [faviconUrl, setFaviconUrl] = useState(undefined);
  const [iconTarget, setIconTarget] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState(undefined);
  const [archiving, setArchiving] = useState(false);

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(editProject(params.projectId, data)).then(() => {
      toast.custom(() => (
        <div className="bg-white shadow-lg rounded p-3">
          <div className="d-flex align-items-center">
            <Check2Circle className="text-success me-2" size={30} />
            <h6 className="text-gray mb-0">
              تم حفظ التعديلات بنجاح
            </h6>
          </div>
        </div>
      ), { position: 'bottom-center', duration: 7000 });
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  const getProject = () => {
    Api.projects.getProject(params.projectId).then((response) => {
      reset(response.data);
      setData(response.data);
      setFaviconUrl(response.data.favicon_url);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const uploadIcon = (e) => {
    const file = URL.createObjectURL(e.target.files[0]);
    setFaviconUrl(file);
    setIconTarget(e.target.files[0]);
  };

  const uploadFavicon = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('media', iconTarget);
    formData.append('entity', 'favicon');
    formData.append('reference_id', params.projectId);
    formData.append('reference_type', 'project');
    dispatch(uploadMedia(formData)).then(() => {
      setError('');
      setIconTarget(undefined);
      getProject();
      setLoading(false);
    }).catch((e) => {
      const error = e?.response?.data?.message;
      setError(error);
      setLoading(false);
    });
  };

  const archiveProject = () => {
    setArchiving(true);
    Api.projects.archiveProject(params.projectId).then(() => {
      getProject();
      setArchiving(false);
    }).catch(() => setArchiving(false));
  };

  const unarchiveProject = () => {
    setArchiving(true);
    Api.projects.unarchiveProject(params.projectId).then(() => {
      getProject();
      setArchiving(false);
    }).catch(() => setArchiving(false));
  };
  return (
    <>
      <Row className="mt-4">
        <Col md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-card">
              <div className="header bg-light p-3">
                <h6 className="text-gray mb-0">الإعدادات العامة</h6>
              </div>
              <div className="p-3">
                <InputField
                  label="اسم المشروع"
                  name="name"
                  type="text"
                  errors={errors}
                  {...register('name')}
                />
                <InputGroupField
                  label="رابط المشروع"
                  name="slug"
                  type="text"
                  errors={errors}
                  {...register('slug')}
                  rightInputText=".rawabit.me"
                />
              </div>
              <div className="footer p-3 bg-light d-flex align-items-center justify-content-between">
                <div>
                  <button type="button" className="btn btn-sm btn-danger" onClick={() => toggleModal(true)} id="delete_project">
                    حذف
                  </button>
                  {!data?.is_archived
                  && (
                    <button type="button" className="btn btn-warning btn-sm ms-2" disabled={archiving} onClick={archiveProject} id="archive_project">
                      أرشفة
                    </button>
                  )}
                  {data?.is_archived
                  && (
                    <button type="button" className="btn btn-success btn-sm ms-2" disabled={archiving} onClick={unarchiveProject} id="unarchive_project">
                      استعادة
                    </button>
                  )}
                </div>
                <button type="submit" className="btn btn-sm btn-blue" id="update_project">
                  تعديل
                </button>
              </div>
            </div>
          </Form>
        </Col>
        <Col md={4}>
          <div className="form-card">
            <div className="header bg-light p-3">
              <h6 className="text-gray mb-0">Favicon</h6>
            </div>
            <div className="p-3">
              <div className="uploader d-flex align-items-center justify-content-center">
                <Label className="mb-0 d-flex flex-column align-items-center justify-content-center h-100 w-100 cursor-pointer" htmlFor="faviconUploader">
                  {loading ? <Spinner className="text-blue mb-2" /> : <Upload size={25} className="text-blue mb-2" />}
                  <small className="text-muted fw-bold">يجب أن تكون الأيقونة مربعة الشكل</small>
                  <Input type="file" onChange={uploadIcon} hidden id="faviconUploader" accept="image/png" />
                </Label>
              </div>
              {error && <small className="text-red mt-1">يرجى التأكد من أبعاد الصورة</small>}
              <div className="chrome-tab d-flex justify-content-center mt-3">
                <div className="tab-content">
                  <img src={chromeTab} alt="tab" className="tab" />
                  <div className="content d-flex align-items-center" dir="ltr">
                    <img src={faviconUrl || iconPlaceholder} alt="icon" className="icon ms-2" />
                    <p className="text-gray mb-0">Page title</p>
                  </div>
                  <X className="close text-gray" size={25} />
                </div>
              </div>
            </div>
            <div className="footer p-3 bg-light d-flex align-items-center justify-content-end">
              <button type="submit" className="btn btn-sm btn-blue" onClick={uploadFavicon} disabled={!faviconUrl || loading} id="upload_favicon">
                رفع الأيقونة
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <DeleteProject isModalOpen={isModalOpen} toggleModal={toggleModal} projectId={params?.projectId} />
    </>
  );
}

export default GeneralSettings;
