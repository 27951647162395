import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Badge, Col, List, Row,
} from 'reactstrap';
import { DateTime } from 'luxon';
import emptyPlaceholder from '../../../assets/images/empty-placeholder.svg';
import Unsubscribe from './unsubscribe';

function SubscriptionDetails({ openSubscribeModal }) {
  const [isUnsubscribeModalOpen, toggleUnsubscribeModal] = useState(false);
  const user = useSelector((state) => state.user.data);

  return (
    <>
      <div className="form-card mb-4">
        <div className="bg-light d-flex justify-content-between align-items-center p-3">
          <h5 className="text-gray mb-0">بيانات الاشتراك</h5>
          {user?.active_subscription?.status === 'active' && user?.active_subscription?.next_billing_at
          && <button type="submit" className="btn btn-sm btn-red-outline" onClick={() => toggleUnsubscribeModal(true)} id="unsubscribe_settings">إلغاء الاشتراك</button>}
          {user?.active_subscription?.status !== 'active' && !user?.active_subscription?.next_billing_at
          && <button type="submit" className="btn btn-sm btn-blue-outline" onClick={openSubscribeModal} id="subscribe_settings">ترقية الاشتراك</button>}
        </div>
        <div className="p-3">
          <Row>
            {user?.active_subscription?.status !== 'active' && !user?.active_subscription?.next_billing_at
              ? (
                <Col md={12} className="d-flex flex-column align-items-center">
                  <img src={emptyPlaceholder} alt="empty" />
                  <h6 className="text-gray text-center mb-0 mt-3">لا يوجد لديك اشتراك ساري</h6>
                </Col>
              )
              : (
                <Col md={12}>
                  {user?.active_subscription?.status === 'active'
                  && <Badge color="success">اشتراك ساري</Badge>}
                  {user?.active_subscription?.status === 'past_due'
                  && <Badge color="danger">فشل التجديد</Badge>}
                  <List type="unstyled" className="mb-0 mt-3">
                    {user?.active_subscription?.name
                    && (
                      <li className="d-flex align-items-center mb-1">
                        <h6 className="text-gray mb-0 me-1">اسم الباقة:</h6>
                        {' '}
                        <p className="text-gray mb-0">{user?.active_subscription?.name}</p>
                      </li>
                    )}
                    {user?.active_subscription?.next_billing_at
                    && (
                      <li className="d-flex align-items-center">
                        <h6 className="text-gray mb-0 me-1">تاريخ الدفعة القادمة:</h6>
                        {' '}
                        <p className="text-gray mb-0" dir="ltr">{DateTime.fromISO(user?.active_subscription?.next_billing_at).toFormat('dd-MM-yyyy HH:mm')}</p>
                      </li>
                    )}
                    {user?.active_subscription?.finished_at
                    && (
                      <li className="d-flex align-items-center">
                        <h6 className="text-gray mb-0 me-1">تاريخ انتهاء الاشتراك:</h6>
                        {' '}
                        <p className="text-gray mb-0" dir="ltr">{DateTime.fromISO(user?.active_subscription?.finished_at).toFormat('dd-MM-yyyy HH:mm')}</p>
                      </li>
                    )}
                  </List>
                </Col>
              )}
          </Row>
        </div>
      </div>
      <Unsubscribe isModalOpen={isUnsubscribeModalOpen} toggleModal={toggleUnsubscribeModal} />
    </>
  );
}

export default SubscriptionDetails;
