/* eslint-disable max-len */
import videoOneThumbnail from '../../assets/images/thumbnails/video-one.svg';
import videoTwoThumbnail from '../../assets/images/thumbnails/video-two.svg';

const videos = [
  {
    id: '39d238b8-a9db-4978-a55c-b74cd43e43ec',
    title: 'Videos',
    elements: [
      {
        id: '3217b4aa-90cd-43ac-956b-92a936724ab5',
        thumbnail: videoOneThumbnail,
        data: {
          type: 'video01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'video-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'iframe',
                              metadata: {
                                src: 'https://www.youtube.com/embed/2RhbAGq5U40',
                                height: '550px',
                                width: '100%',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '5a3830a4-ae62-4100-afdb-c300ada23aa0',
        thumbnail: videoTwoThumbnail,
        data: {
          type: 'video02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'video-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'iframe',
                              metadata: {
                                src: 'https://www.youtube.com/embed/2RhbAGq5U40',
                                height: '550px',
                                width: '100%',
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'row',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'col-md-12',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    level: 2,
                                    text: 'العنوان الرئيسي',
                                  },
                                  interface_data: {
                                    class: 'fw-bold mt-4',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default videos;
