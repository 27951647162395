/* eslint-disable max-lines */
/* eslint-disable max-len */
import contentOneThumbnail from '../../assets/images/thumbnails/content-one.svg';
import contentTwoThumbnail from '../../assets/images/thumbnails/content-two.svg';
import contentThreeThumbnail from '../../assets/images/thumbnails/content-three.svg';

const content = [
  {
    id: '56aa2ee7-9a60-45c0-bef9-98a6eba5b2a8',
    title: 'Content',
    elements: [
      {
        id: '54f738dd-4b23-4bee-a323-a5c8ecb6c8c4',
        thumbnail: contentOneThumbnail,
        data: {
          type: 'content01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'content-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '500px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '270px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '270px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: 'div',
                              interface_data: {
                                class: 'row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-12',
                                  },
                                  items: [
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'العنوان الرئيسي',
                                        level: 2,
                                      },
                                      interface_data: {
                                        class: 'fw-bold mt-5 mb-3',
                                        style: {
                                          'text-align': 'right',
                                        },
                                      },
                                    },
                                    {
                                      type: 'paragraph',
                                      metadata: {
                                        text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                      },
                                      interface_data: {
                                        class: 'mb-0',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '061f2332-7b5a-47e2-8ff3-947ed56aa80d',
        thumbnail: contentTwoThumbnail,
        data: {
          type: 'content02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'content-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'd-flex align-items-center row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6 mb-3',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '200px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6 mb-3',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '200px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '200px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-6',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '200px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'fw-bold',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'mb-5',
                              },
                            },
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'زر رئيسي',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'a434d15c-3611-443b-ac9a-361428863071',
        thumbnail: contentThreeThumbnail,
        data: {
          type: 'content03',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'content-three',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row d-flex justify-content-center',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-8',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'fw-bold',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير منظم، غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.',
                              },
                            },
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '500px',
                                  width: '100%',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                              },
                              interface_data: {
                                class: 'mt-3 mb-0',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default content;
