import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Col, List, ListInlineItem, Row,
} from 'reactstrap';
import classNames from 'classnames';
import desktop from '../../../assets/images/desktop.svg';
import desktopActive from '../../../assets/images/desktop-active.svg';
import mobile from '../../../assets/images/mobile.svg';
import mobileActive from '../../../assets/images/mobile-active.svg';

function PreviewNavbar({ mode, updatePreviewMode }) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="fixed-top gray-navbar">
      <Row>
        <Col md={12} className="d-flex align-items-center justify-content-between">
          <button id="navigate_builder_preview" type="button" className="btn btn-blue d-flex align-items-center" onClick={() => navigate(`/builder/${params.projectId}/pages/${params?.pageId}`)}>
            <ArrowRight size={20} className="me-2" />
            <span className="mt-1">العودة إلى التصميم</span>
          </button>
          <List className="preview-icons d-flex align-items-center justify-content-around m-0 p-0">
            <ListInlineItem
              onClick={() => updatePreviewMode('mobile')}
              className={classNames(mode === 'mobile' ? 'active' : '')}
            >
              <img
                src={mode === 'mobile' ? mobileActive : mobile}
                alt="mobile"
              />
            </ListInlineItem>
            <ListInlineItem
              onClick={() => updatePreviewMode('desktop')}
              className={classNames(mode === 'desktop' ? 'active' : '')}
            >
              <img
                src={mode === 'desktop' ? desktopActive : desktop}
                alt="desktop"
              />
            </ListInlineItem>
          </List>
        </Col>
      </Row>
    </div>
  );
}

export default PreviewNavbar;
