import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { createProject, getProjects } from '../../redux/actions/projects.actions';
import { guestUser } from '../../redux/actions/auth.actions';

export default function Guest() {
  const token = localStorage.getItem('api_token');
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params?.templateId) return;

    if (token) {
      const data = { template_id: params?.templateId };
      dispatch(createProject(data)).then((response) => {
        dispatch(getProjects()).then((projects) => {
          const newProject = projects?.data?.find((project) => project?.id === response?.data?.id);
          navigate(`/builder/${newProject?.id}/pages/${newProject?.homepage?.id}`);
        });
      });
    } else {
      const data = { template_id: params?.templateId };
      dispatch(guestUser()).then(() => {
        dispatch(createProject(data)).then((response) => {
          dispatch(getProjects()).then((projects) => {
            const newProject = projects?.data?.find((project) => project?.id === response?.data?.id);
            navigate(`/builder/${newProject?.id}/pages/${newProject?.homepage?.id}`);
          });
        });
      });
    }
  }, [params?.templateId]);
  return (
    <LoadingOverlay
      active
      spinner
      text="جاري إعداد المشروع"
      className="h-100"
    />
  );
}
