import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Gear } from 'react-bootstrap-icons';
import ReactTooltip from 'react-tooltip';
import file from '../../../assets/images/file.svg';

function ProjectCard({ data }) {
  const navigate = useNavigate();
  return (
    <div className="project-card">
      <div className="card-content" onClick={() => navigate(`/builder/${data.id}/pages/${data?.homepage?.id}`)} id="view_project">
        <img src={file} alt="file" height={70} />
        {data?.is_template
          && (
            <Badge color="info" className="template">
              قالب روابط
            </Badge>
          )}
        {data?.published_at && !data?.is_archived
          && (
            <Badge color="success" className="published">
              تم النشر
            </Badge>
          )}
        {data?.is_archived
          && (
            <Badge color="warning" className="published">
              مؤرشف
            </Badge>
          )}
      </div>
      <div className="card-text">
        <h6 className="mb-0 text-gray" onClick={() => navigate(`/builder/${data.id}/pages/${data?.homepage?.id}`)}>{data.name}</h6>
        <Gear
          size={20}
          className="text-gray cursor-pointer"
          data-tip="إعدادات المشروع"
          data-for="settings"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/builder/${data.id}/settings`);
          }}
        />
        <ReactTooltip place="top" id="settings" effect="solid" className="px-2 py-1" />
      </div>
    </div>
  );
}

export default ProjectCard;
