import React from 'react';
import { Check2Circle } from 'react-bootstrap-icons';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

function SuccessAlert({ title, isModalOpen, toggleModal }) {
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="text-gray" toggle={() => toggleModal()}>
        {title}
      </ModalHeader>
      <ModalBody className="pb-0 text-center">
        <Check2Circle className="text-blue" size={55} />
        <h5 className="text-gray text-center my-3">تم حفظ التعديلات بنجاح</h5>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()}>
          إغلاق
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default SuccessAlert;
