import React from 'react';
import {
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { getErrorsByPath } from '../../helpers';

const InputField = React.forwardRef((props, ref) => {
  const errors = props.errors || [];

  return (
    <FormGroup className="form-group">
      {props.label
      && (
        <Label className="text-gray" for={props.name}>
          {props.label}
        </Label>
      )}
      <Input
        {...props}
        innerRef={ref}
        invalid={getErrorsByPath(errors, props.name) && true}
        autoComplete="off"
      />
      <ErrorMessage
        errors={errors}
        name={props.name}
        render={({ message }) => <FormFeedback>{message}</FormFeedback>}
      />
    </FormGroup>
  );
});

export default InputField;
