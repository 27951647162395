/* eslint-disable max-len */
import heroOneThumbnail from '../../assets/images/thumbnails/hero-one.svg';
import heroTwoThumbnail from '../../assets/images/thumbnails/hero-two.svg';

const hero = [
  {
    id: '96ae5afe-1ad0-4608-9e41-b968c40b7164',
    title: 'Hero',
    elements: [
      {
        id: 'ab7be301-1b47-43d5-8808-2b4ac408b2e0',
        thumbnail: heroOneThumbnail,
        data: {
          type: 'hero01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'hero-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '550px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                level: 2,
                                text: 'العنوان الرئيسي',
                              },
                              interface_data: {
                                class: 'fw-bold',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'mb-5',
                              },
                            },
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'زر رئيسي',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '0a81e74e-f52a-435a-bfed-8b2baba6817c',
        thumbnail: heroTwoThumbnail,
        data: {
          type: 'hero02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'hero-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'fw-bold',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'mb-5',
                              },
                            },
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'زر رئيسي',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '550px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default hero;
