import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row,
} from 'reactstrap';
import {
  TextCenter, TextLeft, TextRight, XLg,
} from 'react-bootstrap-icons';
import { updateNestedItems } from '../../../../../helpers';
import { updateSection } from '../../../../../redux/actions/sections.actions';

function Heading({
  config, projectId, pageId, parent, getPageDetails, previewComponent, updateProgressBar,
}) {
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = useState(false);
  const [textValue, setTextValue] = useState(config.metadata?.text);
  const [level, setLevel] = useState(config.metadata?.level);
  const [alignment, setAlignment] = useState(config.interface_data?.style && config.interface_data?.style['text-align']);

  const updateConfigValue = () => {
    updateProgressBar(50);
    const updatedConfig = {
      ...config,
      metadata: { text: textValue, level },
      interface_data: {
        ...config?.interface_data,
        style: {
          ...config?.interface_data?.style,
          'text-align': alignment,
        },
      },
    };
    updateNestedItems(parent?.components, config.id, updatedConfig);
    dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
      toggleModal(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  const renderHeading = (level) => {
    switch (level) {
    case '1':
      return (
        <h1
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={classnames('heading', config?.interface_data?.class)}
          onClick={() => toggleModal(!isModalOpen)}
        >
          {config.metadata?.text}
        </h1>
      );

    case '2':
      return (
        <h3
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={classnames('heading', config?.interface_data?.class)}
          onClick={() => toggleModal(!isModalOpen)}
        >
          {config.metadata?.text}
        </h3>
      );

    case '3':
      return (
        <h3
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={classnames('heading', config?.interface_data?.class)}
          onClick={() => toggleModal(!isModalOpen)}
        >
          {config.metadata?.text}
        </h3>
      );

    case '4':
      return (
        <h4
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={classnames('heading', config?.interface_data?.class)}
          onClick={() => toggleModal(!isModalOpen)}
        >
          {config.metadata?.text}
        </h4>
      );

    case '5':
      return (
        <h5
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={classnames('heading', config?.interface_data?.class)}
          onClick={() => toggleModal(!isModalOpen)}
        >
          {config.metadata?.text}
        </h5>
      );

    case '6':
      return (
        <h6
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={classnames('heading', config?.interface_data?.class)}
          onClick={() => toggleModal(!isModalOpen)}
        >
          {config.metadata?.text}
        </h6>
      );

    default:
      return null;
    }
  };

  const renderPreviewHeading = (level) => {
    switch (level) {
    case '1':
      return (
        <h1
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={config?.interface_data?.class}
        >
          {config.metadata?.text}
        </h1>
      );

    case '2':
      return (
        <h3
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={config?.interface_data?.class}
        >
          {config.metadata?.text}
        </h3>
      );

    case '3':
      return (
        <h3
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={config?.interface_data?.class}
        >
          {config.metadata?.text}
        </h3>
      );

    case '4':
      return (
        <h4
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={config?.interface_data?.class}
        >
          {config.metadata?.text}
        </h4>
      );

    case '5':
      return (
        <h5
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={config?.interface_data?.class}
        >
          {config.metadata?.text}
        </h5>
      );

    case '6':
      return (
        <h6
          style={{
            textAlign: config?.interface_data?.style && config?.interface_data?.style['text-align'],
          }}
          className={config?.interface_data?.class}
        >
          {config.metadata?.text}
        </h6>
      );

    default:
      return null;
    }
  };

  if (previewComponent) {
    return renderPreviewHeading(config?.metadata?.level);
  }

  return (
    <>
      {renderHeading(config?.metadata?.level)}
      <Modal toggle={() => toggleModal(!isModalOpen)} isOpen={isModalOpen} size="lg">
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إعدادات العنوان</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody>
          <Form className="heading-form">
            <Row>
              <FormGroup className="col-12 form-group">
                <Label className="text-gray mb-1">
                  تعديل النص
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  rows={3}
                  value={textValue || ''}
                  onChange={(e) => setTextValue(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-group col-md-6">
                <Label className="text-gray mb-1">
                  الحجم
                </Label>
                <Input
                  value={level || ''}
                  name="level"
                  onChange={(e) => setLevel(e.target.value)}
                  type="select"
                  defaultValue=""
                >
                  <option value="" disabled>يرجى الاختيار</option>
                  <option value={1}>Heading 1</option>
                  <option value={2}>Heading 2</option>
                  <option value={3}>Heading 3</option>
                  <option value={4}>Heading 4</option>
                  <option value={5}>Heading 5</option>
                  <option value={6}>Heading 6</option>
                </Input>
              </FormGroup>
              <FormGroup className="form-group col-md-6">
                <Label className="text-gray mb-1">
                  محاذاة النص
                </Label>
                <div className="btns-container w-100">
                  <button
                    className={classnames('btn btn-blue-outline', alignment === 'right' ? 'active' : '')}
                    type="button"
                    onClick={() => setAlignment('right')}
                  >
                    <TextRight />
                    {' '}
                    يمين
                  </button>
                  <button
                    className={classnames('btn btn-blue-outline', alignment === 'center' ? 'active' : '')}
                    type="button"
                    onClick={() => setAlignment('center')}
                  >
                    <TextCenter />
                    {' '}
                    وسط
                  </button>
                  <button
                    className={classnames('btn btn-blue-outline', alignment === 'left' ? 'active' : '')}
                    type="button"
                    onClick={() => setAlignment('left')}
                  >
                    <TextLeft />
                    {' '}
                    يسار
                  </button>
                </div>
              </FormGroup>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-end">
          <button type="button" className="btn btn-navy me-2" onClick={updateConfigValue}>حفظ</button>
          <button type="button" className="btn btn-blue-outline" onClick={() => toggleModal(!isModalOpen)}>إلغاء</button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Heading;
