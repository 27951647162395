import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const token = localStorage.getItem('api_token');
  if (token) {
    return true;
  }
  return false;
};

function ProtectedRoutes() {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/auth/login" />;
}

export default ProtectedRoutes;
