import dividerOneThumbnail from '../../assets/images/thumbnails/divider-one.svg';
import dividerTwoThumbnail from '../../assets/images/thumbnails/divider-two.svg';

const dividers = [
  {
    id: 'b5100c86-dd17-4fd9-9b53-632866c80963',
    title: 'Dividers',
    elements: [
      {
        id: '07b2f063-e1f8-40dd-9c07-4205f52d682e',
        thumbnail: dividerOneThumbnail,
        data: {
          type: 'divider01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'divider-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'my-2',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

              ],
            },
          ],
        },
      },
      {
        id: '1a7c796a-80dc-4751-94ae-17370b8dd040',
        thumbnail: dividerTwoThumbnail,
        data: {
          type: 'divider02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'divider-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'py-4',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default dividers;
