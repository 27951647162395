import React, { useEffect, useState } from 'react';
import { Columns } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import ColorInputField from '../../../../components/color-input';
import { uploadMedia } from '../../../../redux/actions/projects.actions';
import { updateSection } from '../../../../redux/actions/sections.actions';

function SectionSettings({
  section, pageId, projectId, getPageDetails, updateSelectedSection, selectedSection,
}) {
  const [backgroundColor, setBackgroundColor] = useState(undefined);
  const [textColor, setTextColor] = useState(undefined);
  const [borderTopRightRadius, setBorderTopRightRadius] = useState(undefined);
  const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(undefined);
  const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(undefined);
  const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(undefined);
  const dispatch = useDispatch();

  const handleWidthChange = (e) => {
    const updatedItems = section?.components[0]?.items?.map((item, index) => {
      if (index === 0) {
        const updatedItem = {
          ...item,
          interface_data: {
            ...item.interface_data,
            containerClass: e.target.checked ? 'container-fluid' : 'container',
          },
        };
        return updatedItem;
      }
      return item;
    });

    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          items: updatedItems,
        };
        return updatedComponent;
      }
      return component;
    });

    const updatedSection = { ...section, components: updatedComponents };
    dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
      updateSelectedSection(updatedSection);
      getPageDetails();
    });
  };

  const handleBackgroundColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setBackgroundColor(color);
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              'background-color': color,
              'background-image': undefined,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
      updateSelectedSection(updatedSection);
      getPageDetails();
    });
  };

  const handleTextColorChange = (event) => {
    let color = '';
    if (event?.target?.value) {
      color = event?.target?.value;
    } else {
      color = event;
    }
    setTextColor(color);
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              color,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
      updateSelectedSection(updatedSection);
      getPageDetails();
    });
  };

  const handleBackgroundImageChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('media', e.target.files[0]);
    formData.append('entity', 'project-media');
    formData.append('reference_id', projectId);
    formData.append('reference_type', 'project');
    dispatch(uploadMedia(formData)).then((response) => {
      const updatedComponents = section?.components?.map((component, index) => {
        if (index === 0) {
          const updatedComponent = {
            ...component,
            interface_data: {
              ...component?.interface_data,
              style: {
                ...component?.interface_data?.style,
                'background-image': `url(${response?.url})`,
                'background-color': undefined,
              },
            },
          };
          return updatedComponent;
        }
        return component;
      });
      const updatedSection = { ...section, components: updatedComponents };
      dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
        updateSelectedSection(updatedSection);
        getPageDetails();
        e.target.value = null;
      });
    });
  };

  const handleBorderTopRightRadiusChange = (e) => {
    setBorderTopRightRadius(e.target.value);
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              'border-top-right-radius': `${e.target.value}px`,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    setTimeout(() => {
      dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
        updateSelectedSection(updatedSection);
        getPageDetails();
      });
    }, 800);
  };

  const handleBorderTopLeftRadiusChange = (e) => {
    setBorderTopLeftRadius(e.target.value);
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              'border-top-left-radius': `${e.target.value}px`,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    setTimeout(() => {
      dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
        updateSelectedSection(updatedSection);
        getPageDetails();
      });
    }, 800);
  };

  const handleBorderBottomRightRadiusChange = (e) => {
    setBorderBottomRightRadius(e.target.value);
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              'border-bottom-right-radius': `${e.target.value}px`,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    setTimeout(() => {
      dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
        updateSelectedSection(updatedSection);
        getPageDetails();
      });
    }, 800);
  };

  const handleBorderBottomLeftRadiusChange = (e) => {
    setBorderBottomLeftRadius(e.target.value);
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              'border-bottom-left-radius': `${e.target.value}px`,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    setTimeout(() => {
      dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
        updateSelectedSection(updatedSection);
        getPageDetails();
      });
    }, 800);
  };

  const resetBackgroundImage = () => {
    const updatedComponents = section?.components?.map((component, index) => {
      if (index === 0) {
        const updatedComponent = {
          ...component,
          interface_data: {
            ...component?.interface_data,
            style: {
              ...component?.interface_data?.style,
              'background-image': undefined,
              'background-color': undefined,
            },
          },
        };
        return updatedComponent;
      }
      return component;
    });
    const updatedSection = { ...section, components: updatedComponents };
    dispatch(updateSection(projectId, pageId, section?.id, updatedSection)).then(() => {
      updateSelectedSection(updatedSection);
      getPageDetails();
    });
  };

  useEffect(() => {
    if (!selectedSection) return;

    setBackgroundColor(section?.components[0]?.interface_data?.style && section?.components[0]?.interface_data?.style['background-color']);
    setTextColor(section?.components[0]?.interface_data?.style?.color);
    setBorderTopRightRadius(section?.components[0]?.interface_data?.style && section?.components[0]?.interface_data?.style?.['border-top-right-radius']?.replace(/\D/g, ''));
    setBorderTopLeftRadius(section?.components[0]?.interface_data?.style && section?.components[0]?.interface_data?.style?.['border-top-left-radius']?.replace(/\D/g, ''));
    setBorderBottomRightRadius(section?.components[0]?.interface_data?.style && section?.components[0]?.interface_data?.style?.['border-bottom-right-radius']?.replace(/\D/g, ''));
    setBorderBottomLeftRadius(section?.components[0]?.interface_data?.style && section?.components[0]?.interface_data?.style?.['border-bottom-left-radius']?.replace(/\D/g, ''));
  }, [selectedSection]);

  if (!selectedSection) {
    return (
      <div className="px-4 py-5 d-flex flex-column align-items-center justify-content-center">
        <Columns size={32} className="text-gray mb-2" />
        <h6 className="text-gray text-center">يرجى اختيار قسم حتى تتمكن من تحديث الإعدادات</h6>
      </div>
    );
  }
  return (
    <>
      <div className="mb-2 form-switch form-check">
        <Input type="checkbox" checked={section?.components[0]?.items[0]?.interface_data?.containerClass === 'container-fluid'} onChange={(e) => handleWidthChange(e)} />
        <Label className="text-gray" switch>
          عرض في كل الصفحة
        </Label>
      </div>
      <FormGroup className="mb-3">
        <Label className="text-gray">لون النص</Label>
        <ColorInputField
          value={textColor || ''}
          onChange={handleTextColorChange}
          style={{ backgroundColor: textColor }}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="text-gray">لون الخلفية</Label>
        <ColorInputField
          value={backgroundColor || ''}
          onChange={handleBackgroundColorChange}
          style={{ backgroundColor }}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Label className="text-gray">صورة الخلفية</Label>
        <Input type="file" onChange={handleBackgroundImageChange} />
        <button type="button" className="btn btn-blue w-100 mt-3" onClick={resetBackgroundImage} id="reset_background">إعادة تعيين الخلفية</button>
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray mb-1">
          تدوير الإطار (أعلى اليمين)
        </Label>
        <Input
          value={borderTopRightRadius}
          onChange={handleBorderTopRightRadiusChange}
          type="number"
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray mb-1">
          تدوير الإطار (أعلى اليسار)
        </Label>
        <Input
          value={borderTopLeftRadius}
          onChange={handleBorderTopLeftRadiusChange}
          type="number"
        />
      </FormGroup>
      <FormGroup className="form-group mb-3">
        <Label className="text-gray mb-1">
          تدوير الإطار (أسفل اليمين)
        </Label>
        <Input
          value={borderBottomRightRadius}
          onChange={handleBorderBottomRightRadiusChange}
          type="number"
        />
      </FormGroup>
      <FormGroup className="form-group">
        <Label className="text-gray mb-1">
          تدوير الإطار (أسفل اليسار)
        </Label>
        <Input
          value={borderBottomLeftRadius}
          onChange={handleBorderBottomLeftRadiusChange}
          type="number"
        />
      </FormGroup>
    </>
  );
}
export default SectionSettings;
