/* eslint-disable max-len */
import biographyOneThumbnail from '../../assets/images/thumbnails/biography-one.svg';
import biographyTwoThumbnail from '../../assets/images/thumbnails/biography-two.svg';

const biography = [
  {
    id: 'abe1d931-8b55-4328-9bec-1f168f7016f3',
    title: 'Biography',
    elements: [
      {
        id: '7de4137a-e83d-41ab-9b3d-98632e73c5a2',
        thumbnail: biographyOneThumbnail,
        data: {
          type: 'biography01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'biography-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '300px',
                                  'border-top-left-radius': '300px',
                                  'border-bottom-right-radius': '300px',
                                  'border-bottom-left-radius': '300px',
                                  height: '300px',
                                  width: '28%',
                                },
                              },
                            },
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'fw-bold mb-0 mt-3',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'المسمى الوظيفي',
                              },
                              interface_data: {
                                class: 'text-center',
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'text-center w-75 mx-auto mb-0',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'ccea6ccc-1fc0-42e3-aa21-5c102a169ace',
        thumbnail: biographyTwoThumbnail,
        data: {
          type: 'biography02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'biography-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '450px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6 d-flex flex-column justify-content-center',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'fw-bold mb-0 mt-3',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'المسمى الوظيفي',
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                            },
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 p-0',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'زر رئيسي',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary mt-5',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default biography;
