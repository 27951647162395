import Api from '../../services';

export const createSection = (projectId, pageId, data) => {
  return (dispatch) => {
    return Api.sections.createSection(projectId, pageId, data).then((response) => {
      dispatch({ type: 'CREATE_SECTION' });
      return response;
    });
  };
};

export const deleteSection = (projectId, pageId, sectionId) => {
  return (dispatch) => {
    return Api.sections.deleteSection(projectId, pageId, sectionId).then((response) => {
      dispatch({ type: 'DELETE_SECTION' });
      return response;
    });
  };
};

export const updateSection = (projectId, pageId, sectionId, data) => {
  return (dispatch) => {
    return Api.sections.updateSection(projectId, pageId, sectionId, data).then((response) => {
      dispatch({ type: 'UPDATE_SECTION' });
      return response;
    });
  };
};
