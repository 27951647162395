import React, { useState } from 'react';
import {
  Check2, Check2Circle, Clipboard, Eye, XLg,
} from 'react-bootstrap-icons';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../services';

function PublishProject({
  url, isModalOpen, toggleModal, projectId,
}) {
  const [isCopied, setCopied] = useState(false);
  const [isDomainCopied, setCopiedDomain] = useState(false);
  const [domains, setDomains] = useState([]);
  const navigate = useNavigate();

  const onOpened = () => {
    setCopied(false);
    setCopiedDomain(false);
    Api.projects.getProjectDomains(projectId).then((response) => setDomains(response.data));
  };
  return (
    <Modal onOpened={onOpened} centered isOpen={isModalOpen} toggle={() => toggleModal()} size="lg">
      <ModalHeader className="border-0 pb-0">
        <XLg className="text-gray cursor-pointer" onClick={() => toggleModal()} />
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center flex-column pb-5 pt-3">
        <Check2Circle className="text-blue display-3 mb-3" />
        <h5 className="text-gray text-center mb-1">تم نشر المشروع بنجاح</h5>
        <p className="text-gray text-center mb-0">يمكنك استعراض المشروع من خلال الرابط</p>
        <div className="dashed-border d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center">
            <CopyToClipboard
              text={url}
              onCopy={() => setCopied(true)}
            >
              {isCopied ? <Check2 className="text-blue" size={22} />
                : <Clipboard className="text-blue cursor-pointer" size={20} data-tip="نسخ الرابط" data-for="copy" />}
            </CopyToClipboard>
            <Eye
              className="text-blue cursor-pointer mx-2"
              size={20}
              data-tip="فتح الرابط"
              data-for="preview"
              onClick={() => window.open(url, '_blank')}
            />
          </div>
          <h6 className="text-blue text-center mt-1 mb-0 text-underlined cursor-pointer" onClick={() => window.open(url, '_blank')}><u>{url}</u></h6>
        </div>
        {domains?.filter((domain) => domain.status === 'verified')?.map((d) => {
          return (
            <div className="dashed-border d-flex align-items-center justify-content-between mt-3">
              <div className="d-flex align-items-center">
                <CopyToClipboard
                  text={d?.domain}
                  onCopy={() => setCopiedDomain(true)}
                >
                  {isDomainCopied ? <Check2 className="text-blue" size={22} />
                    : <Clipboard className="text-blue cursor-pointer" size={20} data-tip="نسخ الرابط" data-for="copyDomain" />}
                </CopyToClipboard>
                <Eye
                  className="text-blue cursor-pointer mx-2"
                  size={20}
                  data-tip="فتح الرابط"
                  data-for="previewDomain"
                  onClick={() => window.open(`https://${d?.domain}`, '_blank')}
                />
              </div>
              <h6 className="text-blue text-center mt-1 mb-0 text-underlined cursor-pointer" onClick={() => window.open(`https://${d?.domain}`, '_blank')}><u>{d?.domain}</u></h6>
            </div>
          );
        })}
        <h6 className="text-gray text-center mb-0 mt-4">
          يمكنك تعديل الرابط أو ربط نطاقك الخاص من خلال إعدادات المشروع، اضغط
          {' '}
          <span className="cursor-pointer text-blue" onClick={() => navigate(`/builder/${projectId}/settings`)} id="send_verification_email">هنا</span>
          {' '}
          للتعديل
        </h6>
        <ReactTooltip id="copy" effect="solid" className="px-2 py-1" />
        <ReactTooltip id="preview" effect="solid" className="px-2 py-1" />
        <ReactTooltip id="copyDomain" effect="solid" className="px-2 py-1" />
        <ReactTooltip id="previewDomain" effect="solid" className="px-2 py-1" />
      </ModalBody>
    </Modal>
  );
}

export default PublishProject;
