import * as helpers from './helpers';
import AuthApiEndpoints from './auth.api';
import ProjectsApiEndpoints from './projects.api';
import PagesApiEndpoints from './pages.api';
import UserApiEndpoints from './user.api';
import SectionsApiEndpoints from './sections.api';
import PasswordlessApiEndpoints from './passwordless.api';
import PaymentsApiEndpoints from './payments.api';
import PlansApiEndpoints from './plans.api';

const Api = {
  ...helpers,
  auth: AuthApiEndpoints,
  projects: ProjectsApiEndpoints,
  pages: PagesApiEndpoints,
  user: UserApiEndpoints,
  sections: SectionsApiEndpoints,
  passwordless: PasswordlessApiEndpoints,
  payments: PaymentsApiEndpoints,
  plans: PlansApiEndpoints,
};

export default Api;
