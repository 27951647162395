/* eslint-disable */

export const getErrorsByPath = (errors, name) => {
  const parts = name.split('.');
  let result = errors;
  for (const part of parts) {
    if (!result) return;
    result = result[part];
  }
  return result;
};

export const setServerErrors = (responseErrors, setError) => {
  for (const key of Object.keys(responseErrors)) {
    const message = responseErrors[key][0];
    setError(key, { message, type: 'manual' });
  }
}

export const updateNestedItems = (components, id, updatedObject, inAncestor = false) => {
  return components?.map((component) => {
    const updateObject = inAncestor || component.id === id;
    const updated = !updateObject ? component : { ...component, ...updatedObject };
    if (updated.items) {      
      updated.items = updateNestedItems(updated.items, id, updatedObject, updateObject);
    }
    return updated;
  });
};

export const updateNestedListItems = (components, id, updatedObject, inAncestor = false) => {
  return components?.map((component) => {
    const updateObject = inAncestor || component.id === id;
    const updated = (!updateObject || component?.type === 'image') ? component : { ...component, ...updatedObject };
    if (updated.items) {   
      updated.items = updateNestedListItems(updated.items, id, updatedObject, updateObject);
    }
    return updated;
  });
};

export const removeElementById = (data, idToRemove) => {
  const filtered = data.filter(entry => entry.id !== idToRemove);
  return filtered.map(entry => {
    if(!entry.items) return entry;
    return {...entry, items: removeElementById(entry.items, idToRemove)};
  });
}
export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};