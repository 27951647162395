import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Collapse } from 'reactstrap';
import sections from '../../../../data';

function Sections({ windowHeight, collapsedSection, updateCollapsedSection }) {
  return (
    <Droppable droppableId="sections" isDropDisabled>
      {(provided, snapshot) => (
        <div
          className="sections-sidebar"
          style={{ height: windowHeight - 70 }}
          ref={provided.innerRef}
          isDraggingOver={snapshot.isDraggingOver}
          {...provided.droppableProps}
        >
          {sections?.map((section) => {
            const isCollapsed = collapsedSection?.id === section.id;
            return (
              <div key={section.id}>
                <div className="item d-flex align-items-center justify-content-between" onClick={() => updateCollapsedSection(section)}>
                  {isCollapsed ? <ChevronUp /> : <ChevronDown />}
                  <p className="mb-0">{section.title}</p>
                </div>
                <Collapse isOpen={isCollapsed}>
                  {section.elements?.map((element, index) => (
                    <Draggable
                      key={element.id}
                      draggableId={element.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              className="thumbnail"
                            >
                              <img src={element.thumbnail} alt={section.title} />
                            </div>
                            {snapshot.isDragging && (
                              <div className="thumbnail">
                                <img src={element.thumbnail} alt={section.title} />
                              </div>
                            )}
                          </>
                        );
                      }}
                    </Draggable>
                  ))}
                </Collapse>
              </div>
            );
          })}
        </div>
      )}
    </Droppable>
  );
}

export default Sections;
