import React from 'react';
import { Col, Container, Row } from 'reactstrap';

function EmptyPlaceholder({ text, icon }) {
  return (
    <section className="empty-placeholder">
      <Container fluid>
        <Row>
          <Col md={12} className="d-flex flex-column align-items-center">
            <img src={icon} alt="project" height={200} />
            <h4 className="text-gray mt-4">{text}</h4>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default EmptyPlaceholder;
