import HttpHelpers from './helpers';

const SectionsApiEndpoints = {
  createSection: (projectId, pageId, data) => {
    return HttpHelpers.authenticatedAxios
      .post(`project/${projectId}/pages/${pageId}/section`, data)
      .then((response) => response.data);
  },
  deleteSection: (projectId, pageId, sectionId) => {
    return HttpHelpers.authenticatedAxios
      .delete(`project/${projectId}/pages/${pageId}/section/${sectionId}`)
      .then((response) => response.data);
  },
  updateSection: (projectId, pageId, sectionId, data) => {
    return HttpHelpers.authenticatedAxios
      .put(`project/${projectId}/pages/${pageId}/section/${sectionId}`, data)
      .then((response) => response.data);
  },
};

export default SectionsApiEndpoints;
