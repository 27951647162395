/* eslint-disable max-len */
import statisticsOneThumbnail from '../../assets/images/thumbnails/statistics-one.svg';
import statisticsTwoThumbnail from '../../assets/images/thumbnails/statistics-two.svg';

const statistics = [
  {
    id: '5ede3b6d-e46d-407f-b2c3-94cf756b94eb',
    title: 'Statistics',
    elements: [
      {
        id: '20922c8d-9b2d-4ad1-bdee-3cd092878b28',
        thumbnail: statisticsOneThumbnail,
        data: {
          type: 'statistics01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'statistics-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'statistics-card',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: '48',
                                    level: 1,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'حدد الإنجاز هنا',
                                  },
                                  interface_data: {
                                    class: 'mb-0 mt-1 text-center',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'statistics-card',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: '48',
                                    level: 1,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'حدد الإنجاز هنا',
                                  },
                                  interface_data: {
                                    class: 'mb-0 mt-1 text-center',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'statistics-card',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: '48',
                                    level: 1,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'حدد الإنجاز هنا',
                                  },
                                  interface_data: {
                                    class: 'mb-0 mt-1 text-center',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

              ],
            },
          ],
        },
      },
      {
        id: 'e9d76923-cf95-492e-bd76-b88f26942aad',
        thumbnail: statisticsTwoThumbnail,
        data: {
          type: 'statistics02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'statistics-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    class: 'row',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'col-md-3',
                      },
                      items: [
                        {
                          type: 'image',
                          metadata: {
                            initialized: true,
                          },
                          interface_data: {
                            class: '',
                            alt: '',
                            style: {
                              'object-fit': 'contain',
                              'border-top-right-radius': '0px',
                              'border-top-left-radius': '0px',
                              'border-bottom-right-radius': '0px',
                              'border-bottom-left-radius': '0px',
                              height: '50px',
                              width: '100%',
                            },
                          },
                        },
                        {
                          type: 'heading',
                          metadata: {
                            text: '48',
                            level: 3,
                          },
                          interface_data: {
                            class: 'mb-0 mt-3',
                            style: {
                              'text-align': 'center',
                            },
                          },
                        },
                        {
                          type: 'paragraph',
                          metadata: {
                            text: 'حدد الإنجاز هنا',
                          },
                          interface_data: {
                            class: 'mb-0 text-center',
                          },
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'col-md-3',
                      },
                      items: [
                        {
                          type: 'image',
                          metadata: {
                            initialized: true,
                          },
                          interface_data: {
                            class: '',
                            alt: '',
                            style: {
                              'object-fit': 'contain',
                              'border-top-right-radius': '0px',
                              'border-top-left-radius': '0px',
                              'border-bottom-right-radius': '0px',
                              'border-bottom-left-radius': '0px',
                              height: '50px',
                              width: '100%',
                            },
                          },
                        },
                        {
                          type: 'heading',
                          metadata: {
                            text: '48',
                            level: 3,
                          },
                          interface_data: {
                            class: 'mb-0 mt-3',
                            style: {
                              'text-align': 'center',
                            },
                          },
                        },
                        {
                          type: 'paragraph',
                          metadata: {
                            text: 'حدد الإنجاز هنا',
                          },
                          interface_data: {
                            class: 'mb-0 text-center',
                          },
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'col-md-3',
                      },
                      items: [
                        {
                          type: 'image',
                          metadata: {
                            initialized: true,
                          },
                          interface_data: {
                            class: '',
                            alt: '',
                            style: {
                              'object-fit': 'contain',
                              'border-top-right-radius': '0px',
                              'border-top-left-radius': '0px',
                              'border-bottom-right-radius': '0px',
                              'border-bottom-left-radius': '0px',
                              height: '50px',
                              width: '100%',
                            },
                          },
                        },
                        {
                          type: 'heading',
                          metadata: {
                            text: '48',
                            level: 3,
                          },
                          interface_data: {
                            class: 'mb-0 mt-3',
                            style: {
                              'text-align': 'center',
                            },
                          },
                        },
                        {
                          type: 'paragraph',
                          metadata: {
                            text: 'حدد الإنجاز هنا',
                          },
                          interface_data: {
                            class: 'mb-0 text-center',
                          },
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'col-md-3',
                      },
                      items: [
                        {
                          type: 'image',
                          metadata: {
                            initialized: true,
                          },
                          interface_data: {
                            class: '',
                            alt: '',
                            style: {
                              'object-fit': 'contain',
                              'border-top-right-radius': '0px',
                              'border-top-left-radius': '0px',
                              'border-bottom-right-radius': '0px',
                              'border-bottom-left-radius': '0px',
                              height: '50px',
                              width: '100%',
                            },
                          },
                        },
                        {
                          type: 'heading',
                          metadata: {
                            text: '48',
                            level: 3,
                          },
                          interface_data: {
                            class: 'mb-0 mt-3',
                            style: {
                              'text-align': 'center',
                            },
                          },
                        },
                        {
                          type: 'paragraph',
                          metadata: {
                            text: 'حدد الإنجاز هنا',
                          },
                          interface_data: {
                            class: 'mb-0 text-center',
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default statistics;
