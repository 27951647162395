import React from 'react';
import {
  Form, Modal, ModalBody, ModalFooter, Label, FormGroup,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { XLg } from 'react-bootstrap-icons';
import schema from './schema';
import InputField from '../../../../../components/input';
import { createPage } from '../../../../../redux/actions/pages.actions';

function CreatePage({
  isModalOpen, toggleModal, projectId, updatePages,
}) {
  const dispatch = useDispatch();
  const {
    register, reset, handleSubmit, formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(createPage(projectId, data)).then(() => {
      updatePages();
      toggleModal();
    });
  };
  return (
    <Modal onOpened={reset} centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إضافة صفحة</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal()} />
        </div>
        <ModalBody className="pb-0">
          <InputField
            label="عنوان الصفحة"
            name="title"
            type="text"
            errors={errors}
            {...register('title')}
          />
          <FormGroup check className="mb-3">
            <input
              className="form-check-input"
              name="is_homepage"
              type="checkbox"
              {...register('is_homepage')}
              id="is_homepage"
            />
            <Label className="mb-0" check>
              صفحة رئيسية
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-navy px-4" id="confirm_add_page">
            إضافة
          </button>
          <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_add_page">
            إلغاء
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default CreatePage;
