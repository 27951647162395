import React, { useEffect, useState } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { CardChecklist } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import masterCard from '../../../assets/images/mastercard.png';
import cardsPlaceholder from '../../../assets/images/cards-placeholder.svg';
import visa from '../../../assets/images/visa.png';
import { getCards } from '../../../redux/actions/cards.actions';
import Api from '../../../services';

function PaymentMethods({ openCreateModal }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.cards.list);

  useEffect(() => {
    setLoading(true);
    dispatch(getCards()).then(() => {
      setLoading(false);
    }).catch(() => setLoading(false));
  }, []);

  const renderBrandImage = (brand) => {
    switch (brand) {
    case 'mastercard':
      return <img src={masterCard} alt="mastercard" height={25} />;
    case 'visa':
      return <img src={visa} alt="visa" height={12} />;

    default:
      return <CardChecklist />;
    }
  };

  const deleteCard = (id) => {
    Api.payments.deleteCard(id).then(() => {
      setLoading(true);
      dispatch(getCards()).then(() => {
        setLoading(false);
      }).catch(() => setLoading(false));
    });
  };

  const setDefaultCard = (id) => {
    Api.payments.setDefaultCard(id).then(() => {
      setLoading(true);
      dispatch(getCards()).then(() => {
        setLoading(false);
      }).catch(() => setLoading(false));
    });
  };
  return (
    <div className="form-card">
      <div className="bg-light d-flex justify-content-between align-items-center p-3">
        <h5 className="text-gray mb-0">طرق الدفع</h5>
        <button type="submit" className="btn btn-sm btn-blue-outline" onClick={openCreateModal} id="add_payment_method">إضافة طريقة دفع</button>
      </div>
      <div className="p-3">
        <Row>
          {loading
            ? (
              <Col md={12}>
                <Skeleton height={65} width="100%" />
              </Col>
            )
            : (
              <>
                {cards?.data?.length > 0
                  ? (
                    <Col md={12}>
                      {cards?.data?.map((card) => {
                        return (
                          <div className="py-3 d-flex align-items-center justify-content-between border-bottom">
                            <div className="d-flex align-items-center">
                              {renderBrandImage(card.brand)}
                              <h6 className="text-gray mb-0 mt-1 ms-2" dir="ltr">
                                (
                                {card.expiry_month}
                                /
                                {card?.expiry_year}
                                )
                                ****
                                {card.masked_number}
                                {' '}
                              </h6>
                            </div>
                            {card.is_default
                && <Badge color="secondary">طريقة الدفع الرئيسية</Badge>}
                            {!card.is_default
                && (
                  <div className="d-flex align-items-center">
                    <button type="submit" className="btn btn-sm btn-red me-2" onClick={() => deleteCard(card.id)} id="delete_payment_method">حذف</button>
                    <button type="submit" className="btn btn-sm btn-blue" onClick={() => setDefaultCard(card.id)} id="default_payment_method"> تعيين كطريقة دفع رئيسية</button>
                  </div>
                )}
                          </div>
                        );
                      })}
                    </Col>
                  )
                  : (
                    <Col md={12} className="d-flex flex-column align-items-center">
                      <img src={cardsPlaceholder} alt="cards" />
                      <h6 className="text-gray text-center mb-0 mt-3">لا يوجد طرق دفع مضافة</h6>
                    </Col>
                  )}
              </>
            )}
        </Row>
      </div>
    </div>
  );
}

export default PaymentMethods;
