import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import logo from '../../assets/images/logo.svg';

function HeaderLogo() {
  return (
    <header className="header-logo">
      <Container>
        <Row>
          <Col md={12} className="d-flex justify-content-center">
            <img src={logo} alt="Rawabit" className="logo" />
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default HeaderLogo;
