import React, { useState, useEffect } from 'react';
import { CardImage, XLg } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import {
  List as ListItems, Input, FormGroup, Label, Row, Modal, ModalBody, ModalFooter, Col, Form,
} from 'reactstrap';
import classNames from 'classnames';
import { updateNestedItems, updateNestedListItems } from '../../../../../helpers';
import { updateSection } from '../../../../../redux/actions/sections.actions';
import { getPages } from '../../../../../redux/actions/pages.actions';
import placeholder from '../../../../../assets/images/placeholder.svg';
import { uploadMedia } from '../../../../../redux/actions/projects.actions';

function List({
  children, config, projectId, pageId, parent, getPageDetails, previewComponent, updateProgressBar,
}) {
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = useState(false);
  const [isImageModalOpen, toggleImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imgUrl, setImgUrl] = useState(undefined);
  const [imgTarget, setImgTarget] = useState(undefined);
  const [borderTopRightRadius, setBorderTopRightRadius] = useState(undefined);
  const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(undefined);
  const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(undefined);
  const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(undefined);
  const [list, setList] = useState([]);
  const [pages, setPages] = useState([]);

  const onImageChange = (e) => {
    setSelectedImage({
      ...selectedImage,
      interface_data: {
        ...selectedImage?.interface_data,
        alt: e.target.files[0]?.name?.split('.')[0],
      },
    });
    setImgTarget(e.target.files[0]);
    const file = URL.createObjectURL(e.target.files[0]);
    setImgUrl(file);
  };

  const updateImageValue = () => {
    updateProgressBar(50);
    if (imgTarget) {
      const formData = new FormData();
      formData.append('media', imgTarget);
      formData.append('entity', 'project-media');
      formData.append('reference_id', projectId);
      formData.append('reference_type', 'project');
      dispatch(uploadMedia(formData)).then((response) => {
        const updatedConfig = {
          ...selectedImage,
          metadata: {
            id: response.uuid,
          },
          interface_data: {
            ...selectedImage?.interface_data,
            alt: selectedImage?.interface_data?.alt,
            style: {
              ...selectedImage?.interface_data?.style,
              'object-fit': selectedImage?.interface_data?.style['object-fit'],
              'border-top-right-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-top-right-radius']}px`,
              'border-top-left-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-top-left-radius']}px`,
              'border-bottom-right-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-bottom-right-radius']}px`,
              'border-bottom-left-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-bottom-left-radius']}px`,
            },
          },
        };
        updateNestedItems(parent?.components, selectedImage?.id, updatedConfig);
        dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
          toggleImageModal(false);
          updateProgressBar(100);
        }).catch(() => updateProgressBar(100));
      });
    } else {
      const updatedConfig = {
        ...selectedImage,
        interface_data: {
          ...selectedImage?.interface_data,
          alt: selectedImage?.interface_data?.alt,
          style: {
            ...selectedImage?.interface_data?.style,
            'object-fit': selectedImage?.interface_data?.style['object-fit'],
            'border-top-right-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-top-right-radius']}px`,
            'border-top-left-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-top-left-radius']}px`,
            'border-bottom-right-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-bottom-right-radius']}px`,
            'border-bottom-left-radius': `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-bottom-left-radius']}px`,
          },
        },
      };
      updateNestedItems(parent?.components, selectedImage?.id, updatedConfig);
      dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
        toggleImageModal(false);
        updateProgressBar(100);
      }).catch(() => updateProgressBar(100));
    }
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
    setImgUrl(image?.metadata?.url);
    setBorderTopRightRadius(image?.interface_data?.style && image?.interface_data?.style?.['border-top-right-radius']?.replace(/\D/g, ''));
    setBorderTopLeftRadius(image?.interface_data?.style && image?.interface_data?.style?.['border-top-left-radius']?.replace(/\D/g, ''));
    setBorderBottomRightRadius(image?.interface_data?.style && image?.interface_data?.style?.['border-bottom-right-radius']?.replace(/\D/g, ''));
    setBorderBottomLeftRadius(image?.interface_data?.style && image?.interface_data?.style?.['border-bottom-left-radius']?.replace(/\D/g, ''));
    toggleImageModal(true);
  };

  useEffect(() => {
    const clonedList = [...config.items];
    setList(clonedList);
  }, [config]);

  const updateConfigValue = () => {
    updateProgressBar(50);
    dispatch(updateSection(projectId, pageId, parent?.id, parent)).then(() => getPageDetails()).then(() => {
      toggleModal(false);
      updateProgressBar(100);
    }).catch(() => updateProgressBar(100));
  };

  const updateItem = (e, index) => {
    const newList = list?.map((item, i) => {
      const { value } = e.target;
      const { checked } = e.target;
      const { name } = e.target;
      if (index === i) {
        if (name === 'target') {
          const targetValue = checked ? '_blank' : '_self';
          updateNestedListItems(parent?.components, item.items[0].id, { metadata: { ...item.items[0].metadata, [name]: targetValue } });
          return { ...item, items: [{ ...item.items[0], metadata: { ...item.items[0].metadata, [name]: targetValue } }] };
        }
        if (name === 'externalLink' && !value.startsWith('https://')) {
          updateNestedListItems(parent?.components, item.items[0].id, { metadata: { ...item.items[0].metadata, [name]: `https://${value}` } });
          return { ...item, items: [{ ...item.items[0], metadata: { ...item.items[0].metadata, [name]: `https://${value}` } }] };
        }
        updateNestedListItems(parent?.components, item.items[0].id, { metadata: { ...item.items[0].metadata, [name]: value } });
        return { ...item, items: [{ ...item.items[0], metadata: { ...item.items[0].metadata, [name]: value } }] };
      }
      return item;
    });
    setList(newList);
  };

  const onOpened = () => {
    dispatch(getPages(projectId)).then((response) => {
      setPages(response.data);
    });
  };

  if (previewComponent) {
    return (
      <ListItems className={config?.interface_data?.class} key={config.id}>
        {children}
      </ListItems>
    );
  }
  return (
    <div className="config-list" onClick={() => toggleModal(!isModalOpen)}>
      <ListItems className={config?.interface_data?.class} key={config.id}>
        {children}
      </ListItems>
      <Modal onOpened={onOpened} className="list-modal" isOpen={isModalOpen} size="lg">
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إعدادات القائمة</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody>
          {list?.map((item, index) => {
            return (
              <Row key={item.id}>
                {item?.items[0]?.items[0]?.type === 'image'
                  ? (
                    <FormGroup className="col-3 form-group">
                      <Label className="text-gray mb-1">
                        تعديل الصورة
                      </Label>
                      <div className="d-flex flex-row align-items-center">
                        <div className="img-wrapper">
                          <img
                            style={{
                              objectFit: item?.items[0]?.items[0]?.interface_data?.style && item?.items[0]?.items[0]?.interface_data?.style['object-fit'],
                              borderTopRightRadius: item?.items[0]?.items[0]?.interface_data?.style && item?.items[0]?.items[0]?.interface_data?.style['border-top-right-radius'],
                              borderTopLeftRadius: item?.items[0]?.items[0]?.interface_data?.style && item?.items[0]?.items[0]?.interface_data?.style['border-top-left-radius'],
                              borderBottomRightRadius: item?.items[0]?.items[0]?.interface_data?.style && item?.items[0]?.items[0]?.interface_data?.style['border-bottom-right-radius'],
                              borderBottomLeftRadius: item?.items[0]?.items[0]?.interface_data?.style && item?.items[0]?.items[0]?.interface_data?.style['border-bottom-left-radius'],
                              height: item?.items[0]?.items[0]?.interface_data?.style?.height,
                              width: item?.items[0]?.items[0]?.interface_data?.style?.width,
                            }}
                            src={item?.items[0]?.items[0]?.metadata?.url || placeholder}
                            className={item?.items[0]?.items[0]?.interface_data?.class}
                            alt={item?.items[0]?.items[0]?.interface_data?.alt}
                          />
                        </div>
                        <p className="text-blue cursor-pointer mb-0 mt-1 small ms-2" onClick={() => openImageModal(item?.items[0]?.items[0])}>
                          تغيير الصورة
                        </p>
                      </div>
                    </FormGroup>
                  )
                  : (
                    <FormGroup className="col-3 form-group">
                      <Label className="text-gray mb-1">
                        تعديل النص
                      </Label>
                      <Input
                        value={item?.items[0]?.metadata?.text || ''}
                        name="text"
                        onChange={(e) => updateItem(e, index)}
                      />
                    </FormGroup>
                  )}
                <FormGroup className="form-group col-3">
                  <Label className="text-gray mb-1">
                    نوع الرابط
                  </Label>
                  <Input
                    value={item?.items[0]?.metadata?.linkPoint || ''}
                    name="linkPoint"
                    onChange={(e) => updateItem(e, index)}
                    type="select"
                    defaultValue=""
                  >
                    <option value="" disabled>يرجى الاختيار</option>
                    <option value="external">رابط خارجي</option>
                    <option value="page">صفحة من الموقع</option>
                  </Input>
                </FormGroup>
                {item?.items[0]?.metadata?.linkPoint === 'external'
                  ? (
                    <FormGroup className="form-group col-3">
                      <Label className="text-gray mb-1">
                        تعديل الرابط
                      </Label>
                      <Input
                        value={item?.items[0]?.metadata?.externalLink || ''}
                        name="externalLink"
                        onChange={(e) => updateItem(e, index)}
                        dir="ltr"
                      />
                    </FormGroup>
                  )
                  : (
                    <FormGroup className="form-group col-3">
                      <Label className="text-gray mb-1">
                        رابط الصفحة
                      </Label>
                      <Input
                        value={item?.items[0]?.metadata?.pageId || ''}
                        name="pageId"
                        onChange={(e) => updateItem(e, index)}
                        type="select"
                        defaultValue=""
                      >
                        <option value="" disabled>يرجى الاختيار</option>
                        {pages?.map((page) => {
                          return (
                            <option value={page.id}>{page.title}</option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  )}
                <FormGroup className="form-group form-switch form-check pt-4 col-3">
                  <Input
                    name="target"
                    type="checkbox"
                    checked={item?.items[0]?.metadata?.target === '_blank'}
                    onChange={(e) => updateItem(e, index)}
                    className="mt-2"
                  />
                  <Label className="text-gray mt-1 mb-0" switch>
                    فتح في نافذة جديدة
                  </Label>
                </FormGroup>
              </Row>
            );
          })}
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-end">
          <button type="button" className="btn btn-navy me-2" onClick={updateConfigValue}>حفظ</button>
          <button type="button" className="btn btn-blue-outline" onClick={() => toggleModal(!isModalOpen)}>إلغاء</button>
        </ModalFooter>
        <Modal className="img-modal" isOpen={isImageModalOpen} size="lg">
          <div className="modal-header d-flex align-items-center">
            <h5 className="text-gray mb-0 pt-2">إعدادات الصورة</h5>
            <XLg className="text-gray cursor-pointer" onClick={() => toggleImageModal(!isImageModalOpen)} />
          </div>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="img-wrapper cursor-pointer mb-3">
                  <img
                    style={{
                      objectFit: selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['object-fit'],
                      borderTopRightRadius: `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-top-right-radius']}px`,
                      borderTopLeftRadius: `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-top-left-radius']}px`,
                      borderBottomRightRadius: `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-bottom-right-radius']}px`,
                      borderBottomLeftRadius: `${selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['border-bottom-left-radius']}px`,
                      height: selectedImage?.interface_data?.style?.height,
                      width: selectedImage?.interface_data?.style?.width,
                    }}
                    src={imgUrl || placeholder}
                    className={selectedImage?.interface_data?.class}
                    alt={selectedImage?.interface_data?.alt}
                  />
                </div>
                <Input type="file" onChange={onImageChange} />
              </Col>
              <Col md={6}>
                <Form className="img-form row">
                  <FormGroup className="form-group col-md-12">
                    <Label className="text-gray mb-1">النص البديل</Label>
                    <Input
                      value={selectedImage?.interface_data?.alt}
                      onChange={(e) => setSelectedImage({
                        ...selectedImage,
                        interface_data: {
                          ...selectedImage?.interface_data,
                          alt: e.target.value,
                        },
                      })}
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup className="form-group col-md-12">
                    <Label className="text-gray mb-1">تعبئة الصورة</Label>
                    <div className="btns-container w-100">
                      <button
                        className={classNames('btn btn-blue-outline', selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['object-fit'] === 'contain' ? 'active' : '')}
                        type="button"
                        onClick={() => setSelectedImage({
                          ...selectedImage,
                          interface_data: {
                            ...selectedImage?.interface_data,
                            style: {
                              ...selectedImage?.interface_data?.style,
                              'object-fit': 'contain',
                            },
                          },
                        })}
                      >
                        <CardImage size={20} className="me-1" />
                        {' '}
                        الحجم الأصلي
                      </button>
                      <button
                        className={classNames('btn btn-blue-outline', selectedImage?.interface_data?.style && selectedImage?.interface_data?.style['object-fit'] === 'cover' ? 'active' : '')}
                        type="button"
                        onClick={() => setSelectedImage({
                          ...selectedImage,
                          interface_data: {
                            ...selectedImage?.interface_data,
                            style: {
                              ...selectedImage?.interface_data?.style,
                              'object-fit': 'cover',
                            },
                          },
                        })}
                      >
                        <CardImage size={20} className="me-1" />
                        {' '}
                        حجم الإطار
                      </button>
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group mb-3 col-md-6">
                    <Label className="text-gray mb-1">
                      تدوير الإطار (أعلى اليمين)
                    </Label>
                    <Input
                      value={borderTopRightRadius}
                      onChange={(e) => {
                        setBorderTopRightRadius(e.target.value);
                        setSelectedImage({
                          ...selectedImage,
                          interface_data: {
                            ...selectedImage?.interface_data,
                            style: {
                              ...selectedImage?.interface_data?.style,
                              'border-top-right-radius': e.target.value,
                            },
                          },
                        });
                      }}
                      type="number"
                    />
                  </FormGroup>
                  <FormGroup className="form-group mb-3 col-md-6">
                    <Label className="text-gray mb-1">
                      تدوير الإطار (أعلى اليسار)
                    </Label>
                    <Input
                      value={borderTopLeftRadius}
                      onChange={(e) => {
                        setBorderTopLeftRadius(e.target.value);
                        setSelectedImage({
                          ...selectedImage,
                          interface_data: {
                            ...selectedImage?.interface_data,
                            style: {
                              ...selectedImage?.interface_data?.style,
                              'border-top-left-radius': e.target.value,
                            },
                          },
                        });
                      }}
                      type="number"
                    />
                  </FormGroup>
                  <FormGroup className="form-group mb-3  col-md-6">
                    <Label className="text-gray mb-1">
                      تدوير الإطار (أسفل اليمين)
                    </Label>
                    <Input
                      value={borderBottomRightRadius}
                      onChange={(e) => {
                        setBorderBottomRightRadius(e.target.value);
                        setSelectedImage({
                          ...selectedImage,
                          interface_data: {
                            ...selectedImage?.interface_data,
                            style: {
                              ...selectedImage?.interface_data?.style,
                              'border-bottom-right-radius': e.target.value,
                            },
                          },
                        });
                      }}
                      type="number"
                    />
                  </FormGroup>
                  <FormGroup className="form-group col-md-6">
                    <Label className="text-gray mb-1">
                      تدوير الإطار (أسفل اليسار)
                    </Label>
                    <Input
                      value={borderBottomLeftRadius}
                      onChange={(e) => {
                        setBorderBottomLeftRadius(e.target.value);
                        setSelectedImage({
                          ...selectedImage,
                          interface_data: {
                            ...selectedImage?.interface_data,
                            style: {
                              ...selectedImage?.interface_data?.style,
                              'border-bottom-left-radius': e.target.value,
                            },
                          },
                        });
                      }}
                      type="number"
                    />
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex align-items-center justify-content-end">
            <button type="button" className="btn btn-navy me-2" onClick={updateImageValue}>حفظ</button>
            <button type="button" className="btn btn-blue-outline" onClick={() => toggleImageModal(!isImageModalOpen)}>إلغاء</button>
          </ModalFooter>
        </Modal>
      </Modal>
    </div>
  );
}

export default List;
