import React, { useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const EditForm = React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({}));
  const textareaRef = useRef();

  const onFocus = (e) => {
    const { value } = e.target;
    e.target.value = '';
    e.target.value = value;
  };

  const onChange = (e) => props.onChange(e.target.value);
  return (
    <div className="form-wrapper w-100">
      <TextareaAutosize
        minRows={1}
        ref={textareaRef}
        className={`${props.className} w-100`}
        value={props.value}
        onChange={onChange}
        autoFocus
        onFocus={onFocus}
        onBlur={props.onBlur}
      />
    </div>
  );
});

export default EditForm;
