/* eslint-disable max-lines */
import headerOneThumbnail from '../../assets/images/thumbnails/header-one.svg';
import headerTwoThumbnail from '../../assets/images/thumbnails/header-two.svg';
import headerThreeThumbnail from '../../assets/images/thumbnails/header-three.svg';

const headers = [
  {
    id: '392cb397-5a8a-4986-bdff-150930624816',
    title: 'Headers',
    elements: [
      {
        id: 'f370b447-c5d8-4dfd-bda7-db67a59f0bf6',
        thumbnail: headerOneThumbnail,
        data: {
          type: 'header01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'header-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 col-6 d-flex justify-content-start order-1',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '65px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6 col-12 d-flex align-items-center justify-content-md-end justify-content-center order-md-2 order-3',
                          },
                          items: [
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 p-0 mt-3 mt-md-0',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item me-3',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ١',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item me-3',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٢',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٣',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6 d-flex align-items-center justify-content-end order-2 order-md-3',
                          },
                          items: [
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'الصفحة الرئيسة',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '55570efc-30c4-40a0-a7f2-f6b233ac6222',
        thumbnail: headerTwoThumbnail,
        data: {
          type: 'header02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'header-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 d-flex align-items-center justify-content-md-start justify-content-center',
                          },
                          items: [
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-md-0 p-0',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item me-3',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ١',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item me-3',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٢',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٣',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 col-6 d-flex justify-content-md-center',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '65px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 col-6 d-flex align-items-center justify-content-end',
                          },
                          items: [
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'الصفحة الرئيسة',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '28f41495-32cb-44bb-985f-77309337e379',
        thumbnail: headerThreeThumbnail,
        data: {
          type: 'header03',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'header-three',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-12 d-flex align-items-center justify-content-center',
                          },
                          items: [
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 p-0',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item me-3',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ١',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item me-3',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٢',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٣',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default headers;
