import HttpHelpers from './helpers';

const UserApiEndpoints = {
  getUser: () => {
    return HttpHelpers.authenticatedAxios
      .get('user')
      .then((response) => response.data);
  },
  updateUser: (data) => {
    return HttpHelpers.authenticatedAxios
      .put('user', data)
      .then((response) => response.data);
  },
};

export default UserApiEndpoints;
