import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import {
  Badge, Col, Row, Spinner,
} from 'reactstrap';
import emptyPlaceholder from '../../../assets/images/empty-placeholder.svg';
import Api from '../../../services';

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState({});

  useEffect(() => {
    setLoading(true);
    Api.payments.getTransactions({ page: 1, page_size: 10 }).then((response) => {
      setTransactions(response?.data);
      setLoading(false);
      setPaginationMeta(response?.meta);
    }).catch(() => setLoading(false));
  }, []);

  const loadNextPage = () => {
    Api.payments.getTransactions({ page: paginationMeta?.current_page + 1, page_size: 10 }).then((response) => {
      setTransactions([...transactions, ...response?.data]);
      setPaginationMeta(response?.meta);
    });
  };
  return (
    <div className="form-card mb-4">
      <div className="bg-light p-3">
        <h5 className="text-gray mb-0">سجل الحركات المالية</h5>
      </div>
      <div className="p-3">
        {loading
          ? (
            <Row>
              {[1, 2, 3, 4, 5]?.map((index) => {
                return (
                  <Col md={12}>
                    <Skeleton key={index} height={55} width="100%" />
                  </Col>
                );
              })}
            </Row>
          )
          : (
            <>
              {transactions?.length > 0 ? (
                <Row>
                  <Col md={12}>
                    <div className="scrollable" id="scrollableDiv">
                      <InfiniteScroll
                        dataLength={transactions?.length}
                        next={loadNextPage}
                        hasMore={paginationMeta?.current_page !== paginationMeta?.last_page}
                        loader={(
                          <Col md={12} className="d-flex justify-content-center">
                            <Spinner className="text-gray" />
                          </Col>
                        )}
                        scrollableTarget="scrollableDiv"
                      >
                        {transactions?.map((transaction) => {
                          return (
                            <div className="py-3 d-flex align-items-center justify-content-between border-bottom" key={transaction.id}>
                              <h6 className="text-gray mb-0">
                                {transaction?.type === '1' ? 'سداد' : 'استرداد'}
                              </h6>
                              <h6 className="text-gray mb-0" dir="ltr">
                                {transaction?.amount / 100}
                                {' '}
                                <span className="text-uppercase">{transaction?.currency}</span>
                              </h6>
                              <h6 className="text-gray mb-0" dir="ltr">
                                {DateTime.fromISO(transaction?.created_at).toFormat('dd-MM-yyyy')}
                              </h6>
                              {transaction?.status === '1'
                                ? <Badge color="success">حركة ناجحة</Badge>
                                : <Badge color="danger">حركة فاشلة</Badge>}
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    </div>
                  </Col>
                </Row>
              )
                : (
                  <Row>
                    <Col md={12} className="d-flex flex-column align-items-center">
                      <img src={emptyPlaceholder} alt="empty" />
                      <h6 className="text-gray text-center mb-0 mt-3">لا يوجد حركات مالية</h6>
                    </Col>
                  </Row>
                )}
            </>
          )}
      </div>
    </div>
  );
}

export default Transactions;
