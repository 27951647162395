import Api from '../../services';

export const login = (data) => {
  return (dispatch) => {
    return Api.auth.login(data).then((response) => {
      dispatch({ type: 'LOGIN', data: response });
      return response;
    });
  };
};

export const signup = (data) => {
  return (dispatch) => {
    return Api.auth.signup(data).then((response) => {
      dispatch({ type: 'SIGN_UP', data: response });
      return response;
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    return Api.auth.logout().then((response) => {
      dispatch({ type: 'LOGOUT' });
      return response;
    });
  };
};

export const guestUser = () => {
  return (dispatch) => {
    return Api.auth.guestUser().then((response) => {
      dispatch({ type: 'GUEST_USER', data: response });
      return response;
    });
  };
};
