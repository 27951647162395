import React, { useState } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { DateTime } from 'luxon';
import { Trash } from 'react-bootstrap-icons';
import ReactTooltip from 'react-tooltip';
import emptyPlaceholder from '../../../../../../assets/images/empty-placeholder.svg';
import DeleteDomain from '../delete';

function DomainsList({
  domains, openStepsModal, updateDomains, projectId,
}) {
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
  const [selectedDomainId, setSelectedDomainId] = useState(undefined);

  const openDeleteModal = (id) => {
    setSelectedDomainId(id);
    toggleDeleteModal(true);
  };
  return (

    <>
      {domains?.length === 0
        ? (
          <div className="p-3 d-flex flex-column align-items-center">
            <img src={emptyPlaceholder} alt="empty" />
            <h6 className="text-gray text-center mb-0 mt-3">لا يوجد نطاقات مضافة</h6>
          </div>
        )
        : (
          <div className="p-3 pb-1">
            <Row>
              {domains?.map((domain) => {
                return (
                  <Col md={6} key={domain.id}>
                    <div className="item bg-light d-flex align-items-center justify-content-between mb-3">
                      <div>
                        <h6 className="text-gray">
                          {domain?.domain}
                        </h6>
                        <p className="text-gray mb-0 small">
                          تاريخ الإضافة:
                          {' '}
                          <span dir="ltr">{DateTime.fromISO(domain?.created_at).toFormat('dd-MM-yyyy')}</span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        {domain?.status === 'verified'
                              && (
                                <Badge color="success">
                                  تم الربط
                                </Badge>
                              )}
                        {domain?.status === 'failed'
                              && (
                                <Badge color="danger">
                                  فشل التحقق
                                </Badge>
                              )}
                        {domain?.status === 'waiting_verification'
                              && (
                                <div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <Badge color="warning">
                                      بانتظار الربط
                                    </Badge>
                                  </div>
                                  <button id="domain_activation_steps" type="button" className="btn btn-sm btn-link p-0 mt-1" onClick={() => openStepsModal(domain?.id)}>خطوات التفعيل</button>
                                </div>
                              )}
                        {domain?.status === 'delete_in_progress'
                              && (
                                <Badge color="danger">
                                  جاري الحذف
                                </Badge>
                              )}
                        {domain?.status === 'delete_failed'
                              && (
                                <Badge color="danger">
                                  فشل الحذف
                                </Badge>
                              )}
                        {domain?.status === 'deleted'
                              && (
                                <Badge color="danger">
                                  محذوف
                                </Badge>
                              )}
                        {(domain?.status === 'failed' || domain?.status === 'verified' || domain?.status === 'waiting_verification')
                          && (
                            <>
                              <Trash className="text-danger lead ms-3 cursor-pointer" onClick={() => openDeleteModal(domain.id)} data-tip="حذف النطاق" data-for="delete" />
                              <ReactTooltip place="top" id="delete" effect="solid" className="px-2 py-1" />
                            </>
                          )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      <DeleteDomain
        isModalOpen={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        updateDomains={updateDomains}
        domainId={selectedDomainId}
        projectId={projectId}
      />
    </>

  );
}

export default DomainsList;
