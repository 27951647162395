import headers from './sections/headers';
import hero from './sections/hero';
import clients from './sections/clients';
import content from './sections/content';
import cta from './sections/cta';
import contactUs from './sections/contact-us';
import features from './sections/features';
import portfolio from './sections/portfolio';
import team from './sections/team';
import testimonials from './sections/testimonials';
import dividers from './sections/dividers';
import footers from './sections/footers';
import biography from './sections/biography';
import videos from './sections/videos';
import statistics from './sections/statistics';

const data = [
  ...headers,
  ...hero,
  ...biography,
  ...clients,
  ...content,
  ...statistics,
  ...videos,
  ...cta,
  ...features,
  ...portfolio,
  ...team,
  ...testimonials,
  ...dividers,
  ...contactUs,
  ...footers,
];

export default data;
