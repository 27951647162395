import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import Skeleton from 'react-loading-skeleton';
import { setServerErrors } from '../../../../../helpers';
import Api from '../../../../../services';
import CreateDomain from './create';
import schema from './create/schema';
import Steps from './steps';
import { getUser } from '../../../../../redux/actions/user.actions';
import { publishProject } from '../../../../../redux/actions/projects.actions';
import Subscribe from '../../subscribe';
import DomainsList from './list';
import Placeholder from './placeholder';
import PublishProject from '../../publish';
import { stripePromise } from '../../../../../App';

function ProjectDomains() {
  const dispatch = useDispatch();
  const params = useParams();
  const [domains, setDomains] = useState([]);
  const [isCreateModalOpen, toggleCreateModal] = useState(false);
  const [isStepsModalOpen, toggleStepsModal] = useState(false);
  const [selectedDomainId, setSelectedDomainId] = useState(undefined);
  const [isSubscribeModalOpen, toggleSubscribeModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(undefined);
  const [project, setProject] = useState(undefined);
  const [isPublishModalOpen, togglePublishModal] = useState(false);
  const [publishUrl, setPublishUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.data);

  const { handleSubmit, reset, setError } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    Api.projects.createProjectDomain(params?.projectId, data).then(() => {
      reset();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  const getDomains = () => {
    setLoading(true);
    Api.projects.getProjectDomains(params?.projectId).then((response) => {
      setDomains(response.data);
      setLoading(false);
    }).catch(() => setLoading(false));
  };

  const getProject = () => {
    Api.projects.getProject(params?.projectId).then((response) => setProject(response.data));
  };

  const onPublish = () => {
    dispatch(publishProject(params?.projectId)).then((response) => {
      getProject();
      setPublishUrl(response?.url);
      togglePublishModal(true);
    });
  };

  useEffect(() => {
    dispatch(getUser());
    getDomains();
    getProject();
  }, []);

  const openStepsModal = useCallback((id) => {
    setSelectedDomainId(id);
    toggleStepsModal(true);
  }, [setSelectedDomainId, toggleStepsModal]);

  const updateDomains = useCallback(() => {
    getDomains();
  }, [setDomains]);

  const updateClientSecret = useCallback((value) => {
    setClientSecret(value);
  }, [toggleSubscribeModal, setClientSecret]);

  if (!user) return null;

  return (
    <>
      <Row className="mt-4">
        <Col md={8}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-card">
              <div className="header bg-light p-3 d-flex align-items-center justify-content-between">
                <h6 className="text-gray mb-0">إعدادت النطاق</h6>
                {user?.active_subscription && project?.published_at
                && (
                  <button
                    type="button"
                    className="btn btn-sm btn-blue"
                    onClick={() => toggleCreateModal(true)}
                    id="connect_domain"
                  >
                    ربط نطاق
                  </button>
                )}
              </div>
              {loading
                ? (
                  <div className="p-3 pb-1">
                    <Row>
                      {[1, 2, 3, 4]?.map((index) => {
                        return (
                          <Col md={6} key={index}>
                            <Skeleton height={78} width="100%" className="mb-2" />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                )
                : (
                  <>
                    {user && user?.active_subscription
                      ? (
                        <>
                          {project?.published_at
                            ? (
                              <DomainsList domains={domains} openStepsModal={openStepsModal} updateDomains={updateDomains} projectId={params?.projectId} />
                            )
                            : (
                              <Placeholder
                                title="يجب عليك نشر المشروع حتى تتمكن من ربط النطاق"
                                btnText="انشر المشروع"
                                onClick={onPublish}
                              />
                            )}
                        </>
                      )
                      : (
                        <Placeholder
                          title="لا يوجد لديك اشتراك فعّال حتى تتمكن من ربط النطاق"
                          btnText="اشترك الآن"
                          onClick={toggleSubscribeModal}
                        />
                      )}
                  </>
                )}
            </div>
          </Form>
        </Col>
      </Row>
      <PublishProject
        url={publishUrl}
        isModalOpen={isPublishModalOpen}
        toggleModal={togglePublishModal}
        projectId={params.projectId}
      />
      <CreateDomain isModalOpen={isCreateModalOpen} toggleModal={toggleCreateModal} projectId={params.projectId} updateDomains={updateDomains} />
      <Steps isModalOpen={isStepsModalOpen} toggleModal={toggleStepsModal} projectId={params.projectId} domainId={selectedDomainId} />
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <Subscribe
          isModalOpen={isSubscribeModalOpen}
          toggleModal={toggleSubscribeModal}
          updateClientSecret={updateClientSecret}
        />
      </Elements>
    </>
  );
}

export default ProjectDomains;
