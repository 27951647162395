import React, { useState, useEffect } from 'react';
import { Gear, HouseDoorFill, PlusCircleFill } from 'react-bootstrap-icons';
import { useParams, useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import classNames from 'classnames';

function PagesSettings({
  pages, toggleCreateModal, isCreateModalOpen, openPageSettings, user, togglePromoteModal,
}) {
  const params = useParams();
  const navigate = useNavigate();
  const { Option } = components;
  const [selectedPage, setSelectedPage] = useState(undefined);

  const onChange = (option) => {
    setSelectedPage(option);
    navigate(`/builder/${params.projectId}/pages/${option.value}`);
  };

  const renderOption = (props) => {
    return (
      <Option {...props}>
        <div className="d-flex align-items-center">
          {props?.data?.isHomePage && <HouseDoorFill className={classNames('me-2', props?.isSelected ? 'text-white' : 'text-blue')} />}
          <span className="mt-2">
            {props.label}
          </span>
        </div>
      </Option>
    );
  };

  const addPage = () => {
    if (!user?.active_subscription && pages?.length === 2) {
      togglePromoteModal(true);
    } else {
      toggleCreateModal(!isCreateModalOpen);
    }
  };

  useEffect(() => {
    if (!params?.pageId || pages?.length === 0) return;

    const title = pages?.find((page) => page.value === params.pageId)?.label;
    setSelectedPage({ label: title, value: params.pageId });
  }, [params?.pageId, pages]);
  return (
    <>
      <Select
        className="react-select"
        classNamePrefix="select"
        options={pages}
        placeholder="اختر الصفحة"
        onChange={onChange}
        value={selectedPage}
        isSearchable={false}
        isRtl
        components={{ Option: renderOption }}
      />
      <button type="button" className="btn btn-blue btn-sm ms-2" onClick={() => addPage()} id="add_page">
        <PlusCircleFill className="me-2" />
        إضافة صفحة
      </button>
      <button type="button" className="btn btn-blue-outline btn-sm ms-2" onClick={openPageSettings} id="page_settings">
        <Gear className="me-2" />
        إعدادات الصفحة
      </button>
    </>
  );
}

export default PagesSettings;
