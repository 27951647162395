import React, { useEffect, useState } from 'react';
import { CaretDownFill, Gear, Power } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navbar, NavbarToggler, Collapse, Nav, NavbarText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { getUser } from '../../redux/actions/user.actions';
import { logout } from '../../redux/actions/auth.actions';

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, toggleCollapse] = useState(false);

  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    dispatch(getUser()).then((response) => {
      window.clarity('identify', response?.data?.email);
      window.freshpaint.identify(response?.data?.email);
    });
  }, []);

  const logoutHandler = () => {
    dispatch(logout()).then(() => {
      navigate('/auth/login');
    });
  };
  return (
    <header className="py-2">
      <Navbar expand="md" light>
        <div className="navbar-brand">
          <Link to="/" id="navigate_home">
            <img src={logo} alt="Rawabit" className="logo" />
          </Link>
        </div>
        <NavbarToggler onClick={() => toggleCollapse(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav
            className="ms-auto"
            navbar
          >
            <UncontrolledDropdown nav inNavbar dir="ltr">
              <DropdownToggle nav>
                <CaretDownFill className="text-gray ms-1" size={14} />
                <span className="text-gray">{user?.name}</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavbarText className="text-gray cursor-pointer" onClick={() => navigate('/settings/profile')}>
                    الإعدادات
                    <Gear className="me-2" />
                  </NavbarText>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <NavbarText className="text-gray cursor-pointer" onClick={logoutHandler}>
                    تسجيل الخروج
                    <Power className="me-2" />
                  </NavbarText>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
