import Api from '../../services';

export const getPages = (projectId) => {
  return (dispatch) => {
    return Api.pages.getPages(projectId).then((response) => {
      dispatch({ type: 'GET_PAGES', data: response });
      return response;
    });
  };
};

export const getPage = (projectId, pageId) => {
  return (dispatch) => {
    return Api.pages.getPage(projectId, pageId).then((response) => {
      dispatch({ type: 'GET_PAGE', data: response });
      return response;
    });
  };
};

export const createPage = (projectId, data) => {
  return (dispatch) => {
    return Api.pages.createPage(projectId, data).then((response) => {
      dispatch({ type: 'CREATE_PAGE' });
      return response;
    });
  };
};

export const editPage = (projectId, pageId, data) => {
  return (dispatch) => {
    return Api.pages.editPage(projectId, pageId, data).then((response) => {
      dispatch({ type: 'EDIT_PAGE' });
      return response;
    });
  };
};

export const deletePage = (projectId, pageId) => {
  return (dispatch) => {
    return Api.pages.deletePage(projectId, pageId).then((response) => {
      dispatch({ type: 'DELETE_PAGE' });
      return response;
    });
  };
};
