import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { deleteSection } from '../../../../redux/actions/sections.actions';

function DeleteSection({
  isModalOpen, toggleModal, projectId, pageId, sectionId, getPageDetails,
}) {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(deleteSection(projectId, pageId, sectionId)).then(() => {
      getPageDetails();
      toggleModal();
    });
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="border-0 text-gray" toggle={() => toggleModal()}>
        حذف القسم
      </ModalHeader>
      <ModalBody className="pb-0">
        <p className="text-gray">حذف القسم سيؤدي إلى خسارة جميع التعديلات التي تم إجراؤها، هل تود المتابعة؟</p>
      </ModalBody>
      <ModalFooter className="border-0">
        <button type="submit" className="btn btn-red px-4" onClick={onDelete} id="confirm_delete_section">
          تأكيد
        </button>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_delete_section">
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteSection;
