import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Col, Container, Form, Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import Loader from 'react-loaders';
import { requestPasswordlessLogin } from '../../../redux/actions/passwordless.actions';
import { setServerErrors } from '../../../helpers';
import InputField from '../../../components/input';
import HeaderLogo from '../../../components/header-logo';
import schema from './schema';
import illustration from '../../../assets/images/email-verified.svg';
import gmail from '../../../assets/images/gmail.png';
import outlook from '../../../assets/images/outlook.png';
import yahoo from '../../../assets/images/yahoo.png';

function LoginLink() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isPlaceholderVisible, showPlaceholder] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    register, handleSubmit, setError, formState: { errors }, reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(requestPasswordlessLogin(data)).then(() => {
      showPlaceholder(true);
      setEmail(data?.email);
      reset();
      setLoading(false);
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!location?.state) return;

    toast.error(location?.state?.error, { position: 'bottom-right', duration: 7000 });
  }, [location?.state]);

  if (isPlaceholderVisible) {
    return (
      <>
        <Helmet>
          <title>منصّة روابط | تسجيل الدخول</title>
          <meta name="description" content="قم بتسجيل الدخول الى أداة تصميم المواقع من روابط وابدا رحلة انشاء واطلاق موقعك الإلكتروني." />
        </Helmet>
        <section className="auth-container">
          <HeaderLogo />
          <main>
            <Container>
              <Row className="justify-content-center">
                <Col md={6}>
                  <div className="white-card d-flex flex-column justify-content-center align-items-center">
                    <img src={illustration} alt="email" height={150} />
                    <h5 className="text-gray text-center fw-bold mb-0 mt-4">
                      تم إرسال رابط الدخول المباشر إلى البريد الإلكتروني
                      {' '}
                      {email}
                    </h5>
                    <div className="d-flex mt-4 d-none d-md-block">
                      <button type="button" className="btn btn-white me-2" dir="ltr" onClick={() => window.open('https://gmail.com')}>
                        <img src={gmail} alt="gmail" height={25} className="ms-2" />
                        Open Gmail
                      </button>
                      <button type="button" className="btn btn-white me-2" dir="ltr" onClick={() => window.open('https://login.live.com/')}>
                        <img src={outlook} alt="outlook" height={25} className="ms-2" />
                        Open Outlook
                      </button>
                      <button type="button" className="btn btn-white" dir="ltr" onClick={() => window.open('https://login.yahoo.com/')}>
                        <img src={yahoo} alt="yahoo" height={25} className="ms-2" />
                        Open Yahoo
                      </button>
                    </div>
                    <h6 className="text-gray text-center fw-bold mb-0 mt-4">
                      قمت بإدخال البريد الإلكتروني بشكل خاطئ؟
                      {' '}
                      <span className="text-blue cursor-pointer" onClick={() => showPlaceholder(false)}>إعادة إدخال</span>
                    </h6>
                  </div>
                </Col>
              </Row>
            </Container>
          </main>
        </section>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>منصّة روابط | تسجيل الدخول</title>
        <meta name="description" content="قم بتسجيل الدخول الى أداة تصميم المواقع من روابط وابدا رحلة انشاء واطلاق موقعك الإلكتروني." />
      </Helmet>
      <section className="auth-container">
        <HeaderLogo />
        <main>
          <Container>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="white-card d-flex flex-column justify-content-center">
                  <h5 className="text-gray text-center fw-bold mb-3">
                    الحصول على رابط
                    <br />
                    {' '}
                    تسجيل الدخول المباشر
                  </h5>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                      label="البريد الإلكتروني"
                      name="email"
                      type="email"
                      errors={errors}
                      {...register('email')}
                      placeholder="youremail@domain.com"
                    />
                    <button type="submit" className="btn btn-blue w-100 fw-bold" id="login_magic_link" disabled={loading}>
                      {
                        loading ? <Loader type="ball-pulse" style={{ transform: 'scale(0.7)' }} /> : 'إرسال الرابط'
                      }
                    </button>
                  </Form>
                  <Link to="/auth/login" className="btn btn-link w-100 fw-bold mt-3" id="login_options">العودة إلى خيارت تسجيل الدخول</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </section>

    </>
  );
}

export default LoginLink;
