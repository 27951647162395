/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const getCards = () => {
  return (dispatch) => {
    return Api.payments.getCards().then((response) => {
      dispatch({ type: 'GET_CARDS', data: response });
      return response;
    });
  };
};
