import React, { useCallback, useRef, useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { HexColorPicker } from 'react-colorful';
import useOnClickOutside from '../../hooks';

const ColorInputField = React.forwardRef((props) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), [toggle]);
  useOnClickOutside(popover, close);
  return (
    <FormGroup className="d-flex align-items-center">
      <Input
        type="text"
        value={props.value}
        onChange={props.onChange}
        dir="ltr"
      />
      <div className="picker">
        <div
          className="swatch"
          style={props.style}
          onClick={() => toggle(true)}
        />
        {isOpen
          && (
            <div className="popover" ref={popover}>
              <HexColorPicker
                onChange={props.onChange}
                color={props.value}
              />
            </div>
          )}
      </div>
    </FormGroup>
  );
});

export default ColorInputField;
