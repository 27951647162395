import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

function PromoteSubscription({
  isModalOpen, toggleModal, modalTitle, modalBody, toggleSubscribeModal,
}) {
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="text-gray" toggle={() => toggleModal()}>
        {modalTitle}
      </ModalHeader>
      <ModalBody>
        <p className="text-gray mb-0">{modalBody}</p>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-blue px-4"
          onClick={() => {
            toggleSubscribeModal(true);
            toggleModal();
          }}
          id="promote_subscription"
        >
          ترقية
        </button>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_promote_subscription">
          المتابعة في الباقة المجانية
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default PromoteSubscription;
