import React, { useEffect, useState } from 'react';
import { Label, FormFeedback, FormGroup } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { getErrorsByPath } from '../../helpers';
import 'react-phone-input-2/lib/style.css';

const PhoneField = React.forwardRef(({
  underlined, theme, wrapperClassName, ...props
}, ref) => {
  React.useImperativeHandle(ref, () => ({}));
  const [country, setCountry] = useState('');

  useEffect(() => {
    axios.get('https://api.country.is/').then((result) => setCountry(result?.data?.country?.toLowerCase()));
  }, []);
  return (
    <FormGroup className="form-group">
      <Label className="text-gray">
        {props.label}
      </Label>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            prefix="+"
            inputClass={`${getErrorsByPath(props.errors, props.name) ? 'is-invalid' : ''}`}
            value={value}
            inputProps={{
              onChange,
            }}
            country={country || 'sa'}
            enableSearch
            disableSearchIcon
            searchPlaceholder="Search"
            enableAreaCodeStretch
          />
        )}
      />
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </FormGroup>
  );
});

export default PhoneField;
