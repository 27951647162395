import Api from '../../services';

export const requestPasswordlessLogin = (data) => {
  return (dispatch) => {
    return Api.passwordless.requestPasswordlessLogin(data).then((response) => {
      dispatch({ type: 'REQUEST_PASSWORDLESS_LOGIN' });
      return response;
    });
  };
};

export const passwordlessLogin = (data) => {
  return (dispatch) => {
    return Api.passwordless.passwordlessLogin(data).then((response) => {
      dispatch({ type: 'PASSWORDLESS_LOGIN', data: response });
      return response;
    });
  };
};
