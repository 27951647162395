/* eslint-disable max-len */
/* eslint-disable max-lines */
import footerOneThumbnail from '../../assets/images/thumbnails/footer-one.svg';
import footerTwoThumbnail from '../../assets/images/thumbnails/footer-two.svg';
import footerThreeThumbnail from '../../assets/images/thumbnails/footer-three.svg';

const footers = [
  {
    id: '4b780218-d5e8-46c8-854f-02536c16bd55',
    title: 'Footers',
    elements: [
      {
        id: 'aa5df3fa-7724-4270-a26a-5d168433ca98',
        thumbnail: footerOneThumbnail,
        data: {
          type: 'footer01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'footer-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 col-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '80px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-10 col-6 d-flex align-items-center justify-content-end',
                          },
                          items: [
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 p-0',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '63de14bf-9678-4889-a80d-4289046fc79d',
        thumbnail: footerTwoThumbnail,
        data: {
          type: 'footer02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'footer-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'd-flex align-items-center row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 d-flex align-items-center justify-content-center justify-content-md-start',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '80px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 d-flex align-items-center justify-content-center',
                          },
                          items: [
                            {
                              type: 'paragraph',
                              metadata: {
                                text: `جميع الحقوق محفوظة ${new Date().getFullYear()}©`,
                              },
                              interface_data: {
                                class: 'text-center mb-0 py-2 py-md-0',
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4 d-flex align-items-center justify-content-center justify-content-md-end',
                          },
                          items: [
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 p-0',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '9787d651-0561-4236-bfc7-d6e46d4870ef',
        thumbnail: footerThreeThumbnail,
        data: {
          type: 'footer03',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'footer-three',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'contain',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '80px',
                                  width: '40%',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'mt-3 mb-0',
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-5',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'تواصل معنا',
                                level: 5,
                              },
                              interface_data: {
                                class: 'fw-bold mb-3',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'mt-3 ps-3',
                              },
                            },
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 ps-3',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: 'list-inline-item',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                      items: [
                                        {
                                          type: 'image',
                                          metadata: {
                                            initialized: true,
                                          },
                                          interface_data: {
                                            class: 'icon',
                                            alt: '',
                                            style: {
                                              'object-fit': 'cover',
                                              'border-top-right-radius': '30px',
                                              'border-top-left-radius': '30px',
                                              'border-bottom-right-radius': '30px',
                                              'border-bottom-left-radius': '30px',
                                              height: '30px',
                                              width: '30px',
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-3',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'روابط مهمة',
                                level: 5,
                              },
                              interface_data: {
                                class: 'fw-bold mb-3',
                                style: {
                                  'text-align': 'right',
                                },
                              },
                            },
                            {
                              type: 'list',
                              metadata: {
                                listType: 'unorderlist',
                              },
                              interface_data: {
                                class: 'mb-0 ps-3 list-unstyled',
                              },
                              items: [
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: '',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ١',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: '',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٢',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'listElement',
                                  interface_data: {
                                    class: '',
                                  },
                                  items: [
                                    {
                                      type: 'link',
                                      metadata: {
                                        text: 'القائمة ٣',
                                        linkPoint: 'external',
                                        externalLink: '',
                                        target: '_self',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default footers;
