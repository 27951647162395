import React, { useEffect, useState } from 'react';
import { ArrowRight, CloudUpload, Eye } from 'react-bootstrap-icons';
import { useParams, Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CreatePage from '../pages/create';
import PagesSettings from '../pages';
import PromoteSubscription from '../../../../components/promote-subscription';
import Api from '../../../../services';

function NavigationBar({
  publishProject, toggleSubscribeModal, openPageSettings, updatePages, pages, user, toggleCompleteProfileModal,
}) {
  const params = useParams();
  const [isCreateModalOpen, toggleCreateModal] = useState(false);
  const [isPromoteModalOpen, togglePromoteModal] = useState(false);
  const [publishedAt, setPublishedAt] = useState(null);

  useEffect(() => {
    if (!params?.projectId) return;

    Api.projects.getProject(params.projectId).then((response) => setPublishedAt(response?.data?.published_at));
  }, [params?.projectId]);

  const onPublish = () => {
    if (!user?.email) {
      toggleCompleteProfileModal();
    } else {
      publishProject();
    }
  };
  return (
    <>
      <div className="header-wrapper">
        <Row>
          <Col md={8} className="d-flex align-items-center">
            <Link
              to="/projects"
              className="btn btn-outline-secondary btn-sm d-flex align-items-center justify-content-center me-3"
              id="navigate_projects_project_details"
            >
              <ArrowRight size={20} className="me-2" />
              العودة إلى المشاريع
            </Link>
            <PagesSettings
              isCreateModalOpen={isCreateModalOpen}
              toggleCreateModal={toggleCreateModal}
              pages={pages}
              openPageSettings={openPageSettings}
              user={user}
              togglePromoteModal={togglePromoteModal}
            />
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <Link to={`/preview/${params.projectId}/pages/${params?.pageId}`} className="btn btn-navy-outline btn-sm" id="preview_project">
              <Eye className="me-2" />
              معاينة
            </Link>
            <button type="button" className="btn btn-success btn-sm ms-2" onClick={onPublish} id="publish_project">
              <CloudUpload className="me-2" />
              {publishedAt ? 'حفظ التعديلات' : 'نشر الموقع على الإنترنت'}
            </button>
            <Link to={`/builder/${params.projectId}/settings`} type="button" className="btn btn-navy btn-sm mx-2" id="navigate_project_settings">
              إعدادات المشروع
            </Link>
          </Col>
        </Row>
      </div>
      <CreatePage
        isModalOpen={isCreateModalOpen}
        toggleModal={toggleCreateModal}
        projectId={params.projectId}
        updatePages={updatePages}
      />
      <PromoteSubscription
        isModalOpen={isPromoteModalOpen}
        toggleModal={togglePromoteModal}
        toggleSubscribeModal={toggleSubscribeModal}
        modalTitle="إضافة صفحة"
        modalBody="لقد وصلت إلى الحد الأعلى من عدد الصفحات في الباقة المجانية، يجب أن تقوم بترقية الباقة لتتمكن من إضافة صفحات جديدة"
      />
    </>
  );
}

export default NavigationBar;
