import React from 'react';

function Footer({ children }) {
  return (
    <footer>
      {children}
    </footer>
  );
}

export default Footer;
