import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import {
  Col, Container, Nav, NavItem, Row,
} from 'reactstrap';
import NavigationBar from './navbar';

function ProjectSettings() {
  const params = useParams();

  return (
    <>
      <NavigationBar />
      <section className="mt-4">
        <Container>
          <Row>
            <Col md={12}>
              <Nav className="underlined-tabs" tabs>
                <NavItem>
                  <NavLink to={`/builder/${params.projectId}/settings`} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    الإعدادات العامة
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={`/builder/${params.projectId}/domains`} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    إعدادات النطاق
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Outlet />
        </Container>
      </section>
    </>
  );
}

export default ProjectSettings;
