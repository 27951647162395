import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import cardsReducer from './cards.reducer';
import projectsReducer from './projects.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  user: userReducer,
  cards: cardsReducer,
});

export default rootReducer;
