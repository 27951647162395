import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import store from './redux/storeConfig';
import ProjectsList from './views/projects/list';
import Login from './views/auth/login';
import ProtectedRoutes from './router/protected-routes';
import PublicRoutes from './router/public-routes';
import MagicLink from './views/auth/magic-link';
import LoginLink from './views/auth/login-link';
import ProjectDetails from './views/projects/details';
import ProjectPreview from './views/projects/preview';
import ProjectIframe from './views/projects/iframe';
import Error from './views/error';
import Analytics from './views/analytics';
import Account from './views/settings';
import Profile from './views/settings/profile';
import Subscriptions from './views/settings/subscriptions';
import ProjectSettings from './views/projects/details/settings';
import GeneralSettings from './views/projects/details/settings/general';
import ProjectDomains from './views/projects/details/settings/domains';
import ConfirmEmail from './views/auth/confirm-email';
import Guest from './views/guest';

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

function App() {
  return (
    <Provider store={store}>
      <Analytics>
        <BrowserRouter>
          <Toaster />
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/" element={<ProjectsList />}>
                <Route path="/" element={<Navigate replace to="/projects" />} />
                <Route path="/projects" element={<ProjectsList />} index />
              </Route>
              <Route path="/builder/:projectId/pages/:pageId" element={<ProjectDetails />} index />
              <Route path="/builder/:projectId" element={<ProjectSettings />}>
                <Route path="/builder/:projectId/settings" element={<GeneralSettings />} />
                <Route path="/builder/:projectId/domains" element={<ProjectDomains />} />
              </Route>
              <Route path="/preview/:projectId/pages/:pageId" element={<ProjectPreview />} />
              <Route path="/iframe/:projectId/pages/:pageId" element={<ProjectIframe />} />
              <Route path="/settings" element={<Account />}>
                <Route path="/settings/profile" element={<Profile />} />
                <Route path="/settings/subscriptions" element={<Subscriptions />} />
              </Route>
            </Route>
            <Route path="auth" element={<PublicRoutes />}>
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/magic-link" element={<MagicLink />} />
              <Route path="/auth/login-link" element={<LoginLink />} />
            </Route>
            <Route path="/auth/confirm-email" element={<ConfirmEmail />} />
            <Route path="/start/:templateId" element={<Guest />} />
            <Route path="/:statusCode" element={<Error />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </Analytics>
    </Provider>
  );
}

export default App;
