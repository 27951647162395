import axios from 'axios';

class HttpHelpers {
  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_URL;
    this.authenticatedAxios = axios.create({ baseURL: this.apiBaseUrl });
    this.unAuthenticatedAxios = axios.create({ baseURL: this.apiBaseUrl });
    this.addAuthenticationInterceptor();
    this.addUnauthenticationInterceptor();
    this.errorInterceptor();
  }

  getToken() {
    return localStorage.getItem('api_token');
  }

  errorInterceptor() {
    this.authenticatedAxios.interceptors.response.use(null, (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('api_token');
        window.location.pathname = '/auth/login';
      }
      if (error.response.status === 403) {
        window.location.pathname = '/403';
      }
      if (error.response.status === 404) {
        window.location.pathname = '/404';
      }
      return Promise.reject(error);
    });
  }

  addAuthenticationInterceptor() {
    this.authenticatedAxios.interceptors.request.use(
      async (config) => {
        const accessToken = await this.getToken();
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  addUnauthenticationInterceptor() {
    this.unAuthenticatedAxios.interceptors.request.use(
      async (config) => {
        return config;
      },
      (error) => Promise.reject(error),
    );
  }
}

export default new HttpHelpers();
