/* eslint-disable no-useless-escape */
import * as yup from 'yup';

const schema = yup.object({
  domain: yup.string()
    .required('يجب تعبئة هذه الخانة')
    .matches(
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})(\/)?$/,
      'الصيغة المدخلة غير صحيحة',
    ),
}).required();

export default schema;
