import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Form,
} from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputField from '../../../../../../components/input';
import schema from './schema';
import { setServerErrors } from '../../../../../../helpers';
import Api from '../../../../../../services';

function CreateDomain({
  isModalOpen, toggleModal, projectId, updateDomains,
}) {
  const {
    register, handleSubmit, setError, formState: { errors }, reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    Api.projects.createProjectDomain(projectId, data).then(() => {
      updateDomains();
      toggleModal(false);
      reset();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="text-gray" toggle={() => toggleModal()}>
        ربط نطاق
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <InputField
            label="النطاق"
            name="domain"
            type="text"
            errors={errors}
            {...register('domain')}
            dir="ltr"
          />
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-blue px-4" id="add_domain">
            تأكيد
          </button>
          <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()} id="cancel_add_domain">
            إلغاء
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default CreateDomain;
