import HttpHelpers from './helpers';

const PasswordlessApiEndpoints = {
  requestPasswordlessLogin: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('passwordless/request', data)
      .then((response) => response.data);
  },
  passwordlessLogin: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('passwordless/login', data)
      .then((response) => response.data);
  },
};

export default PasswordlessApiEndpoints;
