import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';

const Analytics = ({ children }) => {
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    hotjar.initialize(3116652, 6);

    if (hotjar.initialized() && user) {
      hotjar.identify(user?.id, { email: user?.email });
    }
  }, [user]);

  return children;
};

export default Analytics;
