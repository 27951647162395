/* eslint-disable max-lines */
/* eslint-disable max-len */
import testimonialOneThumbnail from '../../assets/images/thumbnails/testimonial-one.svg';
import testimonialTwoThumbnail from '../../assets/images/thumbnails/testimonial-two.svg';
import testimonialThreeThumbnail from '../../assets/images/thumbnails/testimonial-three.svg';

const testimonials = [
  {
    id: '93a7f669-bb5e-48b3-ac14-d47de6287c81',
    title: 'Testimonials',
    elements: [
      {
        id: 'b2ecc760-cfcd-4438-944a-3dd21fd714f2',
        thumbnail: testimonialOneThumbnail,
        data: {
          type: 'testimonials01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'testimonial-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-3',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-5',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'testimonial-card d-flex flex-column align-items-center',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '80px',
                                      'border-top-left-radius': '80px',
                                      'border-bottom-right-radius': '80px',
                                      'border-bottom-left-radius': '80px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: 'text-center',
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mb-0 fw-bold',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-2 d-flex justify-content-center',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'vr',
                              },
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-5',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'testimonial-card d-flex flex-column align-items-center',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '80px',
                                      'border-top-left-radius': '80px',
                                      'border-bottom-right-radius': '80px',
                                      'border-bottom-left-radius': '80px',
                                      height: '80px',
                                      width: '80px',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: 'text-center',
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'mb-0 fw-bold',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'center',
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: 'bb36c965-9fab-491b-9aaf-4976600ee213',
        thumbnail: testimonialTwoThumbnail,
        data: {
          type: 'testimonials02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'testimonial-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-3',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'testimonial-card',
                              },
                              items: [
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {},
                                },
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '70px',
                                      'border-top-left-radius': '70px',
                                      'border-bottom-right-radius': '70px',
                                      'border-bottom-left-radius': '70px',
                                      height: '70px',
                                      width: '70px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'fw-bold',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'testimonial-card',
                              },
                              items: [
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {},
                                },
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '70px',
                                      'border-top-left-radius': '70px',
                                      'border-bottom-right-radius': '70px',
                                      'border-bottom-left-radius': '70px',
                                      height: '70px',
                                      width: '70px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'fw-bold',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'testimonial-card',
                              },
                              items: [
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                  },
                                  interface_data: {
                                    class: '',
                                  },
                                },
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '70px',
                                      'border-top-left-radius': '70px',
                                      'border-bottom-right-radius': '70px',
                                      'border-bottom-left-radius': '70px',
                                      height: '70px',
                                      width: '70px',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 4,
                                  },
                                  interface_data: {
                                    class: 'fw-bold',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '3b8a196f-1473-4f97-8f85-8fe1e5c523cd',
        thumbnail: testimonialThreeThumbnail,
        data: {
          type: 'testimonials03',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'testimonial-three',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العنوان الرئيسي',
                                level: 2,
                              },
                              interface_data: {
                                class: 'mb-0',
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'border rounded p-4 mt-4',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 5,
                                  },
                                  interface_data: {
                                    class: 'fw-bold mb-2',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-3',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'border rounded p-4 mt-4',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 5,
                                  },
                                  interface_data: {
                                    class: 'fw-bold mb-2',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-3',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'border rounded p-4 mt-4',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 5,
                                  },
                                  interface_data: {
                                    class: 'fw-bold mb-2',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-3',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'border rounded p-4 mt-4',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 5,
                                  },
                                  interface_data: {
                                    class: 'fw-bold mb-2',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-3',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'border rounded p-4 mt-4',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 5,
                                  },
                                  interface_data: {
                                    class: 'fw-bold mb-2',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-3',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'border rounded p-4 mt-4',
                              },
                              items: [
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'اسم العميل',
                                    level: 5,
                                  },
                                  interface_data: {
                                    class: 'fw-bold mb-2',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'heading',
                                  metadata: {
                                    text: 'المسمى الوظيفي',
                                    level: 6,
                                  },
                                  interface_data: {
                                    class: 'mb-3',
                                    style: {
                                      'text-align': 'right',
                                    },
                                  },
                                },
                                {
                                  type: 'paragraph',
                                  metadata: {
                                    text: 'هذا النص هو مثال يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا النص من مولد النص العربي.',
                                  },
                                  interface_data: {
                                    class: 'mb-0',
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default testimonials;
