import HttpHelpers from './helpers';

const PagesApiEndpoints = {
  getPages: (projectId) => {
    return HttpHelpers.authenticatedAxios
      .get(`project/${projectId}/pages`)
      .then((response) => response.data);
  },
  getPage: (projectId, pageId) => {
    return HttpHelpers.authenticatedAxios
      .get(`project/${projectId}/pages/${pageId}`)
      .then((response) => response.data);
  },
  createPage: (projectId, data) => {
    return HttpHelpers.authenticatedAxios
      .post(`project/${projectId}/pages`, data)
      .then((response) => response.data);
  },
  editPage: (projectId, pageId, data) => {
    return HttpHelpers.authenticatedAxios
      .put(`project/${projectId}/pages/${pageId}`, data)
      .then((response) => response.data);
  },
  deletePage: (projectId, pageId) => {
    return HttpHelpers.authenticatedAxios
      .delete(`project/${projectId}/pages/${pageId}`)
      .then((response) => response.data);
  },
};

export default PagesApiEndpoints;
