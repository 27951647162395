import HttpHelpers from './helpers';

const AuthApiEndpoints = {
  login: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('login', data)
      .then((response) => response.data);
  },
  signup: (data) => {
    return HttpHelpers.unAuthenticatedAxios
      .post('register', data)
      .then((response) => response.data);
  },
  logout: () => {
    return HttpHelpers.authenticatedAxios
      .post('logout')
      .then((response) => response.data);
  },
  sendVerificationEmail: () => {
    return HttpHelpers.authenticatedAxios
      .post('email/verification-notification')
      .then((response) => response.data);
  },
  guestUser: () => {
    return HttpHelpers.unAuthenticatedAxios
      .post('guest')
      .then((response) => response.data);
  },
};

export default AuthApiEndpoints;
