import React, {
  createElement, useCallback, useEffect, useRef, useState,
} from 'react';
import { List, ListInlineItem } from 'reactstrap';
import {
  ArrowDownCircle, ArrowUpCircle, Files, Gear, Trash,
} from 'react-bootstrap-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import LoadingBar from 'react-top-loading-bar';
import LoadingOverlay from 'react-loading-overlay';
import { Elements } from '@stripe/react-stripe-js';
import ReactTooltip from 'react-tooltip';
import { getPage, getPages } from '../../../redux/actions/pages.actions';
import { createSection, updateSection } from '../../../redux/actions/sections.actions';
import componentsKeys from './components';
import { publishProject } from '../../../redux/actions/projects.actions';
import PublishProject from './publish';
import SectionSettings from './sections/settings';
import PageSettings from './pages/settings';
import Subscribe from './subscribe';
import { getUser } from '../../../redux/actions/user.actions';
import DeleteSection from './delete/section';
import dragAndDrop from '../../../assets/images/drag-drop.svg';
import ResponsiveView from './responsive';
import Sections from './sections';
import sections from '../../../data';
import NavigationBar from './navigation-bar';
import { stripePromise } from '../../../App';
import CompleteProfile from '../../../components/complete-profile';

function ProjectDetails() {
  const sidebarRef = useRef();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [page, setPage] = useState({});
  const [collapsedSection, setCollapsedSection] = useState(sections[0]);
  const [selectedSection, setSelectedSection] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [publishing, setPublishing] = useState(false);
  const [isPublishModalOpen, togglePublishModal] = useState(false);
  const [isSubscribeModalOpen, toggleSubscribeModal] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState(undefined);
  const [publishUrl, setPublishUrl] = useState('');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [clientSecret, setClientSecret] = useState(undefined);
  const [activeSettings, setActiveSettings] = useState('');
  const [pages, setPages] = useState([]);
  const [isProfileModalOpen, toggleProfileModal] = useState(false);

  const user = useSelector((state) => state.user.data);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const updateClientSecret = useCallback((value) => {
    setClientSecret(value);
  }, [toggleSubscribeModal, setClientSecret]);

  const getPagesList = () => {
    dispatch(getPages(params.projectId)).then((response) => {
      const newPages = response.data.map((page) => ({ label: page.title, value: page.id, isHomePage: page.is_homepage }));
      setPages(newPages);
    });
  };

  const updatePages = useCallback(() => {
    dispatch(getPages(params.projectId)).then((response) => {
      const newPages = response.data.map((page) => ({ label: page.title, value: page.id, isHomePage: page.is_homepage }));
      setPages(newPages);
      navigate(`/builder/${params.projectId}/pages/${response.data[0]?.id}`);
    });
  }, [setPages]);

  useEffect(() => {
    if (!params?.projectId) return;

    getPagesList();
    dispatch(getUser());
  }, [params?.projectId]);

  const updateDimensions = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const getPageDetails = () => {
    const { projectId, pageId } = params;
    dispatch(getPage(projectId, pageId)).then((response) => {
      setPage(response.data);
    });
  };

  useEffect(() => {
    if (!params?.pageId) return;

    setSelectedSection(undefined);
  }, [params?.pageId]);

  useEffect(() => {
    if (!params?.pageId) return;

    getPageDetails();
  }, [params?.pageId]);

  const updateProgressBar = useCallback((value) => {
    setProgress(value);
  }, [setProgress]);

  const onPublish = useCallback(() => {
    setPublishing(true);
    dispatch(publishProject(params?.projectId)).then((response) => {
      setPublishUrl(response?.url);
      setPublishing(false);
      togglePublishModal(true);
      toggleProfileModal(false);
    }).catch(() => {
      setPublishing(false);
    });
  }, [togglePublishModal, setPublishUrl, setPublishing]);

  const toggleCompleteProfileModal = useCallback(() => {
    toggleProfileModal(!isProfileModalOpen);
  }, [toggleProfileModal]);

  const updateCollapsedSection = (section) => {
    if (collapsedSection?.id === section.id) {
      setCollapsedSection({});
    } else {
      setCollapsedSection(section);
    }
  };

  const updateSelectedSection = useCallback((section) => {
    setSelectedSection(section);
  }, [setSelectedSection]);

  const openSectionSettings = (e, section) => {
    e.stopPropagation();
    setSelectedSection(section);
    setActiveSettings('section');
    document.querySelector('.settings-sidebar').classList.add('toggled');
    document.querySelector('.droppable').classList.add('toggled');
    document.querySelector('.sections-sidebar').classList.add('toggled');
  };

  const openPageSettings = () => {
    setActiveSettings('page');
    document.querySelector('.settings-sidebar').classList.add('toggled');
    document.querySelector('.droppable').classList.add('toggled');
    document.querySelector('.sections-sidebar').classList.add('toggled');
  };

  const onCanvasClick = () => {
    setSelectedSection(undefined);
    setActiveSettings('');
    document.querySelector('.settings-sidebar').classList.remove('toggled');
    document.querySelector('.droppable').classList.remove('toggled');
    document.querySelector('.sections-sidebar').classList.remove('toggled');
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const draggedSection = collapsedSection?.elements[source.index];
    const { projectId, pageId } = params;
    const data = { ...draggedSection?.data, order: destination.index + 1 };
    dispatch(createSection(projectId, pageId, data)).then(() => getPageDetails());
  };

  const toggleDeleteSectionModal = (id) => {
    setSelectedSectionId(id);
    toggleDeleteModal(!isDeleteModalOpen);
  };

  const clone = (id) => {
    const { projectId, pageId } = params;
    const element = page.sections?.find((item) => item.id === id);
    delete element.id;

    dispatch(createSection(projectId, pageId, element)).then(() => getPageDetails());
  };

  const moveUp = (index) => {
    const { projectId, pageId } = params;

    if (index === 0) return;

    const sections = page.sections?.slice();
    const selectedSection = sections[index];
    selectedSection.order -= 1;

    dispatch(updateSection(projectId, pageId, selectedSection?.id, selectedSection)).then(() => getPageDetails());
  };

  const moveDown = (index) => {
    const { projectId, pageId } = params;
    if (index === page.sections?.length - 1) return;

    const sections = page.sections?.slice();
    const selectedSection = sections[index];
    selectedSection.order += 1;

    dispatch(updateSection(projectId, pageId, selectedSection?.id, selectedSection)).then(() => getPageDetails());
  };

  const renderChildren = (config, parent) => {
    const { projectId, pageId } = params;

    if (typeof componentsKeys[config.type] !== 'undefined') {
      return createElement(
        componentsKeys[config.type],
        {
          config,
          pageId,
          projectId,
          parent,
          getPageDetails,
          updateProgressBar,
        },
        config.items
          && (typeof config.items === 'string'
            ? config.items
            : config.items?.map((component) => renderChildren(component, parent))),
      );
    }
    return null;
  };

  const renderComponent = (config) => {
    const { projectId, pageId } = params;

    if (typeof componentsKeys[config.type] !== 'undefined') {
      return createElement(
        componentsKeys[config.type],
        {
          config,
          pageId,
          projectId,
          getPageDetails,
          updateProgressBar,
        },
        config.components
          && (typeof config.components === 'string'
            ? config.components
            : config.components?.map((component) => renderChildren(component, config))),
      );
    }
    return null;
  };

  if (isTabletOrMobile) {
    return <ResponsiveView />;
  }
  return (
    <LoadingOverlay
      active={publishing}
      spinner
      text="جاري نشر المشروع..."
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <NavigationBar
          toggleCompleteProfileModal={toggleCompleteProfileModal}
          publishProject={onPublish}
          toggleSubscribeModal={toggleSubscribeModal}
          openPageSettings={openPageSettings}
          pages={pages}
          updatePages={updatePages}
          user={user}
        />
        <Sections
          windowHeight={windowHeight}
          collapsedSection={collapsedSection}
          updateCollapsedSection={updateCollapsedSection}
        />
        <LoadingBar shadow containerStyle={{ direction: 'ltr', height: 4 }} color="#00B4D8" progress={progress} onLoaderFinished={() => setProgress(0)} />
        <div className="droppable" onClick={onCanvasClick}>
          <div className="canvas">
            <Droppable key="droppable" droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="droppable-wrapper"
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                  style={{
                    backgroundColor: (page?.style && page?.style['background-color']) || 'white',
                    color: page?.style?.color,
                    height: windowHeight - 120,
                  }}
                  {...provided.droppableProps}
                >
                  {page?.sections?.length > 0
                    ? (
                      <>
                        {page.sections?.sort((a, b) => a.order - b.order)?.map((section, index) => {
                          return (
                            <Draggable
                              key={section.id}
                              draggableId={section.id}
                              index={index}
                            >
                              {(
                                provided,
                                snapshot,
                              ) => (
                                <div
                                  className={classnames('sortable-item', selectedSection?.id === section.id ? 'border' : '')}
                                  {...provided.draggableProps}
                                  isDragging={snapshot.isDragging}
                                  style={provided.draggableProps.style}
                                  ref={provided.innerRef}
                                >
                                  {renderComponent(section)}
                                  <div className="section-actions">
                                    <List>
                                      <ListInlineItem onClick={(e) => openSectionSettings(e, section)} data-tip="إعدادات القسم" data-for="settings"><Gear size={20} /></ListInlineItem>
                                      <ListInlineItem onClick={() => clone(section.id)} data-tip="تكرار القسم" data-for="clone"><Files size={20} /></ListInlineItem>
                                      <ListInlineItem onClick={() => moveUp(index)} data-tip="نقل للأعلى" data-for="moveUp"><ArrowUpCircle size={20} /></ListInlineItem>
                                      <ListInlineItem onClick={() => moveDown(index)} data-tip="نقل للأسفل" data-for="moveDown"><ArrowDownCircle size={20} /></ListInlineItem>
                                      <ListInlineItem onClick={() => toggleDeleteSectionModal(section.id)} data-tip="حذف القسم" data-for="delete"><Trash size={20} /></ListInlineItem>
                                    </List>
                                  </div>
                                  <ReactTooltip place="bottom" id="delete" effect="solid" className="px-2 py-1" />
                                  <ReactTooltip place="bottom" id="moveDown" effect="solid" className="px-2 py-1" />
                                  <ReactTooltip place="bottom" id="moveUp" effect="solid" className="px-2 py-1" />
                                  <ReactTooltip place="bottom" id="clone" effect="solid" className="px-2 py-1" />
                                  <ReactTooltip place="bottom" id="settings" effect="solid" className="px-2 py-1" />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </>
                    )
                    : (
                      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <img src={dragAndDrop} alt="drag and drop" height={90} />
                        <h4 className="text-gray mt-3">لم تقم بإضافة أي قسم</h4>
                        <h5 className="text-gray mb-0">قم بسحب وإفلات القسم من القائمة الجانبية داخل الصفحة</h5>
                      </div>
                    )}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        <div className="settings-sidebar" ref={sidebarRef}>
          {activeSettings === 'section'
            ? (
              <SectionSettings
                section={selectedSection}
                pageId={params.pageId}
                projectId={params.projectId}
                getPageDetails={getPageDetails}
                updateSelectedSection={updateSelectedSection}
                selectedSection={selectedSection}
              />
            ) : (
              <PageSettings
                projectId={params.projectId}
                pageId={params.pageId}
                getPageDetails={getPageDetails}
                page={page}
                updatePages={updatePages}
              />
            )}
        </div>
        <PublishProject
          url={publishUrl}
          isModalOpen={isPublishModalOpen}
          toggleModal={togglePublishModal}
          projectId={params.projectId}
        />
        <CompleteProfile
          isModalOpen={isProfileModalOpen}
          toggleModal={toggleProfileModal}
          onPublish={onPublish}
        />
        <DeleteSection
          isModalOpen={isDeleteModalOpen}
          toggleModal={toggleDeleteModal}
          pageId={params.pageId}
          projectId={params.projectId}
          sectionId={selectedSectionId}
          getPageDetails={getPageDetails}
        />
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <Subscribe
            isModalOpen={isSubscribeModalOpen}
            toggleModal={toggleSubscribeModal}
            updateClientSecret={updateClientSecret}
          />
        </Elements>
      </DragDropContext>
    </LoadingOverlay>
  );
}

export default ProjectDetails;
