/* eslint-disable max-len */
import portfolioOneThumbnail from '../../assets/images/thumbnails/portfolio-one.svg';
import portfolioTwoThumbnail from '../../assets/images/thumbnails/portfolio-two.svg';

const portfolio = [
  {
    id: '7028ffc4-e69e-4c53-8245-fe4f540b522f',
    title: 'Portfolio',
    elements: [
      {
        id: '2ce98cf0-9401-42cf-937a-deac5771b253',
        thumbnail: portfolioOneThumbnail,
        data: {
          type: 'portfolio01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'portfolio-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'row',
                              },
                              items: [
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-12 mb-3',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '245px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'div',
                                  interface_data: {
                                    class: 'col-md-12 mb-3 mb-md-0',
                                  },
                                  items: [
                                    {
                                      type: 'image',
                                      metadata: {
                                        initialized: true,
                                      },
                                      interface_data: {
                                        class: '',
                                        alt: '',
                                        style: {
                                          'object-fit': 'cover',
                                          'border-top-right-radius': '0px',
                                          'border-top-left-radius': '0px',
                                          'border-bottom-right-radius': '0px',
                                          'border-bottom-left-radius': '0px',
                                          height: '245px',
                                          width: '100%',
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-6',
                          },
                          items: [
                            {
                              type: 'image',
                              metadata: {
                                initialized: true,
                              },
                              interface_data: {
                                class: '',
                                alt: '',
                                style: {
                                  'object-fit': 'cover',
                                  'border-top-right-radius': '0px',
                                  'border-top-left-radius': '0px',
                                  'border-bottom-right-radius': '0px',
                                  'border-bottom-left-radius': '0px',
                                  height: '506px',
                                  width: '100%',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '2419ec36-2171-4e38-b2b9-840dead2fa22',
        thumbnail: portfolioTwoThumbnail,
        data: {
          type: 'portfolio02',

          components: [
            {
              type: 'div',
              interface_data: {
                class: 'portfolio-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'portfolio-card',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '240px',
                                      width: '100%',
                                    },
                                  },
                                },
                                {
                                  type: 'div',
                                  interface_data: {},
                                  items: [
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'اسم المشروع',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mb-0 mt-3',
                                        style: {
                                          'text-align': 'right',
                                        },
                                      },
                                    },
                                    {
                                      type: 'paragraph',
                                      metadata: {
                                        text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                      },
                                      interface_data: {
                                        class: 'mb-0',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'portfolio-card',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '240px',
                                      width: '100%',
                                    },
                                  },
                                },
                                {
                                  type: 'div',
                                  interface_data: {},
                                  items: [
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'اسم المشروع',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mb-0 mt-3',
                                        style: {
                                          'text-align': 'right',
                                        },
                                      },
                                    },
                                    {
                                      type: 'paragraph',
                                      metadata: {
                                        text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                      },
                                      interface_data: {
                                        class: 'mb-0',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-4',
                          },
                          items: [
                            {
                              type: 'div',
                              interface_data: {
                                class: 'portfolio-card',
                              },
                              items: [
                                {
                                  type: 'image',
                                  metadata: {
                                    initialized: true,
                                  },
                                  interface_data: {
                                    class: '',
                                    alt: '',
                                    style: {
                                      'object-fit': 'cover',
                                      'border-top-right-radius': '0px',
                                      'border-top-left-radius': '0px',
                                      'border-bottom-right-radius': '0px',
                                      'border-bottom-left-radius': '0px',
                                      height: '240px',
                                      width: '100%',
                                    },
                                  },
                                },
                                {
                                  type: 'div',
                                  interface_data: {},
                                  items: [
                                    {
                                      type: 'heading',
                                      metadata: {
                                        text: 'اسم المشروع',
                                        level: 4,
                                      },
                                      interface_data: {
                                        class: 'mb-0 mt-3',
                                        style: {
                                          'text-align': 'right',
                                        },
                                      },
                                    },
                                    {
                                      type: 'paragraph',
                                      metadata: {
                                        text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                                      },
                                      interface_data: {
                                        class: 'mb-0',
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default portfolio;
