import HttpHelpers from './helpers';

const PaymentsApiEndpoints = {
  getSessionKey: () => {
    return HttpHelpers.authenticatedAxios
      .post('payment/card/session-key')
      .then((response) => response.data);
  },
  addCard: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('payment/card', data)
      .then((response) => response.data);
  },
  deleteCard: (id) => {
    return HttpHelpers.authenticatedAxios
      .delete(`payment/card/${id}`)
      .then((response) => response.data);
  },
  setDefaultCard: (id) => {
    return HttpHelpers.authenticatedAxios
      .post(`/payment/card/${id}/default-payment`)
      .then((response) => response.data);
  },
  getCards: () => {
    return HttpHelpers.authenticatedAxios
      .get('payment/card')
      .then((response) => response.data);
  },
  subscribe: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('subscribe', data)
      .then((response) => response.data);
  },
  unsubscribe: () => {
    return HttpHelpers.authenticatedAxios
      .post('unsubscribe')
      .then((response) => response.data);
  },
  getTransactions: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('transactions', { params })
      .then((response) => response.data);
  },
};

export default PaymentsApiEndpoints;
