import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Check2Circle } from 'react-bootstrap-icons';
import Api from '../../services';

function VerifyEmail() {
  const authenticated = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.user.data);

  const sendVerificationEmail = () => {
    Api.auth.sendVerificationEmail().then(() => {
      toast.custom(() => (
        <div className="bg-white shadow-lg rounded p-3">
          <div className="d-flex align-items-center mb-1">
            <Check2Circle className="text-success me-2" size={30} />
            <h6 className="text-gray mb-0">
              تم إرسال البريد الإلكتروني بنجاح
            </h6>
          </div>
          <p className="text-gray mb-0">
            يرجى تفقد صندوق البريد الخاص بالحساب المسجل لدى روابط
          </p>
        </div>
      ), { position: 'bottom-center', duration: 7000 });
    });
  };

  if (!authenticated || !user) return null;

  return (
    <>
      {authenticated && !user?.email_verified
        && (
          <div className="light-header bg-silver d-flex align-items-center justify-content-center">
            <Row>
              <Col md={12}>
                <h6 className="text-center text-gray mb-0">
                  لم يتم التحقق من البريد الإلكتروني، اضغط
                  {' '}
                  <span className="text-blue cursor-pointer" onClick={sendVerificationEmail}>هنا</span>
                  {' '}
                  حتى يتم التحقق
                </h6>
              </Col>
            </Row>
          </div>
        )}
    </>
  );
}

export default VerifyEmail;
