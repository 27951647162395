/* eslint-disable max-len */
import ctaOneThumbnail from '../../assets/images/thumbnails/cta-one.svg';
import ctaTwoThumbnail from '../../assets/images/thumbnails/cta-two.svg';

const cta = [
  {
    id: 'f45a6037-9dfb-455d-84db-65d31445c74a',
    title: 'Call to action',
    elements: [
      {
        id: 'f80e9426-5de1-4a30-80f0-b69704bb5c6f',
        thumbnail: ctaOneThumbnail,
        data: {
          type: 'call_to_action01',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'cta-one',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12 d-flex flex-column align-items-center',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العناوين الطويلة تقوم بتحويل العملاء إلى زبائن محتملين!',
                                level: 2,
                              },
                              interface_data: {
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                            {
                              type: 'linkButton',
                              metadata: {
                                text: 'زر رئيسي',
                                linkPoint: 'external',
                                externalLink: '',
                                target: '_self',
                              },
                              interface_data: {
                                class: 'btn btn-secondary',
                                style: {
                                  'border-top-right-radius': '5px',
                                  'border-top-left-radius': '5px',
                                  'border-bottom-right-radius': '5px',
                                  'border-bottom-left-radius': '5px',
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: '6f4e7593-0b5f-487b-9340-a7a08fb302c2',
        thumbnail: ctaTwoThumbnail,
        data: {
          type: 'call_to_action02',
          components: [
            {
              type: 'div',
              interface_data: {
                class: 'cta-two',
                style: {
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-image': undefined,
                  'border-top-right-radius': '0px',
                  'border-top-left-radius': '0px',
                  'border-bottom-right-radius': '0px',
                  'border-bottom-left-radius': '0px',
                },
              },
              items: [
                {
                  type: 'div',
                  interface_data: {
                    containerClass: 'container',
                  },
                  items: [
                    {
                      type: 'div',
                      interface_data: {
                        class: 'row',
                      },
                      items: [
                        {
                          type: 'div',
                          interface_data: {
                            class: 'col-md-12 d-flex flex-column align-items-center',
                          },
                          items: [
                            {
                              type: 'heading',
                              metadata: {
                                text: 'العناوين الطويلة تقوم بتحويل العملاء إلى زبائن محتملين!',
                                level: 2,
                              },
                              interface_data: {
                                style: {
                                  'text-align': 'center',
                                },
                              },
                            },
                            {
                              type: 'paragraph',
                              metadata: {
                                text: 'هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.',
                              },
                              interface_data: {
                                class: 'text-center w-50 mx-auto mb-4',
                              },
                            },
                            {
                              type: 'div',
                              interface_data: {
                                class: 'd-flex align-items-center',
                              },
                              items: [
                                {
                                  type: 'linkButton',
                                  metadata: {
                                    text: 'زر رئيسي',
                                    linkPoint: 'external',
                                    externalLink: '',
                                    target: '_self',
                                  },
                                  interface_data: {
                                    class: 'btn btn-secondary',
                                    style: {
                                      'border-top-right-radius': '5px',
                                      'border-top-left-radius': '5px',
                                      'border-bottom-right-radius': '5px',
                                      'border-bottom-left-radius': '5px',
                                    },
                                  },
                                },
                                {
                                  type: 'linkButton',
                                  metadata: {
                                    text: 'زر ثانوي',
                                    linkPoint: 'external',
                                    externalLink: '',
                                    target: '_self',
                                  },
                                  interface_data: {
                                    class: 'btn btn-secondary ms-2',
                                    style: {
                                      'border-top-right-radius': '5px',
                                      'border-top-left-radius': '5px',
                                      'border-bottom-right-radius': '5px',
                                      'border-bottom-left-radius': '5px',
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export default cta;
