import Api from '../../services';

export const getUser = () => {
  return (dispatch) => {
    return Api.user.getUser().then((response) => {
      dispatch({ type: 'GET_USER', data: response });
      return response;
    });
  };
};

export const updateUser = (data) => {
  return (dispatch) => {
    return Api.user.updateUser(data).then((response) => {
      dispatch({ type: 'UPDATE_USER', data: response });
      return response;
    });
  };
};
