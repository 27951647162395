import React from 'react';

function ListItem({ config, children }) {
  return (
    <li className={config?.interface_data?.class} key={config.id}>
      {children}
    </li>
  );
}

export default ListItem;
