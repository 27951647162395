import React, { useMemo, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, List, Table, Alert,
} from 'reactstrap';
import Api from '../../../../../../services';

function Steps({
  isModalOpen, toggleModal, projectId, domainId,
}) {
  const [data, setData] = useState(undefined);

  const getProjectDomain = () => {
    Api.projects.getProjectDomain(projectId, domainId).then((response) => {
      setData(response?.data);
    });
  };

  const domainName = useMemo(() => {
    if (!data?.domain) return '';

    const regex = /(\..*){2,}/;
    if (regex.test(data?.domain)) {
      return data.domain;
    }

    return '@';
  }, [data?.domain]);
  return (
    <Modal onOpened={getProjectDomain} centered isOpen={isModalOpen} toggle={() => toggleModal()} size="xl">
      <ModalHeader className="text-gray" toggle={() => toggleModal()}>
        خطوات تفعيل النطاق
      </ModalHeader>
      <ModalBody>
        <List className="ordered-list" type="unstyled">
          <li>قم بالوصول إلى مزود خدمة النطاق الخاص بك والانتقال إلى إعدادات DNS</li>
          <li>قم بإنشاء A record وادخل القيم التالية:</li>
          <Table bordered dir="ltr">
            <thead>
              <tr>
                <th>
                  hostname
                </th>
                <th>
                  value
                </th>
                <th>
                  TTL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {domainName}
                </td>
                <td>
                  {process.env.REACT_APP_DNS_IP_ONE}
                </td>
                <td>
                  30 minutes
                </td>
              </tr>
            </tbody>
          </Table>
          <li>قم بإنشاء CNAME وادخل القيم التالية:</li>
          {data?.cname?.Name && data?.cname?.Value
            ? (
              <Table bordered dir="ltr">
                <thead>
                  <tr>
                    <th>
                      name
                    </th>
                    <th>
                      value
                    </th>
                    <th>
                      TTL
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {data?.cname?.Name}
                    </td>
                    <td className="text-break">
                      {data?.cname?.Value}
                    </td>
                    <td className="text-break">
                      30 minutes
                    </td>
                  </tr>
                </tbody>
              </Table>
            )
            : (
              <Alert color="secondary">
                لم يتم جلب البيانات، يرجى المحاولة بعد قليل،
                {' '}
                <span className="mb-0 cursor-pointer text-blue" onClick={getProjectDomain}>حاول مرة أخرى</span>
              </Alert>
            )}
        </List>
      </ModalBody>
    </Modal>
  );
}

export default Steps;
