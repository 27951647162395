import React, { useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import {
  Modal, ModalBody, ModalFooter, Spinner,
} from 'reactstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import Api from '../../../services';
import { getUser } from '../../../redux/actions/user.actions';
import { getCards } from '../../../redux/actions/cards.actions';

function AddCard({
  isModalOpen, toggleModal, updateClientSecret,
}) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState(undefined);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const onModalOpen = () => {
    setName('');

    Api.payments.getSessionKey().then((response) => {
      setClientSecret(response?.data['session-key']);
      updateClientSecret();
    });

    dispatch(getCards());
  };

  const cardOptions = {
    style: {
      base: {
        color: '#606060',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        fontFamily: 'Tajawal, sans-serif',
        '::placeholder': {
          color: '#606060',
        },
      },
      invalid: {
        color: '#FF4D4F',
        iconColor: '#FF4D4F',
      },
    },
    hidePostalCode: true,
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const addCard = async () => {
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
          },
        },
      }).then((result) => {
        if (result?.error) {
          setProcessing(false);
          setSucceeded(false);
          setError(result?.error?.message);
        } else {
          setError(null);
          Api.payments.addCard({
            card_data: {
              payment_method_id: result?.setupIntent?.payment_method,
            },
            is_default: false,
          }).then(() => {
            setProcessing(false);
            setSucceeded(true);
            dispatch(getUser());
            dispatch(getCards());
            toggleModal();
          }).catch(() => {
            setProcessing(false);
            setSucceeded(false);
          });
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    addCard();
  };

  return (
    <Modal onOpened={onModalOpen} centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <form onSubmit={handleSubmit}>
        <div className="modal-header d-flex align-items-center">
          <h5 className="text-gray mb-0 pt-2">إضافة طريقة دفع</h5>
          <XLg className="text-gray cursor-pointer" onClick={() => toggleModal(!isModalOpen)} />
        </div>
        <ModalBody className="p-0">
          <div className="payment-form px-3 py-4">
            <input
              className="mb-3"
              placeholder="Card holder name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <CardElement className="card-element" options={cardOptions} onChange={handleChange} />
            {error && (
              <p className="mt-2 mb-0 text-red" dir="ltr">
                {error}
              </p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            disabled={processing || disabled || succeeded}
            id="submit"
            type="submit"
            className="btn btn-blue"
          >
            {processing ? (
              <Spinner className="text-white mt-1" size="sm" />
            ) : (
              'إضافة'
            )}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default AddCard;
