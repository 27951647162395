const authReducer = (state = null, action) => {
  if (!state) {
    const token = localStorage.getItem('api_token');
    const state = {
      authenticated: token !== null,
    };

    return state;
  }

  switch (action.type) {
  case 'LOGIN':
  case 'SIGN_UP':
  case 'PASSWORDLESS_LOGIN':
  case 'GUEST_USER':
    localStorage.setItem('api_token', action.data.api_token);
    if (action.error) return state;
    return {
      ...state,
      authenticated: true,
    };

  case 'LOGOUT':
    localStorage.removeItem('api_token');
    return {
      ...state,
      authenticated: false,
    };

  default:
    return state;
  }
};

export default authReducer;
