import React from 'react';
import { ChevronLeft } from 'react-bootstrap-icons';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import {
  Col, Container, Nav, NavItem, Row,
} from 'reactstrap';
import Header from '../../components/header';
import VerifyEmail from '../../components/verify-email';

function Account() {
  const location = useLocation();

  return (
    <>
      <VerifyEmail />
      <Header />
      <section className="mt-4 mb-3">
        <Container>
          <Row>
            <Col md={12} className="d-flex align-items-center">
              <h5 className="text-gray mb-0">الإعدادات</h5>
              <ChevronLeft className="text-gray mx-1 h5" />
              {location?.pathname === '/settings/profile'
              && <h5 className="text-gray mb-0">الملف الشخصي</h5>}
              {location?.pathname === '/settings/subscriptions'
              && <h5 className="text-gray mb-0">الاشتراكات</h5>}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-2">
        <Container>
          <Row>
            <Col md={12}>
              <Nav className="underlined-tabs" tabs>
                <NavItem>
                  <NavLink to="/settings/profile" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    الملف الشخصي
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/settings/subscriptions" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    الاشتراكات
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Outlet />
        </Container>
      </section>
    </>
  );
}

export default Account;
