import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneField from '../phone-input';
import InputField from '../input';
import { updateUser } from '../../redux/actions/user.actions';
import { setServerErrors } from '../../helpers';
import schema from './schema';

function CompleteProfile({
  isModalOpen, toggleModal, onPublish,
}) {
  const dispatch = useDispatch();
  const {
    register, formState: { errors }, control, setError, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const parsedPhoneNumber = parsePhoneNumber(`${data.phone_number}`);
    const newData = {
      ...data, phone_number: parsedPhoneNumber?.number, phone_country: parsedPhoneNumber?.country, name: data?.email,
    };
    dispatch(updateUser(newData)).then(() => {
      onPublish();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="text-gray" toggle={() => toggleModal()}>
        يرجى إكمال التسجيل لعدم فقدان التغييرات
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="pb-0">
          <Row>
            <Col md={12}>
              <InputField
                label="البريد الإلكتروني"
                name="email"
                type="email"
                errors={errors}
                {...register('email')}
              />
            </Col>
            <Col md={12}>
              <PhoneField
                label="رقم الهاتف"
                type="phone_number"
                errors={errors}
                control={control}
                {...register('phone_number')}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <button type="submit" className="btn btn-success px-4" id="complete_profile">
            متابعة لنشر الموقع
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default CompleteProfile;
