import React from 'react';
import classnames from 'classnames';

function Div({ config, children }) {
  return (
    <div
      className={classnames(config?.interface_data?.class, config?.interface_data?.containerClass)}
      style={{
        backgroundColor: config?.interface_data?.style && config?.interface_data?.style['background-color'],
        color: config?.interface_data?.style?.color,
        backgroundImage: config?.interface_data?.style && config?.interface_data?.style['background-image'],
        backgroundSize: config?.interface_data?.style && config?.interface_data?.style?.['background-size'],
        backgroundPosition: config?.interface_data?.style && config?.interface_data?.style['background-position'],
        borderTopRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-right-radius'],
        borderTopLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-top-left-radius'],
        borderBottomRightRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-right-radius'],
        borderBottomLeftRadius: config?.interface_data?.style && config?.interface_data?.style['border-bottom-left-radius'],
      }}
    >
      {children}
    </div>
  );
}

export default Div;
