import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import unauthorized from '../../assets/images/401.svg';
import notFound from '../../assets/images/404.svg';
import unauthenticated from '../../assets/images/403.svg';

function Error() {
  const { statusCode } = useParams();

  const renderContent = (type) => {
    switch (type) {
    case '404':
      return (
        <>
          <img src={notFound} alt={statusCode} />
          <h1 className="h4 text-gray mt-4 mb-2">لم يتم العثور على هذه الصفحة</h1>
        </>
      );
    case '401':
      return (
        <>
          <img src={unauthenticated} alt={statusCode} />
          <h1 className="h4 text-gray mt-4 mb-2">يجب عليك تسجيل الدخول حتى تتمكن من استعراض المشروع</h1>
        </>
      );
    case '403':
      return (
        <>
          <img src={unauthorized} alt={statusCode} />
          <h1 className="h4 text-gray mt-4 mb-2">لا يوجد لديك صلاحية لاستعراض المشروع</h1>
        </>
      );
    default:
      return (
        <>
          <img src={notFound} alt="404" />
          <h1 className="h4 text-gray mt-4 mb-2">لم يتم العثور على هذه الصفحة</h1>
        </>
      );
    }
  };
  return (
    <section className="error-wrapper">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            {renderContent(statusCode)}
            <Link to="/" className="btn btn-blue">
              العودة إلى الصفحة الرئيسية
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Error;
