import HttpHelpers from './helpers';

const PlansApiEndpoints = {
  getPlans: () => {
    return HttpHelpers.authenticatedAxios
      .get('plans')
      .then((response) => response.data);
  },
};

export default PlansApiEndpoints;
