import React from 'react';
import lockedPlaceholder from '../../../../../../assets/images/locked-placeholder.svg';

function Placeholder({ title, btnText, onClick }) {
  return (
    <div className="p-3 d-flex flex-column align-items-center">
      <img src={lockedPlaceholder} alt="empty" />
      <h6 className="text-gray text-center mb-0 mt-3">{title}</h6>
      <button type="button" className="btn btn-sm btn-blue mt-3" onClick={onClick}>{btnText}</button>
    </div>
  );
}

export default Placeholder;
