import HttpHelpers from './helpers';

const ProjectsApiEndpoints = {
  getProjects: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('project?with[]=homepage', { params })
      .then((response) => response.data);
  },
  getProject: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`project/${id}?with[]=favicon&with[]=homepage`)
      .then((response) => response.data);
  },
  createProject: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('project', data)
      .then((response) => response.data);
  },
  editProject: (projectId, data) => {
    return HttpHelpers.authenticatedAxios
      .put(`project/${projectId}`, data)
      .then((response) => response.data);
  },
  deleteProject: (projectId) => {
    return HttpHelpers.authenticatedAxios
      .delete(`project/${projectId}`)
      .then((response) => response.data);
  },
  uploadMedia: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('media', data)
      .then((response) => response.data);
  },
  publishProject: (projectId) => {
    return HttpHelpers.authenticatedAxios
      .post(`project/${projectId}/publish`)
      .then((response) => response.data);
  },
  getProjectsTemplates: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('projects/templates', { params })
      .then((response) => response.data);
  },
  getProjectDomains: (projectId) => {
    return HttpHelpers.authenticatedAxios
      .get(`project/${projectId}/domains`)
      .then((response) => response.data);
  },
  getProjectDomain: (projectId, domainId) => {
    return HttpHelpers.authenticatedAxios
      .get(`project/${projectId}/domains/${domainId}`)
      .then((response) => response.data);
  },
  createProjectDomain: (projectId, data) => {
    return HttpHelpers.authenticatedAxios
      .post(`project/${projectId}/domains`, data)
      .then((response) => response.data);
  },
  deleteProjectDomain: (projectId, domainId) => {
    return HttpHelpers.authenticatedAxios
      .delete(`project/${projectId}/domains/${domainId}`)
      .then((response) => response.data);
  },
  archiveProject: (projectId) => {
    return HttpHelpers.authenticatedAxios
      .post(`project/${projectId}/archive`)
      .then((response) => response.data);
  },
  unarchiveProject: (projectId) => {
    return HttpHelpers.authenticatedAxios
      .post(`project/${projectId}/unarchive`)
      .then((response) => response.data);
  },
};

export default ProjectsApiEndpoints;
