import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Check2Circle, XCircle } from 'react-bootstrap-icons';
import logo from '../../../../assets/images/logo.svg';
import mobile from '../../../../assets/images/mobile.png';
import laptop from '../../../../assets/images/laptop.png';
import { requestPasswordlessLogin } from '../../../../redux/actions/passwordless.actions';

function ResponsiveView() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);

  const sendLoginLink = () => {
    dispatch(requestPasswordlessLogin({ email: user?.email })).then(() => {
      toast.custom(() => (
        <div className="w-100 bg-white shadow-lg rounded p-3">
          <div className="d-flex align-items-center mb-1">
            <Check2Circle className="text-success me-2" size={30} />
            <h6 className="text-gray mb-0">
              تم إرسال البريد الإلكتروني بنجاح
            </h6>
          </div>
          <p className="text-gray mb-0">
            يرجى تفقد صندوق البريد الخاص بالحساب المسجل لدى روابط
          </p>
        </div>
      ), { position: 'bottom-center', duration: 7000 });
    }).catch((error) => {
      toast.custom(() => (
        <div className="w-100 bg-white shadow-lg rounded p-3">
          <div className="d-flex align-items-center mb-1">
            <XCircle className="text-danger me-2" size={25} />
            <h6 className="text-gray mb-0">
              حدث خطأ ما
            </h6>
          </div>
          <p className="text-gray mb-0">
            {error?.response?.data?.message}
          </p>
        </div>
      ), { position: 'bottom-center', duration: 7000 });
    });
  };
  return (
    <>
      <header className="pt-4 pb-5">
        <Container>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <img src={logo} alt="Rawabit" height={55} />
            </Col>
          </Row>
        </Container>
      </header>
      <Container className="pt-5">
        <Row>
          <Col md={12} className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <img src={laptop} alt="laptop" height={70} />
              <img src={mobile} alt="mobile" height={55} />
            </div>
            <h5 className="text-gray text-center my-3">حتى تتمكن من الحصول على تجربة أفضل، يرجى استخدام جهاز كمبيوتر</h5>
            <Link to="/projects" className="btn btn-navy mb-3" id="navigate_projects_responsive">العودة إلى قائمة المشاريع</Link>
            <button type="button" className="btn btn-blue" onClick={sendLoginLink} id="request_passwordless_project">إرسال رابط تسجيل الدخول إلى البريد الإلكتروني</button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResponsiveView;
